export async function handleError(res: Response) {
  if (!res.ok) {
    const errorBody = res.headers
      .get('content-type')
      ?.includes('application/json')
      ? await res.json()
      : await res.text();
    throw new Error({ status: res.status, ...errorBody });
  }

  return res;
}

export async function makeFetchRequest(
  path: string,
  method: string = 'GET',
  data?: object
) {
  const response = await fetch(
    `https://eventhandler-backend.azurewebsites.net/api/${path}`,
    {
      method,
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(data),
    }
  );
  await handleError(response);
  return await response.json();
}
