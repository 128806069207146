import React from 'react';
import { Form } from 'react-bootstrap';

export const ModalInputSwitch = (props) => {
  return (
    <Form.Group className='mb-3' controlId={props?.controlId}>
      <Form.Label>{props.label}</Form.Label>
      <Form.Check
        type='switch'
        name={props?.name}
        id={props?.id}
        checked={props?.checked}
        onChange={props.onChange}
      ></Form.Check>
    </Form.Group>
  );
};
