import React from 'react';
import { Form } from 'react-bootstrap';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/sv';
import { CalendarCheck } from 'react-bootstrap-icons';

export const DateTimeInputfield = (props) => {
  const onChange = (newValue) => {
    const testDate = new Date(newValue.$d);
    let date =
      testDate.getFullYear() +
      '-' +
      (testDate.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      testDate.getDate().toString().padStart(2, '0');
    let time =
      testDate.getHours().toString().padStart(2, '0') +
      ':' +
      testDate.getMinutes().toString().padStart(2, '0');
    let dateTime = date + 'T' + time;
    if (props.timeType === 'startDate') {
      props.setEventInput((prevState) => ({
        ...prevState,
        startDate: dateTime,
        endTime: addTimeToDate(0, 1, dateTime),
        lastDate: addTimeToDate(0, -1, dateTime),
      }));
      props.setDateTimeValue(newValue);
      props.setDateTimeValueEnd(
        dayjs(props.addHours(new Date(newValue.$d), 1))
      );
      props.setDateValue(dayjs(props.addHours(new Date(newValue.$d), -1)));
    } else if (props.timeType === 'endTime') {
      props.setEventInput((prevState) => ({
        ...prevState,
        endTime: dateTime,
      }));
      props.setDateTimeValueEnd(newValue);
    } else if (props.timeType === 'dateTime') {
      props.setEventInput((prevState) => ({
        ...prevState,
        lastDate: dateTime,
      }));
      props.setDateValue(newValue);
    }
  };

  const addTimeToDate = (day, hour, date) => {
    let dateCopy = new Date(date);
    const dayInMs = day * 86400000;
    const hourInMs = hour * 3600000;
    if (!!dateCopy) {
      let newDate = new Date(dateCopy.getTime() + dayInMs + hourInMs);
      let dateString =
        newDate.getFullYear() +
        '-' +
        (newDate.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        newDate.getDate().toString().padStart(2, '0');
      let timeString =
        newDate.getHours().toString().padStart(2, '0') +
        ':' +
        newDate.getMinutes().toString().padStart(2, '0');
      return dateString + 'T' + timeString;
    }
  };

  return (
    <Form.Group controlId={props.controlId}>
      <Form.Label>{props.label}</Form.Label>
      <br />
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'sv'}>
        <DateTimePicker
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                readOnly: false,
              }}
            />
          )}
          value={props.value}
          onChange={(newValue) => onChange(newValue)}
          className='modalInput'
          minDateTime={dayjs(props.todayOnlyDate + 'T00:00')}
          components={{
            OpenPickerIcon: CalendarCheck,
          }}
          ampm={false}
        />
      </LocalizationProvider>
    </Form.Group>
  );
};
