import React, { useState, useEffect } from 'react';
import {
  Button,
  Form,
  Col,
  Container,
  Row,
  Modal,
  Collapse,
  CloseButton,
  Tooltip,
  OverlayTrigger,
  Image,
} from 'react-bootstrap';
import {
  Clock,
  PeopleFill,
  PinMapFill,
  PersonCheckFill,
  PencilFill,
  XCircle,
  Share,
  Download,
  Envelope,
} from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import './modal.css';
// import HiQ from '../../images/hiqLogoTransparentBlack.png'
import 'animate.css';
import Logo from '../../images/hiqLogoTransparentBlack.png';
import { ExcelGenerator } from '../ExcelGenerator';
import { ModalInputSwitch } from '../ModalInputSwitch';

export const EventSignUpModal = (props) => {
  const navigate = useNavigate();
  const [fetchedAccounts, setFetchedAccounts] = useState(null);
  const [fetchedEvent, setFetchedEvent] = useState(null);

  const [userInput, setUserInput] = useState({
    email: !!props.loggedInUser ? props.loggedInUser.email.toLowerCase() : '',
  });

  // useEffect(() => {
  //   if(!!userInput.email) {
  //     ValidateEmail(userInput.email)
  //   }
  // }, [userInput.email])

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showCancelMessage, setShowCancelMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSignUpServerError, setShowSignUpServerError] = useState(false);
  const [showAlreadySignedUp, setShowAlreadySignedUp] = useState(false);
  const [showErrorCancelMessage, setShowErrorCancelMessage] = useState(false);
  const [showTooLateMessage, setShowTooLateMessage] = useState(false);
  const [showParticipants, setShowParticipants] = useState(false);
  const [todaysDate, setTodaysDate] = useState(null);
  const [showBringGuest, setShowBringGuest] = useState(false);
  const [addGuest, setAddGuest] = useState({
    bringGuest: false,
    guests: [],
  });
  const [currentGuest, setCurrentGuest] = useState({
    currentGuestFirstName: '',
    currentGuestLastName: '',
    currentGuestFoodPref: '',
  });
  const [showGuestErrorMessage, setShowGuestErrorMessage] = useState(false);
  const [showGuestSuccessMessage, setShowGuestSuccessMessage] = useState(false);
  const [showTooManyGuestsError, setShowTooManyGuestsError] = useState(false);
  const [currentTheme, setCurrentTheme] = useState('standardModal');
  const [showAdminSignUp, setShowAdminSignUp] = useState(false);

  useEffect(() => {
    if (!showAdminSignUp && !!props.loggedInUser) {
      setUserInput({
        email: props.loggedInUser.email.toLowerCase(),
      });
    }
  }, [showAdminSignUp]);

  //Sets todays date.
  useEffect(() => {
    let today = new Date();
    let dd = today.getDate().toString().padStart(2, '0');
    let mm = (today.getMonth() + 1).toString().padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    today = new Date(
      yyyy +
        '-' +
        mm +
        '-' +
        dd +
        'T' +
        today.getHours().toString().padStart(2, '0') +
        ':' +
        today.getMinutes().toString().padStart(2, '0')
    );
    setTodaysDate(today);
  }, []);

  //Calls function too see if its too late to signup to event.
  useEffect(() => {
    if (props.event !== null) {
      checkDate();
    }
  }, [props.event]);

  //Sets userInput.email to the logged in users mail address upon opening the modal.
  useEffect(() => {
    if (!!props.loggedInAsAdmin && !!props.loggedInUser) {
      setUserInput({
        email: props.loggedInUser.email.toLowerCase(),
      });
    }
  }, [props.event, props.loggedInUser]);

  //Updates the theme for the event.
  useEffect(() => {
    if (props.event !== null && fetchedEvent !== null) {
      props.changeCurrentTheme(selectTheme());
    }
  }, [fetchedEvent]);

  const selectTheme = () => {
    switch (props.event.themeType) {
      case 1:
        return 'oceanTheme';
      case 2:
        return 'partyTheme';
      case 3:
        return 'fallTheme';
      case 4:
        return 'winterTheme';
      case 5:
        return 'springTheme';
      case 6:
        return 'summerTheme';
      default:
        return 'App-container-no-background';
    }
  };

  //Fetches accounts of the invited users.
  useEffect(() => {
    if (props.event !== null)
      fetch(
        `https://eventhandler-backend.azurewebsites.net/api/Events/${props.event.id}`,
        {
          method: 'GET',
          headers: {
            accept: 'text/plain',
          },
        }
      )
        .then((res) => res.json())
        .then((fixedData) => fixAcceptedInvites(fixedData));
    fetch('https://eventhandler-backend.azurewebsites.net/api/Accounts', {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((data) => setFetchedAccounts(data));
  }, []);

  const handleInputChange = (e) => {
    e.preventDefault();
    setUserInput((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleGuestInputChange = (e) => {
    e.preventDefault();
    setCurrentGuest((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  function checkOnList() {
    const email = userInput.email.toLowerCase();
    const invitedUsers = fetchedAccounts;

    if (invitedUsers.some((e) => e.email.toLowerCase() === email)) {
      return true;
    } else {
      return false;
    }
  }

  //Checks if the logged in user has already signed up to the event.
  function checkAlreadyAccepted() {
    let fetchedAccountsLowerCase = [];
    props.event.acceptedAccounts.forEach((account) => {
      fetchedAccountsLowerCase.push(account.toLowerCase());
    });
    if (fetchedAccountsLowerCase.includes(userInput.email.toLowerCase())) {
      return false;
    } else {
      return true;
    }
  }

  //Called when the user signs up to the event. The many "if" statements are to validate the email for the different event-signup-scenarios (open invitation, invitetd or not, bringing guest e.t.c.)
  const submitUserInput = () => {
    if (addGuest.bringGuest === true && addGuest.guests.length !== 0) {
      submitUserInputWithGuest();
    } else {
      if (props.event.openInvitation) {
        if (checkAlreadyAccepted() === true) {
          if (
            userInput.email !== '' &&
            ValidateEmail(userInput.email) &&
            fetchedAccounts.filter(
              (user) =>
                user.email.toLowerCase() === userInput.email.toLowerCase()
            ).length > 0
          ) {
            fetch(
              `https://eventhandler-backend.azurewebsites.net/api/Accounts/accept?email=${userInput.email
                .substring(0, userInput.email.indexOf('@'))
                .toLowerCase()}%40${userInput.email
                .substring(userInput.email.indexOf('@') + 1)
                .toLowerCase()}&eventId=${props.event.id}`,
              {
                method: 'POST',
                headers: {
                  accept: 'text/plain',
                },
              }
            )
              .then((response) => {
                if (response.ok) {
                  setShowSuccessMessage(true);
                  const timeOut = setTimeout(() => {
                    setShowSuccessMessage(false);
                    handleClose();
                    setUserInput({
                      email: '',
                    });
                  }, 3000);
                  return () => {
                    clearTimeout(timeOut);
                  };
                }
              })
              .then(() => {
                const timeout = setTimeout(() => {
                  props.setShow(false);
                }, 1500);
                return () => {
                  clearTimeout(timeout);
                };
              })
              .catch((error) => {
                console.error(error);
                setShowSignUpServerError(true);
                const timeOut = setTimeout(() => {
                  setShowSignUpServerError(false);
                }, 3000);
                return () => clearTimeout(timeOut);
              });
          } else {
            setShowErrorMessage(true);
            const timeOut = setTimeout(() => {
              setShowErrorMessage(false);
            }, 3000);
            return () => clearTimeout(timeOut);
          }
        } else {
          setShowAlreadySignedUp(true);
          const timeOut = setTimeout(() => {
            setShowAlreadySignedUp(false);
          }, 3000);
          return () => {
            clearTimeout(timeOut);
          };
        }
      } else {
        if (props.event.invitedAccounts.length !== 0) {
          if (checkAlreadyAccepted() === true) {
            if (
              userInput.email !== '' &&
              checkOnList() === true &&
              ValidateEmail(userInput.email) &&
              props.event.invitedAccounts.filter(
                (user) =>
                  user.email.toLowerCase() === userInput.email.toLowerCase()
              ).length > 0
            ) {
              fetch(
                `https://eventhandler-backend.azurewebsites.net/api/Accounts/accept?email=${userInput.email
                  .substring(0, userInput.email.indexOf('@'))
                  .toLowerCase()}%40${userInput.email
                  .substring(userInput.email.indexOf('@') + 1)
                  .toLowerCase()}&eventId=${props.event.id}`,
                {
                  method: 'POST',
                  headers: {
                    accept: 'text/plain',
                  },
                }
              )
                .then((response) => {
                  if (response.ok) {
                    setShowSuccessMessage(true);
                    const timeOut = setTimeout(() => {
                      setShowSuccessMessage(false);
                      handleClose();
                      setUserInput({
                        email: '',
                      });
                    }, 3000);
                    return () => {
                      clearTimeout(timeOut);
                    };
                  }
                })
                .then(() => {
                  const timeout = setTimeout(() => {
                    props.setShow(false);
                  }, 1500);
                  return () => {
                    clearTimeout(timeout);
                  };
                })
                .catch((error) => {
                  console.error(error);
                  setShowSignUpServerError(true);
                  const timeOut = setTimeout(() => {
                    setShowSignUpServerError(false);
                  }, 3000);
                  return () => clearTimeout(timeOut);
                });
            } else {
              setShowErrorMessage(true);
              const timeOut = setTimeout(() => {
                setShowErrorMessage(false);
              }, 3000);
              return () => clearTimeout(timeOut);
            }
          } else {
            setShowAlreadySignedUp(true);
            const timeOut = setTimeout(() => {
              setShowAlreadySignedUp(false);
            }, 3000);
            return () => {
              clearTimeout(timeOut);
            };
          }
        } else {
          if (userInput.email !== '') {
          } else {
            setShowErrorMessage(true);
            const timeOut = setTimeout(() => {
              setShowErrorMessage(false);
            }, 3000);
            return () => clearTimeout(timeOut);
          }
        }
      }
    }
  };

  //Called if signing up to an event with guests. This functions creates "temporary" accounts for the guests with a dummy mail address, invites them to the event and then signs them up.
  const submitUserInputWithGuest = async () => {
    if (props.event.openInvitation) {
      if (checkAlreadyAccepted() === true) {
        if (
          userInput.email !== '' &&
          ValidateEmail(userInput.email) &&
          fetchedAccounts.filter(
            (user) => user.email.toLowerCase() === userInput.email.toLowerCase()
          ).length > 0
        ) {
          await fetch(
            `https://eventhandler-backend.azurewebsites.net/api/Accounts/accept?email=${userInput.email
              .substring(0, userInput.email.indexOf('@'))
              .toLowerCase()}%40${userInput.email
              .substring(userInput.email.indexOf('@') + 1)
              .toLowerCase()}&eventId=${props.event.id}`,
            {
              method: 'POST',
              headers: {
                accept: 'text/plain',
              },
            }
          );
        } else {
          setShowErrorMessage(true);
          const timeOut = setTimeout(() => {
            setShowErrorMessage(false);
          }, 3000);
          return () => clearTimeout(timeOut);
        }
      } else {
        setShowAlreadySignedUp(true);
        const timeOut = setTimeout(() => {
          setShowAlreadySignedUp(false);
        }, 3000);
        return () => {
          clearTimeout(timeOut);
        };
      }
    } else {
      if (props.event.invitedAccounts.length !== 0) {
        if (checkAlreadyAccepted() === true) {
          if (
            userInput.email !== '' &&
            checkOnList() === true &&
            ValidateEmail(userInput.email) &&
            props.event.invitedAccounts.filter(
              (user) =>
                user.email.toLowerCase() === userInput.email.toLowerCase()
            ).length > 0
          ) {
            await fetch(
              `https://eventhandler-backend.azurewebsites.net/api/Accounts/accept?email=${userInput.email
                .substring(0, userInput.email.indexOf('@'))
                .toLowerCase()}%40${userInput.email
                .substring(userInput.email.indexOf('@') + 1)
                .toLowerCase()}&eventId=${props.event.id}`,
              {
                method: 'POST',
                headers: {
                  accept: 'text/plain',
                },
              }
            );
          } else {
            setShowErrorMessage(true);
            const timeOut = setTimeout(() => {
              setShowErrorMessage(false);
            }, 3000);
            return () => clearTimeout(timeOut);
          }
        } else {
          setShowAlreadySignedUp(true);
          const timeOut = setTimeout(() => {
            setShowAlreadySignedUp(false);
          }, 3000);
          return () => {
            clearTimeout(timeOut);
          };
        }
      } else {
        if (userInput.email !== '') {
        } else {
          setShowErrorMessage(true);
          const timeOut = setTimeout(() => {
            setShowErrorMessage(false);
          }, 3000);
          return () => clearTimeout(timeOut);
        }
      }
    }
    for (var i = 0; i < addGuest.guests.length; i++) {
      if (
        addGuest.guests[i].guestFirstName !== '' &&
        addGuest.guests[i].guestLastName !== ''
      ) {
        const mailOptions = {
          email:
            userInput.email.substring(0, userInput.email.indexOf('@')) +
            '-' +
            addGuest.guests[i].guestFirstName +
            '.' +
            addGuest.guests[i].guestLastName +
            '@guest.se',
          foodPreference: addGuest.guests[i].guestFoodPref,
          isHiqAccount: false,
          teamName: 'No team lead',
          employmentCity: 'No employment city',
          teamId: 'No team id',
        };
        let newList = [];
        await fetch(
          'https://eventhandler-backend.azurewebsites.net/api/Accounts',
          {
            method: 'POST',
            headers: {
              Accept: 'text/plain',
              'Content-type': 'application/json',
            },
            body: JSON.stringify(mailOptions),
          }
        )
          .then((res) => res.json())
          .then(async (newAccount) => {
            let invitesHolder = [];
            await fetch(
              `https://eventhandler-backend.azurewebsites.net/api/Events/${props.event.id}`,
              {
                method: 'GET',
                headers: {
                  accept: 'text/plain',
                },
              }
            )
              .then((res) => res.json())
              .then(async (resJson) => {
                invitesHolder = resJson.invitedAccounts;
                newList.push(newAccount);
                invitesHolder.push(newAccount);
                // const newInviteList = props.event.invitedAccounts.concat(invitesHolder)
                let onlyEmail = [];
                invitesHolder.forEach((account) => {
                  onlyEmail.push(account.email);
                });
                let eventInformation = {
                  title: props.event.title,
                  startDate: props.event.startDate,
                  endTime: props.event.endTime,
                  lastDate: props.event.lastDate,
                  location: props.event.location,
                  description: props.event.description,
                  eventType: props.event.eventType,
                  maxParticipants: props.event.maxParticipants,
                  invitedAccounts: onlyEmail,
                  id: props.event.id,
                  imageURL: props.event.imageURL,
                  themeType: props.event.themeType,
                  active: props.event.active,
                  openInvitation: props.event.openInvitation,
                  canBringGuest: props.event.canBringGuest,
                  numberOfGuests: props.event.numberOfGuests,
                  hasSentReminder: props.event.hasSentReminder,
                  recurringEventId: props.event.recurringEventId,
                  createdBy: props.event.createdBy,
                  showFoodPref: props.event.showFoodPref,
                  uploadedFiles: ['empty'],
                };
                await fetch(
                  'https://eventhandler-backend.azurewebsites.net/api/Events',
                  {
                    method: 'PUT',
                    headers: {
                      Accept: 'text/plain',
                      'Content-type': 'application/json',
                    },
                    body: JSON.stringify(eventInformation),
                  }
                )
                  .then((res) => res.json())
                  .then(async () => {
                    await fetch(
                      `https://eventhandler-backend.azurewebsites.net/api/Accounts/accept?email=${userInput.email
                        .substring(0, userInput.email.indexOf('@'))
                        .toLowerCase()}-${
                        addGuest.guests[i].guestFirstName.toLowerCase() +
                        '.' +
                        addGuest.guests[i].guestLastName.toLowerCase()
                      }%40guest.se&eventId=${props.event.id}`,
                      {
                        method: 'POST',
                        headers: {
                          accept: 'text/plain',
                        },
                      }
                    ).then((res) => {
                      if (res.ok) {
                        setShowSuccessMessage(true);
                        const timeOut = setTimeout(() => {
                          setShowSuccessMessage(false);
                          handleClose();
                          setUserInput({
                            email: '',
                          });
                        }, 3000);
                        return () => {
                          clearTimeout(timeOut);
                        };
                      }
                    });
                  })

                  .then(() => {
                    const timeout = setTimeout(() => {
                      props.setShow(false);
                    }, 1500);
                    return () => {
                      clearTimeout(timeout);
                    };
                  });
              });
          });
      }
    }
  };

  //Checks the date for the current event.
  const checkDate = () => {
    let today = new Date();
    let min = today.getMinutes().toString().padStart(2, '0');
    let hh = today.getHours().toString().padStart(2, '0');
    let dd = today.getDate().toString().padStart(2, '0');
    let mm = (today.getMonth() + 1).toString().padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear().toString();
    let today2 = new Date(yyyy + '-' + mm + '-' + dd + 'T' + hh + ':' + min);
    const lastSignUpDate = new Date(props.event.lastDate);
    if (today2 >= lastSignUpDate) {
      setShowTooLateMessage(true);
    } else {
      setShowTooLateMessage(false);
    }
  };

  //Cancels the users sign up to an event (if they are signed up). If they have any guests signed up, they also cancel their sign up.
  const cancelSignUpViaModal = async () => {
    if (
      userInput.email !== '' &&
      ValidateEmail(userInput.email) &&
      fetchedAccounts.filter(
        (user) => user.email.toLowerCase() === userInput.email.toLowerCase()
      ).length > 0
    ) {
      const email = userInput.email.toLowerCase();
      const acceptedUsers = props.event.acceptedAccounts;
      if (acceptedUsers.some((e) => e.toLowerCase() === email)) {
        let mailInDataBase = acceptedUsers.filter(
          (e) => e.toLowerCase() === email
        )[0];
        await fetch(
          `https://eventhandler-backend.azurewebsites.net/api/Accounts/unaccept?email=${mailInDataBase.substring(
            0,
            mailInDataBase.indexOf('@')
          )}%40${mailInDataBase.substring(
            mailInDataBase.indexOf('@') + 1
          )}&eventId=${props.event.id}`,
          {
            method: 'POST',
            headers: {
              accept: 'text/plain',
            },
          }
        )
          .then((res) => res.json())
          .then(async () => {
            let listOfGuests = props.event.acceptedAccounts.filter(
              (email) =>
                email.includes(
                  userInput.email.substring(0, userInput.email.indexOf('@'))
                ) && email.includes('guest.se')
            );
            Promise.all(
              listOfGuests.map(async (guest) => {
                await fetch(
                  `https://eventhandler-backend.azurewebsites.net/api/Accounts/unaccept?email=${guest.substring(
                    0,
                    guest.indexOf('@')
                  )}%40${guest.substring(guest.indexOf('@') + 1)}&eventId=${
                    props.event.id
                  }`,
                  {
                    method: 'POST',
                    headers: {
                      accept: 'text/plain',
                    },
                  }
                );
              })
            );
          })
          .then(() => {
            setShowCancelMessage(true);
            const timeOut = setTimeout(() => {
              setUserInput({
                email: '',
              });
              setShowCancelMessage(false);
              handleClose();
            }, 3000);
            return () => {
              clearTimeout(timeOut);
            };
          });
      } else {
        setShowErrorCancelMessage(true);
        const timeOut = setTimeout(() => {
          setShowErrorCancelMessage(false);
        }, 3000);
        return () => clearTimeout(timeOut);
      }
    } else {
      setShowErrorCancelMessage(true);
      const timeOut = setTimeout(() => {
        setShowErrorCancelMessage(false);
      }, 3000);
      return () => clearTimeout(timeOut);
    }
  };

  //Converts the accepptedInvites string into an array.
  const fixAcceptedInvites = (event) => {
    let eventCopy = event;
    const acceptArray = eventCopy.acceptedAccounts.split(';');
    const filteredAcceptArray = acceptArray.filter((e) => e !== '');
    eventCopy.acceptedAccounts = filteredAcceptArray;
    setFetchedEvent(eventCopy);
  };

  //Resets all the states to their default state when closing the modal.
  const handleClose = () => {
    props.setShow(false);
    setShowParticipants(false);
    setUserInput({
      email: !!props.loggedInUser ? props.loggedInUser.email.toLowerCase() : '',
    });
    setAddGuest({
      bringGuest: false,
      guests: [],
    });
    setCurrentGuest({
      currentGuestFirstName: '',
      currentGuestLastName: '',
      currentGuestFoodPref: '',
    });
    setShowBringGuest(false);
    props.handleModalType(null);
    setShowAdminSignUp(false);
  };

  //Transforms the dateString from the event to "DAY(short form) + MONTH + year"
  const transformDate = (dateAsString) => {
    let result = '';
    let dateToTransform = new Date(dateAsString);
    switch (dateToTransform.getDay()) {
      case 0:
        result = result.concat('', 'SÖN');
        break;
      case 1:
        result = result.concat('', 'MÅN');
        break;
      case 2:
        result = result.concat('', 'TIS');
        break;
      case 3:
        result = result.concat('', 'ONS');
        break;
      case 4:
        result = result.concat('', 'TORS');
        break;
      case 5:
        result = result.concat('', 'FRE');
        break;
      case 6:
        result = result.concat('', 'LÖR');
        break;
      default:
        result = result.concat('', 'ERROR');
        break;
    }
    switch (dateToTransform.getMonth()) {
      case 0:
        result = result + ' JANUARI ';
        break;
      case 1:
        result = result + ' FEBRUARI ';
        break;
      case 2:
        result = result + ' MARS ';
        break;
      case 3:
        result = result + ' APRIL ';
        break;
      case 4:
        result = result + ' MAJ ';
        break;
      case 5:
        result = result + ' JUNI ';
        break;
      case 6:
        result = result + ' JULI ';
        break;
      case 7:
        result = result + ' AUGUSTI ';
        break;
      case 8:
        result = result + ' SEPTEMBER ';
        break;
      case 9:
        result = result + ' OKTOBER ';
        break;
      case 10:
        result = result + ' NOVEMBER ';
        break;
      case 11:
        result = result + ' DECEMBER ';
        break;
      default:
        result = result + ' ERROR ';
        break;
    }
    result = result + dateToTransform.getDate() + ', ';
    result = result + dateToTransform.getFullYear();
    return result;
  };

  const [showListModal, setShowListModal] = useState(false);

  const hideListModal = () => {
    setShowListModal(false);
  };

  //For admins or event creator to get the foodpreference of signed up users.
  const getFoodPreference = (email) => {
    if (fetchedAccounts !== null) {
      let user = fetchedAccounts.find(
        (user) => user.email.toLowerCase() === email.toLowerCase()
      );
      if (!!user) {
        if (user.foodPreference === '') {
          return 'Inga preferenser';
        } else {
          return user.foodPreference;
        }
      } else {
        return 'Ingen data tillgänglig';
      }
    } else {
      return 'Ingen data tillgänglig';
    }
  };

  //Removes signed up guests from event.
  const removeUserFromInviteList = (index) => {
    let guestListCopy = [...addGuest.guests];
    guestListCopy.splice(index, 1);
    setAddGuest({
      bringGuest: true,
      guests: guestListCopy,
    });
  };

  //Updates event theme.
  useEffect(() => {
    if (props.event !== null) {
      switch (props.event.themeType) {
        case 0:
          setCurrentTheme('standardModal');
          break;
        case 1:
          setCurrentTheme('mintModal');
          break;
        case 2:
          setCurrentTheme('peachModal');
          break;
        case 3:
          setCurrentTheme('pinkLightModal');
          break;
        case 4:
          setCurrentTheme('purpleModal');
          break;
        default:
          setCurrentTheme('standardModal');
          break;
      }
    }
  }, [props.event]);

  //Validates the email.
  function ValidateEmail(input) {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (input.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  }

  //Fixes the event description into correct HTML, and creates clickable links out strings that contains https, www e.t.c
  const fixEventDescription = (description) => {
    const descriptionArray = description.split('\n');
    const renderList = descriptionArray.map((item) => {
      let eachWordArray = item.split(' ');
      const result = eachWordArray.map((word, index) => {
        if (isStringLink(word)) {
          if (word.substring(0, 8) !== 'https://') {
            return (
              <a
                key={word + index}
                href={'https://' + word}
                target='_blank'
                rel='noopener noreferrer'
              >
                {word}
              </a>
            );
          } else {
            return (
              <a
                key={word + index}
                href={word}
                target='_blank'
                rel='noopener noreferrer'
              >
                {word}
              </a>
            );
          }
        } else {
          return <>{' ' + word + ' '}</>;
        }
      });
      return (
        <>
          {result}
          <br />
        </>
      );
    });

    return renderList;
  };

  //Checks if string is a URL.
  const isStringLink = (string) => {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(string);
  };

  //Returns true if the user is logged in as admin or if they have created the event
  const checkPermission = (event) => {
    if (props.loggedInAsAdmin) {
      return true;
    } else {
      if (
        props.loggedInUser.roles.includes('Role.creator') &&
        event.createdBy.toLowerCase() === props.loggedInUser.email.toLowerCase()
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  //Checks if the user is already signed up.
  const checkIfSignedUp = (event, email) => {
    if (event.acceptedAccounts.includes(email.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  //Returns number of sign up spots left.
  const getNumberOfSpots = (event) => {
    const numberOfSpots = event.maxParticipants - event.acceptedAccounts.length;
    if (numberOfSpots <= 0) {
      return 'Fullbokat!';
    } else if (numberOfSpots === 1) {
      return '1 plats kvar';
    } else {
      return numberOfSpots + ' platser kvar';
    }
  };

  //If true, there is a time conflict and the user should be warned.
  const checkForTimeConflict = (currentEvent) => {
    const user = fetchedAccounts.filter(
      (account) =>
        account.email.toLowerCase() === props.loggedInUser.email.toLowerCase()
    );
    let result = false;
    if (!!user && !!user[0]) {
      //Change acceptedEvents into an Array instead of a string
      const acceptArray = user[0].acceptedEvents.split(';');
      if (!!acceptArray && acceptArray.length > 0) {
        const filteredAcceptArray = acceptArray.filter((e) => e !== '');
        const fixedUser = {
          id: user[0].id,
          email: user[0].email,
          foodPreference: user[0].foodPreference,
          isHiqAccount: user[0].isHiqAccount,
          teamName: user[0].teamName,
          teamId: user[0].teamId,
          employmentCity: user[0].employmentCity,
          invitedEvents: user[0].invitedEvents,
          acceptedEvents: filteredAcceptArray,
        };

        if (fixedUser.acceptedEvents.length === 0) {
          // console.log('User has not signed up to any events')
          return false;
        } else {
          // console.log('User has signed up to events')
          fixedUser.acceptedEvents.forEach((event) => {
            const eventObject = props.fetchedEvents.find(
              (fetchedEvent) => fetchedEvent.id == event
            );

            //check for time conflict betweem eventObject and the current event.
            if (
              !!eventObject &&
              new Date(eventObject.startDate) <=
                new Date(currentEvent.endTime) &&
              new Date(eventObject.endTime) >=
                new Date(
                  currentEvent.startDate
                ) /*|| (new Date(currentEvent.startDate) <= new Date(eventObject.endTime) && new Date(currentEvent.endTime) >= new Date(eventObject.startDate))*/
            ) {
              result = true;
            } else {
              result = false;
            }
          });
          return result;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  //Generates a json-string/json file of the signed up user for an event. This json-string is then used when generating the excel-file.
  const generateJsonForExcel = (event) => {
    if (!!event && !!fetchedAccounts) {
      let accountsToInclude = [];
      let jsonData = [];
      let acceptedAccountsToLowerCase = [];

      event.acceptedAccounts.forEach((email) => {
        acceptedAccountsToLowerCase.push(email.toLowerCase());
      });

      fetchedAccounts.forEach((account) => {
        if (acceptedAccountsToLowerCase.includes(account.email.toLowerCase())) {
          accountsToInclude.push(account);
        }
      });

      accountsToInclude.forEach((user) => {
        jsonData.push({
          Mail: user.email,
          Matpreferens: getFoodPreference(user.email),
          Team: user.teamName,
          TeamId: user.teamId,
          Stad: user.employmentCity,
        });
      });
      return jsonData;
    }
  };

  //Returns a string used for opening a mailclient and preparing a mail to all the signed up users for an event.
  const generateMailingListString = (event) => {
    let mailString = 'mailto:';
    for (let i = 0; i < event.acceptedAccounts.length; i++) {
      if (i === 0) {
        mailString = mailString.concat('', event.acceptedAccounts[i]);
      } else {
        mailString = mailString.concat(',', event.acceptedAccounts[i]);
      }
    }
    return mailString;
  };

  return (
    <>
      {props.event !== null && (
        <>
          <Modal
            onClick={() => setShowParticipants(false)}
            animation={false}
            className={
              'modal left animate__animated animate__fadeInRightBig ' +
              currentTheme
            }
            show={props.show}
            onHide={handleClose}
          >
            <Modal.Header>
              <Row
                style={
                  props.currentWindowSize <= 776
                    ? { textAlign: 'center', width: '100%' }
                    : { width: '100%' }
                }
              >
                <Col md={2}>
                  <CloseButton
                    onClick={handleClose}
                    style={{ marginTop: 'auto', marginBottom: 'auto' }}
                  />
                </Col>

                <Col
                  style={
                    props.currentWindowSize <= 776
                      ? { textAlign: 'center' }
                      : {}
                  }
                  xs={6}
                  sm={3}
                  md={
                    checkPermission(props.event)
                      ? { span: 3, offset: 2 }
                      : { span: 3, offset: 5 }
                  }
                  lg={
                    checkPermission(props.event)
                      ? { span: 2, offset: 4 }
                      : { span: 2, offset: 6 }
                  }
                >
                  <Button
                    size='sm'
                    onClick={() => {
                      navigate('signup/' + props.event.id);
                      handleClose();
                    }}
                    className='eventItemShow goToEventButton'
                    variant='dark'
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    Expandera
                  </Button>
                </Col>

                <Col
                  style={
                    props.currentWindowSize <= 776
                      ? { textAlign: 'center' }
                      : {}
                  }
                  xs={6}
                  sm={3}
                  md={2}
                >
                  <OverlayTrigger
                    placement='right'
                    overlay={<Tooltip id='copyEventURL'>Kopiera Länk</Tooltip>}
                  >
                    <Button
                      size='sm'
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `https://event.hiqsyd.se/signup/${props.event.id}`
                        );
                      }}
                      className='eventItemShow goToEventButton'
                      variant='dark'
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <Share />
                    </Button>
                  </OverlayTrigger>
                </Col>

                {checkPermission(props.event) && (
                  <Col
                    style={
                      props.currentWindowSize <= 776
                        ? { textAlign: 'center' }
                        : {}
                    }
                    xs={6}
                    sm={3}
                    md={2}
                  >
                    <Button
                      onClick={() => {
                        props.displayEditEventModal();
                        props.handleModalType('edit');
                        props.handleShowModal(true);
                      }}
                      variant='info'
                      className='modalLightBlueButton'
                      size='lg'
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <PencilFill /> Redigera
                    </Button>
                  </Col>
                )}
              </Row>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <div className='modalPreSignUpArea' style={{ width: '100%' }}>
                  <Row>
                    <Col>
                      <p className='modalDate'>
                        {transformDate(props.event.startDate.substring(0, 10)) +
                          ' ' +
                          props.event.startDate.substring(11)}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Modal.Title
                        style={{
                          minWidth: '40vw',
                          marginBottom: '24px',
                          fontSize: '32px',
                          fontWeight: '700',
                        }}
                      >
                        {props.event.title}
                      </Modal.Title>
                    </Col>
                  </Row>

                  <div>
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <Clock className='modalEventIcons'></Clock>
                            <p className='d-inline-block modalEventText'>
                              {props.event.startDate.substring(11)} -{' '}
                              {props.event.endTime.substring(11)}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <PinMapFill className='modalEventIcons'></PinMapFill>
                            <p className='d-inline-block modalEventText'>
                              {props.event.location}
                            </p>
                          </Col>
                        </Row>
                        <Row className='mb-3'>
                          <Col>
                            {!props.event.openInvitation &&
                            props.event.invitedAccounts.length === 0 ? (
                              <>
                                <PersonCheckFill className='modalEventIcons'></PersonCheckFill>
                                <span className='modalEventText'>
                                  Ingen anmälan krävs
                                </span>
                              </>
                            ) : (
                              <>
                                <PersonCheckFill
                                  className='modalEventIcons'
                                  onClick={() => {
                                    setShowListModal(true);
                                  }}
                                  style={{ cursor: 'pointer' }}
                                ></PersonCheckFill>
                                <Button
                                  size='sm'
                                  variant='outline-secondary'
                                  className='d-inline-block modalEventText'
                                  onClick={() => {
                                    setShowListModal(true);
                                  }}
                                  style={{ border: 'none' }}
                                >
                                  {getNumberOfSpots(props.event)}
                                </Button>
                              </>
                            )}
                          </Col>
                        </Row>

                        {props.event.acceptedAccounts.length >
                          props.event.maxParticipants && (
                          <Row className='mb-3'>
                            <Col>
                              <PersonCheckFill
                                className='modalEventIcons'
                                onClick={() => {
                                  setShowListModal(true);
                                }}
                                style={{ cursor: 'pointer' }}
                              ></PersonCheckFill>
                              <Button
                                size='sm'
                                variant='outline-secondary'
                                className='d-inline-block modalEventText'
                                onClick={() => {
                                  setShowListModal(true);
                                }}
                                style={{ border: 'none' }}
                              >
                                {props.event.acceptedAccounts.length -
                                  props.event.maxParticipants !==
                                1
                                  ? props.event.acceptedAccounts.length -
                                    props.event.maxParticipants +
                                    ' reserver'
                                  : props.event.acceptedAccounts.length -
                                    props.event.maxParticipants +
                                    ' reserv'}
                              </Button>
                            </Col>
                          </Row>
                        )}

                        <Row>
                          <Col>
                            <PeopleFill className='modalEventIcons'></PeopleFill>
                            <p className='d-inline-block modalEventText'>
                              Antal platser: {props.event.maxParticipants}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className='modalSignUpArea'>
                  {todaysDate !== null &&
                  (props.event.invitedAccounts.length !== 0 ||
                    props.event.openInvitation) ? (
                    <>
                      {props.loggedInAsAdmin && (
                        <Row className='mb-3'>
                          <Col>
                            <ModalInputSwitch
                              controlId='handleOtherSignUp'
                              label='Vill du hantera anmälan för någon annan?'
                              name='otherSignUp'
                              onChange={() =>
                                setShowAdminSignUp(!showAdminSignUp)
                              }
                            />
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          {todaysDate <= new Date(props.event.lastDate) ? (
                            <h2 className='signUpHeader'>Anmäl dig här!</h2>
                          ) : (
                            <h2 className='signUpHeader'>
                              Det är för sent för att anmäla sig
                            </h2>
                          )}
                        </Col>
                        {props.event.canBringGuest &&
                          todaysDate <= new Date(props.event.lastDate) && (
                            <Col>
                              <Button
                                variant='outline-dark'
                                size='sm'
                                className='outline-blackButton defaultButton'
                                style={{
                                  whiteSpace: 'nowrap',
                                  border: 'none',
                                  backgroundColor: 'transparent',
                                  color: '#333333',
                                  fontSize: '16px',
                                  fontWeight: '700',
                                }}
                                onClick={() =>
                                  setShowBringGuest(!showBringGuest)
                                }
                              >
                                + Lägg till gäst
                              </Button>
                            </Col>
                          )}
                      </Row>
                      <Row>
                        <Col>
                          {todaysDate <= new Date(props.event.lastDate) ? (
                            <p>
                              Sista anmälningsdag är{' '}
                              {props.event.lastDate.substring(0, 10) +
                                ' klockan ' +
                                props.event.lastDate.substring(11)}
                            </p>
                          ) : (
                            <p>
                              Sista anmälningsdatum var den{' '}
                              {props.event.lastDate.substring(0, 10) +
                                ' klockan ' +
                                props.event.lastDate.substring(11)}
                            </p>
                          )}
                        </Col>
                      </Row>

                      <Row className='mt-3 mb-3'>
                        <Col>
                          {props.event.invitedAccounts.length !== 0 &&
                            !checkIfSignedUp(
                              props.event,
                              userInput.email.toLowerCase()
                            ) &&
                            !showAdminSignUp &&
                            checkForTimeConflict(props.event) && (
                              <span
                                style={{ color: 'orange', fontSize: '20px' }}
                              >
                                Varning! Du är redan anmäld till ett annat event
                                som krockar med detta.{' '}
                              </span>
                            )}

                          {props.event.invitedAccounts.length === 0 &&
                            !checkIfSignedUp(
                              props.event,
                              userInput.email.toLowerCase()
                            ) &&
                            props.event.openInvitation &&
                            !showAdminSignUp &&
                            checkForTimeConflict(props.event) && (
                              <span
                                style={{ color: 'orange', fontSize: '20px' }}
                              >
                                Varning! Du är redan anmäld till ett annat event
                                som krockar med detta.{' '}
                              </span>
                            )}
                        </Col>
                      </Row>

                      {props.event.canBringGuest &&
                        todaysDate <= new Date(props.event.lastDate) && (
                          <>
                            {showBringGuest && (
                              <div className='fade-in-animation'>
                                <Row>
                                  <Col xxl={6} md={6}>
                                    <Form>
                                      <Form.Control
                                        className='modalInput'
                                        type='text'
                                        name='currentGuestFirstName'
                                        value={
                                          currentGuest.currentGuestFirstName
                                        }
                                        onChange={handleGuestInputChange}
                                        style={{
                                          textAlign: 'center',
                                          padding: '8px 24px',
                                          gap: '60px',
                                        }}
                                        size='sm'
                                        placeholder='Förnamn'
                                      />
                                    </Form>
                                  </Col>

                                  <Col xxl={6} md={6}>
                                    <Form>
                                      <Form.Control
                                        className='modalInput'
                                        type='email'
                                        name='currentGuestLastName'
                                        value={
                                          currentGuest.currentGuestLastName
                                        }
                                        onChange={handleGuestInputChange}
                                        style={{
                                          textAlign: 'center',
                                          padding: '8px 24px',
                                          gap: '60px',
                                        }}
                                        size='sm'
                                        placeholder='Efternamn'
                                      />
                                    </Form>
                                  </Col>

                                  <Col xxl={8} md={12}>
                                    <Form.Group controlId='formGuestFoodPref'>
                                      <Form.Control
                                        className='modalInput'
                                        as='textarea'
                                        rows={2}
                                        style={{
                                          textAlign: 'left',
                                          marginBottom: '8px',
                                        }}
                                        name='currentGuestFoodPref'
                                        value={
                                          currentGuest.currentGuestFoodPref
                                        }
                                        placeholder='Matrestriktioner/allergier'
                                        onChange={handleGuestInputChange}
                                      />
                                    </Form.Group>
                                  </Col>

                                  <Col xxl={{ span: 2, offset: 2 }} md={12}>
                                    <Button
                                      style={{
                                        marginTop: '16px',
                                        whiteSpace: 'nowrap',
                                      }}
                                      variant='outline-dark'
                                      size='lg'
                                      className='outline-blackButtonSmall'
                                      onClick={() => {
                                        if (
                                          addGuest.guests.length <
                                          props.event.numberOfGuests
                                        ) {
                                          if (
                                            addGuest.guestFirstName !== '' &&
                                            addGuest.guestLastName !== ''
                                          ) {
                                            let currentGuestCopy =
                                              addGuest.guests;
                                            currentGuestCopy.push({
                                              guestFirstName:
                                                currentGuest.currentGuestFirstName,
                                              guestLastName:
                                                currentGuest.currentGuestLastName,
                                              guestFoodPref:
                                                currentGuest.currentGuestFoodPref,
                                            });
                                            setAddGuest({
                                              bringGuest: true,
                                              guests: currentGuestCopy,
                                            });
                                            setShowGuestSuccessMessage(true);
                                            const timeOut = setTimeout(() => {
                                              setShowGuestSuccessMessage(false);
                                              setCurrentGuest({
                                                currentGuestFirstName: '',
                                                currentGuestLastName: '',
                                                currentGuestFoodPref: '',
                                              });
                                            }, 2000);
                                            return () => {
                                              clearTimeout(timeOut);
                                            };
                                          } else {
                                            setShowGuestErrorMessage(true);
                                            const timeOut = setTimeout(() => {
                                              setShowGuestErrorMessage(false);
                                            }, 2000);
                                            return () => {
                                              clearTimeout(timeOut);
                                            };
                                          }
                                        } else {
                                          setShowTooManyGuestsError(true);
                                          const timeOut = setTimeout(() => {
                                            setShowTooManyGuestsError(false);
                                          }, 2000);
                                          return () => {
                                            clearTimeout(timeOut);
                                          };
                                        }
                                      }}
                                    >
                                      Lägg till
                                    </Button>
                                  </Col>
                                </Row>
                                {addGuest.guests.length !== 0 ? (
                                  addGuest.guests.map((guest, index) => {
                                    return (
                                      <Row className='mb-3 mt-3'>
                                        <Col xs={6} md={4}>
                                          <p
                                            style={{
                                              marginTop: 'auto',
                                              marginBottom: 'auto',
                                            }}
                                          >
                                            <strong>Namn: </strong>
                                            {guest.guestFirstName +
                                              ' ' +
                                              guest.guestLastName}{' '}
                                            <strong>Matpreferens: </strong>
                                            {guest.guestFoodPref}
                                          </p>
                                        </Col>
                                        <Col xs={6} md={4}>
                                          <Button
                                            style={{
                                              marginTop: 'auto',
                                              marginBottom: 'auto',
                                            }}
                                            size='sm'
                                            variant='danger'
                                            onClick={() =>
                                              removeUserFromInviteList(index)
                                            }
                                          >
                                            <XCircle></XCircle>
                                          </Button>
                                        </Col>
                                      </Row>
                                    );
                                  })
                                ) : (
                                  <>
                                    {props.event.numberOfGuests === 1 ? (
                                      <em>Du kan ta med 1 gäst.</em>
                                    ) : (
                                      <em>
                                        Du kan ta med{' '}
                                        {props.event.numberOfGuests} gäster.
                                      </em>
                                    )}
                                  </>
                                )}
                                <Row>
                                  <Col>
                                    <Collapse in={showGuestErrorMessage}>
                                      <p style={{ color: 'red' }}>
                                        Måste fylla i alla fält.
                                      </p>
                                    </Collapse>
                                    <Collapse in={showGuestSuccessMessage}>
                                      <p style={{ color: 'green' }}>
                                        {currentGuest.currentGuestFirstName +
                                          ' ' +
                                          currentGuest.currentGuestLastName}{' '}
                                        är tillagd som din gäst.
                                      </p>
                                    </Collapse>
                                    <Collapse in={showTooManyGuestsError}>
                                      <p style={{ color: 'red' }}>
                                        Kan inte bjuda in fler gäster.
                                      </p>
                                    </Collapse>
                                  </Col>
                                </Row>
                              </div>
                            )}
                          </>
                        )}

                      {props.loggedInAsAdmin && showAdminSignUp && (
                        <Row>
                          <Col
                            style={{ marginLeft: 'auto', marginRight: 'auto' }}
                            md={12}
                          >
                            <Form>
                              <Form.Group
                                className='mt-3 mb-3'
                                controlId='formEmail'
                              >
                                <Form.Control
                                  className='modalInput'
                                  type='email'
                                  name='email'
                                  value={userInput.email}
                                  onChange={handleInputChange}
                                  style={{
                                    textAlign: 'center',
                                    padding: '8px 24px',
                                    gap: '60px',
                                  }}
                                  size='lg'
                                  placeholder='Din e-post'
                                />
                              </Form.Group>
                            </Form>
                          </Col>
                        </Row>
                      )}

                      <Row
                        className='mt-5'
                        style={
                          props.currentWindowSize.width < 1200
                            ? { maxWidht: '100%', textAlign: 'center' }
                            : { maxWidht: '100%' }
                        }
                      >
                        {todaysDate <= new Date(props.event.startDate) &&
                          props.loggedInUser &&
                          checkIfSignedUp(
                            props.event,
                            userInput.email.toLowerCase()
                          ) && (
                            <Col xl={6} xs={12}>
                              {props.event.invitedAccounts.length !== 0 &&
                                checkIfSignedUp(
                                  props.event,
                                  userInput.email.toLowerCase()
                                ) && (
                                  <Button
                                    variant='outline-dark'
                                    size='sm'
                                    className='defaultSignUpButton mb-3'
                                    style={{
                                      whiteSpace: 'nowrap',
                                      border: '2px solid #333333',
                                      backgroundColor: 'transparent',
                                      color: '#333333',
                                    }}
                                    onClick={cancelSignUpViaModal}
                                  >
                                    {showAdminSignUp
                                      ? 'Avanmäl'
                                      : 'Avanmäl dig'}
                                  </Button>
                                )}
                              {props.event.invitedAccounts.length === 0 &&
                                props.event.openInvitation &&
                                checkIfSignedUp(
                                  props.event,
                                  userInput.email.toLowerCase()
                                ) && (
                                  <Button
                                    variant='outline-dark'
                                    size='sm'
                                    className='defaultSignUpButton mb-3'
                                    style={{
                                      whiteSpace: 'nowrap',
                                      border: '2px solid #333333',
                                      backgroundColor: 'transparent',
                                      color: '#333333',
                                    }}
                                    onClick={cancelSignUpViaModal}
                                  >
                                    {showAdminSignUp
                                      ? 'Avanmäl'
                                      : 'Avanmäl dig'}
                                  </Button>
                                )}
                            </Col>
                          )}

                        {todaysDate <= new Date(props.event.lastDate) &&
                          props.loggedInUser && (
                            <Col xl={6} xs={12}>
                              {props.event.invitedAccounts.length !== 0 &&
                                !checkIfSignedUp(
                                  props.event,
                                  userInput.email.toLowerCase()
                                ) &&
                                !showAdminSignUp && (
                                  <Button
                                    variant='dark'
                                    size='lg'
                                    className='defaultSignUpButton mb-3'
                                    style={{ whiteSpace: 'nowrap' }}
                                    onClick={submitUserInput}
                                  >
                                    {props.event.acceptedAccounts.length <
                                    props.event.maxParticipants
                                      ? 'Anmäl dig'
                                      : 'Anmäl dig som reserv'}
                                  </Button>
                                )}
                              {props.event.invitedAccounts.length === 0 &&
                                !checkIfSignedUp(
                                  props.event,
                                  userInput.email.toLowerCase()
                                ) &&
                                props.event.openInvitation &&
                                !showAdminSignUp && (
                                  <Button
                                    variant='dark'
                                    size='lg'
                                    className='defaultSignUpButton mb-3'
                                    style={{ whiteSpace: 'nowrap' }}
                                    onClick={submitUserInput}
                                  >
                                    {props.event.acceptedAccounts.length <
                                    props.event.maxParticipants
                                      ? 'Anmäl dig'
                                      : 'Anmäl dig som reserv'}
                                  </Button>
                                )}
                              {props.event.invitedAccounts.length !== 0 &&
                                !checkIfSignedUp(
                                  props.event,
                                  userInput.email.toLowerCase()
                                ) &&
                                showAdminSignUp && (
                                  <Button
                                    variant='dark'
                                    size='lg'
                                    className='defaultSignUpButton mb-3'
                                    style={{ whiteSpace: 'nowrap' }}
                                    onClick={submitUserInput}
                                  >
                                    {props.event.acceptedAccounts.length <
                                    props.event.maxParticipants
                                      ? 'Anmäl'
                                      : 'Anmäl som reserv'}
                                  </Button>
                                )}
                              {props.event.invitedAccounts.length === 0 &&
                                !checkIfSignedUp(
                                  props.event,
                                  userInput.email.toLowerCase()
                                ) &&
                                props.event.openInvitation &&
                                showAdminSignUp && (
                                  <Button
                                    variant='dark'
                                    size='lg'
                                    className='defaultSignUpButton mb-3'
                                    style={{ whiteSpace: 'nowrap' }}
                                    onClick={submitUserInput}
                                  >
                                    {props.event.acceptedAccounts.length <
                                    props.event.maxParticipants
                                      ? 'Anmäl'
                                      : 'Anmäl som reserv'}
                                  </Button>
                                )}
                            </Col>
                          )}
                      </Row>

                      <Collapse in={showSuccessMessage}>
                        <h4 style={{ color: 'green' }}>
                          Du är nu anmäld till "{props.event.title}"
                        </h4>
                      </Collapse>
                      <Collapse in={showCancelMessage}>
                        <h4 style={{ color: 'orange' }}>
                          Du har nu tagit bort din anmälan från eventet: "
                          {props.event.title}"
                        </h4>
                      </Collapse>
                      <Collapse in={showErrorMessage}>
                        <h4 style={{ color: 'red' }}>
                          Inkorrekt E-postadress eller så har du inte blivit
                          bjuden.
                        </h4>
                      </Collapse>
                      <Collapse in={showSignUpServerError}>
                        <h4 style={{ color: 'red' }}>
                          Gick att anmäla sig just nu (serverfel). Kvarstår
                          problemet, kontakta vänligen admin.
                        </h4>
                      </Collapse>
                      <Collapse in={showErrorCancelMessage}>
                        <h4 style={{ color: 'red' }}>
                          Inkorrekt E-postadress eller så är du inte anmäld.
                        </h4>
                      </Collapse>
                      <Collapse in={showAlreadySignedUp}>
                        <h4 style={{ color: 'orange' }}>
                          Du är redan anmäld till eventet "{props.event.title}"
                        </h4>
                      </Collapse>
                    </>
                  ) : (
                    <div className='modalSignUpArea'>
                      <Row>
                        <Col>
                          {props.event.invitedAccounts.length === 0 &&
                            !props.event.openInvitation &&
                            props.event.active &&
                            !showTooLateMessage && (
                              <p style={{ fontSize: '200%' }}>
                                Ingen anmälan behövs till detta event. Hoppas vi
                                ses{' '}
                                {props.event.eventType === 1
                                  ? ' på go’ frulle'
                                  : props.event.eventType === 4
                                  ? ' på afterwork'
                                  : ''}
                                !
                              </p>
                            )}
                          {showTooLateMessage && props.event.active && (
                            <p style={{ fontSize: '200%' }}>
                              Det är tyvärr för sent för att anmäla sig till
                              detta event.
                            </p>
                          )}
                          {!props.event.active && (
                            <p style={{ fontSize: '200%' }}>
                              Det här eventet är inte aktivt ännu.
                            </p>
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
                <Row className='mt-5' style={{ minHeight: '72px' }}>
                  <Col>
                    <p className='modalEventDescription'>
                      <strong style={{ fontSize: '18px' }}>Beskrivning:</strong>{' '}
                      <br /> {fixEventDescription(props.event.description)}
                    </p>
                  </Col>
                </Row>
                <div className='eventImageArea'>
                  <Row>
                    <Col>
                      <Image
                        className='eventImage'
                        rounded
                        fluid
                        src={
                          props.event.imageURL !== ''
                            ? props.event.imageURL
                            : Logo
                        }
                        alt='event Image'
                      />
                    </Col>
                  </Row>
                </div>
              </Container>
            </Modal.Body>
          </Modal>

          <Modal
            aria-labelledby='example-custom-modal-styling-title'
            className='modal center'
            show={showListModal}
            onHide={hideListModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {props.event.title} -{' '}
                {props.event.acceptedAccounts.length === 1
                  ? props.event.acceptedAccounts.length + ' anmäld'
                  : props.event.acceptedAccounts.length + ' anmälda'}
              </Modal.Title>
              {checkPermission(props.event) && (
                <>
                  <ExcelGenerator
                    style={{ marginLeft: '32px' }}
                    excelData={generateJsonForExcel(props.event)}
                    fileName={
                      props.event.title.replace(/\s/g, '') + '_deltagarlista'
                    }
                    buttonText={<Download />}
                  />
                  <Tooltip title='Skicka mail till anmälda'>
                    <a href={generateMailingListString(props.event)}>
                      <Button variant='dark' style={{ marginLeft: '16px' }}>
                        {<Envelope />}
                      </Button>
                    </a>
                  </Tooltip>
                </>
              )}
            </Modal.Header>
            <Modal.Body>
              <Row className='m-3'>
                <Col className='me-3' style={{ borderRight: '2px solid #eee' }}>
                  <h4>Deltagarlista</h4>
                  {props.event.acceptedAccounts.map((email, index) => {
                    if (index < props.event.maxParticipants) {
                      return (
                        <p key={index}>
                          <strong>{email}</strong>{' '}
                          {checkPermission(props.event) &&
                            ' - ' + 'Matpreferens: ' + getFoodPreference(email)}
                        </p>
                      );
                    }
                  })}
                </Col>
                <Col>
                  <h4>Reservlista</h4>
                  {props.event.acceptedAccounts.map((email, index) => {
                    if (index >= props.event.maxParticipants) {
                      return (
                        <p key={index}>
                          <strong>{email}</strong>{' '}
                          {checkPermission(props.event) &&
                            ' - ' + 'Matpreferens: ' + getFoodPreference(email)}
                        </p>
                      );
                    }
                  })}
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};
