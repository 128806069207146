import { useQuery } from '@tanstack/react-query';
import { makeFetchRequest } from 'services/api';

export function useAccountsQuery() {
  return useQuery({
    queryKey: ['accounts'],
    retry: false,
    queryFn: () => makeFetchRequest('Accounts'),
  });
}
