import React from 'react';
import { Form } from 'react-bootstrap';

export const Inputfield = (props) => {
  return (
    <Form.Group className='mb-3' controlId={props?.controlId}>
      <Form.Label>{props.label}</Form.Label>
      <Form.Control
        style={{ textAlign: 'left' }}
        className='modalInput'
        type={props.type}
        name={props?.name}
        value={props.value}
        placeholder={props?.placeholder}
        onChange={props.onChange}
        as={props.as}
        rows={props.rows}
        size={props?.size}
      />
    </Form.Group>
  );
};
