import React from 'react'
import { Button } from 'react-bootstrap';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { Tooltip } from '@mui/material';

//This class is to generate an excel file of an events participants list
export const ExcelGenerator = (props) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet((props.excelData));
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, props.fileName + fileExtension);
    }

    return (
        <>
            <Tooltip title='Exportera deltagarlistan'>
                <Button style={{ marginLeft: '32px' }} variant='dark' onClick={(e) => exportToExcel(props.fileName)}>
                    {props.buttonText}
                </Button>
            </Tooltip>
        </>
    )
}
