import { Button } from 'react-bootstrap';

interface ICancelButtonProps {
  onClick: () => void;
  showAdminSignUp: boolean;
}

const CancelButton = ({ onClick, showAdminSignUp }: ICancelButtonProps) => {
  return (
    <Button
      variant='outline-dark'
      size='sm'
      className='defaultSignUpButton mb-3'
      style={{
        whiteSpace: 'nowrap',
        border: '2px solid #333333',
        backgroundColor: 'transparent',
        color: '#333333',
      }}
      onClick={onClick}
    >
      {showAdminSignUp ? 'Avanmäl' : 'Avanmäl dig'}
    </Button>
  );
};
export default CancelButton;
