import React from 'react';
import { Form } from 'react-bootstrap';

export const InputSelect = (props) => {
  return (
    <Form.Group>
      <Form.Label>{props?.label}</Form.Label>
      <Form.Select
        className={props?.className}
        id={props.id}
        size={props?.size}
        style={props?.style}
        onChange={props.onChange}
        aria-label={props?.ariaLabel}
      >
        {props.options.map((option) => {
          return <option value={option.value}>{option.text}</option>;
        })}
      </Form.Select>
    </Form.Group>
  );
};
