import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';

export const DropdownSelect = (props) => {
  return (
    <Box
      sx={
        props.currentWindowSize.width <= 576
          ? {
              m: '0 auto',
              maxWidth: 150,
              backgroundColor: '#F0F0F0',
            }
          : {
              m: 0,
              minWidth: 100,
              maxWidth: 150,
              backgroundColor: '#F0F0F0',
            }
      }
    >
      <FormControl fullWidth>
        <InputLabel id='simple-select-label'>Tid</InputLabel>
        <Select
          labelId='simple-select-label'
          id='simple-select'
          value={props.eventTimeFilter}
          label={props.label}
          onChange={(e) => props.setEventTimeFilter(e.target.value)}
          MenuProps={props.MenuProps}
        >
          {props.menuItems.map((item, index) => {
            return (
              <MenuItem value={item.value} key={'menuItem' + index}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};
