import { useState, useLayoutEffect } from 'react';
import { IWindowProps } from 'types';

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<IWindowProps>({
    width: 0,
    height: 0,
  });

  // Handler to call on window resize
  function handleResize() {
    // Set window width/height to state
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }
  useLayoutEffect(() => {
    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
