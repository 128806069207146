import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'providers/ReactQueryProvider';
import { makeFetchRequest } from 'services/api';

export function useEventSignUpMutation({ eventId }: { eventId: number }) {
  const mutation = async ({
    action,
    email,
  }: {
    action: string;
    email: string;
  }) => {
    if (action === 'accept') {
      return await makeFetchRequest(
        `Accounts/accept?email=${email.toLocaleLowerCase()}&eventId=${eventId}`,
        'POST'
      );
    }
    if (action === 'cancel') {
      return await makeFetchRequest(
        `Accounts/unaccept?email=${email.toLocaleLowerCase()}&eventId=${eventId}`,
        'POST'
      );
    }
  };
  return useMutation({
    mutationFn: mutation,
    onSuccess: (response: any) => {
      queryClient.invalidateQueries(['event', { eventId: Number(eventId) }]);
      queryClient.invalidateQueries(['events']);
      return response;
    },
  });
}
