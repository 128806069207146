import React, { useState, useEffect } from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import './CalendarView.css'
import { EventSignUpModal } from './modals/EventSignUpModal';
// import { EditEventModal } from './modals/EditEventModal';
import { EventModal } from './modals/EventModal';



export const CalendarView = (props) => {
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [showSignUpModal, setShowSignUpModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState(null);
    let date = new Date();
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0');
    let yyyy = date.getFullYear();
    let todayOnlyDate = String(yyyy + '-' + mm + '-' + dd);

    //Opens the sign up modal from the CalendarView
    const handleShowSignUpModal = (value) => {
        setShowSignUpModal(value);
    }

    //On click, sets the event-state to the clicked events "original form".
    const displayEventSignUp = (infoEvent) => {
        let pickedEvent = props.events.find(event => event.title === infoEvent.title && new Date(event.start) - infoEvent.start === 0 && new Date(event.end) - infoEvent.end === 0)
        setSelectedEvent(pickedEvent.originalForm);
        handleShowSignUpModal(true);
    }

    //Opens the edit modal from the CalendarView
    const displayEditEventModal = () => {
        handleShowSignUpModal(false);
        handleModalType('edit');
        handleShowModal(true);
    }

    const handleShowModal = (value) => {
        setShowModal(value);
    }

    const handleModalType = (value) => {
        setModalType(value);
    }

    //If there are any unusual times, (late at night or early morning), change the timeInterval for the calendar to 00-24 instead of 7-21
    const checkForUnusualTimes = () => {
        if (props.events !== null) {
            let result = props.events.filter(event => parseInt(event.start.substring(11, 13)) > 21 || parseInt(event.start.substring(11, 13)) < 7);
            if (result.length > 0) {
                let dayDifference = [];
                result.forEach((event) => {
                    const todayDate = new Date(todayOnlyDate);
                    const eventDate = new Date(event.start.substring(0, 10));
                    const diffTime = Math.abs(eventDate - todayDate);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                    dayDifference.push(diffDays);
                })
                return dayDifference.some(day => day < 14);
            } else {
                return false;
            }
        }
    }

    useEffect(() => {
        window.onload = () => {
            document.getElementById('fullCalendar').className = 'row col-lg-12'
        }
    }, [])

    return (
        <div id='fullCalendar' className='calendarContainer fade-in-animation'>
            <FullCalendar
                className='fade-in-animation'
                plugins={[dayGridPlugin, timeGridPlugin]}
                initialView={props.currentWindowSize.width > 596 ? "dayGridMonth" : "timeGridDay"}
                headerToolbar={props.currentWindowSize.width > 596 ? {
                    start: 'today prev next',
                    center: 'title',
                    end: 'timeGridDay timeGridWeek dayGridMonth'
                } : {
                    start: 'prev',
                    center: 'title',
                    end: 'next'
                }}
                footerToolbar={props.currentWindowSize.width > 596 ? false : {
                    center: 'timeGridDay timeGridWeek'
                }}
                views={{
                    month: {
                        titleFormat: { year: 'numeric',  month: 'long' },
                     },
                     week: props.currentWindowSize > 596 ? {
                        titleFormat: { year: 'numeric',  month: 'long', day: 'numeric' }
                     }
                     :
                     {
                        titleFormat: { year: 'numeric',  month: 'short', day: 'numeric' }
                     }
                   }}
                titleFormat={props.currentWindowSize > 596 ? {
                    year: 'numeric',
                    month: 'long'
                } : {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                }}
                height={'auto'}
                expandRows={true}
                weekNumberCalculation={'ISO'}
                events={props.events}
                eventTimeFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: false
                }}
                displayEventTime={props.currentWindowSize.width > 1600 ? true : false}
                displayEventEnd={false}
                eventClick={(info) => displayEventSignUp(info.event)}
                buttonText={{
                    today: 'Idag',
                    month: 'Månad',
                    week: 'Vecka',
                    day: 'Dag',
                }}
                dayNames={['Söndag', 'Måndag', 'Tisdag', 'Onsdag',
                    'Torsdag', 'Fredag', 'Lördag']}
                locale='swe'
                updateSize={true}
                handleWindowResize={true}
                themeSystem={'bootstrap5'}
                eventMinHeight='50'
                eventShortHeight='25'
                slotMinTime={checkForUnusualTimes() === true ? '00:00:00' : '07:00:00'}
                slotMaxTime={checkForUnusualTimes() === true ? '24:00:00' : '21:00:00'}
                allDaySlot={!!props.events && props.events.some(event => event.allday === true) ? true : false}
                nowIndicator={true}
                weekNumbers={true}
            />
            <EventSignUpModal
                event={selectedEvent}
                setShow={handleShowSignUpModal}
                show={showSignUpModal}
                changeCurrentTheme={props.changeCurrentTheme}
                loggedInAsAdmin={props.loggedInAsAdmin}
                displayEditEventModal={displayEditEventModal}
                currentWindowSize={props.currentWindowSize}
                loggedInUser={props.loggedInUser}
                fetchedEvents={props.fetchedEvents}
            />
         

            <EventModal
                event={modalType === 'edit' ? selectedEvent : null}
                show={showModal}
                setShow={handleShowModal}
                type={modalType}
                handleModalType={handleModalType}
                currentWindowSize={props.currentWindowSize}
                loggedInUser={props.loggedInUser}
            />
        </div>

    )
}
