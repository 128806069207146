import React from 'react';
import { Form } from 'react-bootstrap';

export const InputSwitch = (props) => {
  return (
    <Form.Group
      className='mb-3'
      controlId={props?.controlId}
      style={props?.style}
    >
      <Form.Label className='me-1'>{props?.label}</Form.Label>
      <Form.Check
        className={props?.className}
        type='switch'
        name={props?.name}
        hasNoBoolValue={props?.hasNoBoolValue}
        id={props?.id}
        onChange={() =>
          props?.hasNoBoolValue === true
            ? props?.onChange()
            : props?.setShow(!props?.show)
        }
      ></Form.Check>
    </Form.Group>
  );
};
