import React from 'react';
import { Navbar, Container, Button } from 'react-bootstrap';
// import { Instagram, Linkedin, Calendar2Event, Folder } from 'react-bootstrap-icons';
import Logo from '../images/hiqLogoTransparent.png';
import { useNavigate, useLocation } from 'react-router-dom';
import './Header.css';
import { useMsal, AuthenticatedTemplate } from '@azure/msal-react';
import { EMain } from 'enums';

function Header(props) {
  const navigate = useNavigate();
  const location = useLocation();

  //Log out the user and redirect to the Intranet main page.
  function signOutClickHandler(instance) {
    const logoutRequest = {
      account: props.loggedInUser.homeAccountId,
      postLogoutRedirectUri:
        'https://hiq365.sharepoint.com/teams/hiq_south_skane',
    };
    instance.logoutRedirect(logoutRequest);
  }

  function SignOutButton() {
    const { instance } = useMsal();

    return (
      <Button
        className='ms-3'
        style={{ border: '2px solid grey', backgroundColor: 'transparent' }}
        onClick={() => signOutClickHandler(instance)}
      >
        Logga ut
      </Button>
    );
  }

  return (
    <Navbar sticky='top' variant='dark' bg='dark'>
      <Container>
        <Navbar.Brand
          title='Hem'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            props.displayBackgroundGif(true);
            if (location.pathname === '/') {
              navigate(0);
            } else {
              navigate('/');
            }
          }}
        >
          <Button size='sm' variant='dark'>
            <img
              className='d-inline-block align-top'
              src={Logo}
              alt='HiQ Logo'
              height={'30px'}
              width='60px'
            ></img>
          </Button>
        </Navbar.Brand>
        <h1
          onClick={() => {
            props.displayBackgroundGif(true);
            if (location.pathname === '/') {
              navigate(0);
            } else {
              navigate('/');
            }
          }}
          className='headerTitle'
        >
          {EMain.EVENTPLANNER_TITLE}
        </h1>
        <Navbar.Toggle />
        <Navbar.Collapse className='justify-content-end'>
          <Navbar.Text>
            {!!props.loggedInUser && !!props.loggedInUser.name && (
              <h1 className='headerTitle'>
                Hejsan, {props.loggedInUser.name}! 😊
              </h1>
            )}
          </Navbar.Text>
          <AuthenticatedTemplate>
            <SignOutButton />
          </AuthenticatedTemplate>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
