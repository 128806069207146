import { Button } from 'react-bootstrap';

interface ISignUpButtonProps {
  onClick: () => void;
  signUpAsReserve: boolean;
  showAdminSignUp?: boolean;
}

const SignUpButton = ({
  onClick,
  signUpAsReserve,
  showAdminSignUp,
}: ISignUpButtonProps) => {
  return (
    <Button
      variant='dark'
      className='mb-3 defaultSignUpButton'
      style={{ whiteSpace: 'nowrap' }}
      onClick={onClick}
    >
      {showAdminSignUp
        ? signUpAsReserve
          ? 'Anmäl som reserv'
          : 'Anmäl'
        : signUpAsReserve
        ? 'Anmäl dig som reserv'
        : 'Anmäl dig'}
    </Button>
  );
};

export default SignUpButton;
