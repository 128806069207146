import React from 'react'
import { Row, Col } from 'react-bootstrap';


export const ErrorPage = (props) => {
    //Displays this error page if a user enters an invalid URL.
    return (
        <div>
            <div style={{ textAlign: 'center' }}>
                <Row>
                    <Col md={12}>
                        <h1 style={{ margin: '30vh auto 0 auto', fontSize: 'xxx-large' }}>{!!props.errorMessage ? props.errorMessage : 'Sidan hittades inte'}</h1>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
