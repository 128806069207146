export const checkDate = (
  event: any,
  setShowTooLateMessage: (value: Boolean) => void
) => {
  let today = new Date();
  let min = today.getMinutes().toString().padStart(2, '0');
  let hh = today.getHours().toString().padStart(2, '0');
  let dd = today.getDate().toString().padStart(2, '0');
  let mm = (today.getMonth() + 1).toString().padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear().toString();
  let today2 = new Date(yyyy + '-' + mm + '-' + dd + 'T' + hh + ':' + min);
  const lastSignUpDate = new Date(event.lastDate);
  if (today2 >= lastSignUpDate) {
    setShowTooLateMessage(true);
  } else {
    setShowTooLateMessage(false);
  }
};

/* Accepted accounts may come as semi-colon-separated string. The below functions creates array of strings. */
export const fixAcceptedInvites = (event: any) => {
  if (typeof event.acceptedAccounts === 'string') {
    event.acceptedAccounts = event.acceptedAccounts
      .split(';')
      .filter((e: string) => e !== '');
  }
  return event;
};

export const fixAcceptedInvitesForAll = (events: any) => {
  return events.map((event: any) => {
    if (typeof event.acceptedAccounts === 'string') {
      event.acceptedAccounts = event.acceptedAccounts
        .split(';')
        .filter((e: string) => e !== '');
    }
    return event;
  });
};
/* ******************************************************************************************************** */

export const isInviteOnly = (event: any) => {
  return !!event.invitedAccounts.length && !event.openInvitation;
};

export const isInvited = (event: any, userEmail: string) => {
  return event.invitedAccounts.includes(userEmail.toLocaleLowerCase());
};
