import React, { useState } from 'react'
import { Button, Form, Dropdown } from 'react-bootstrap';

//This Component lets the user select a location by either typing the location or by selecting from a set list (locationSuggestions)
export const AutoSuggestInput = (props) => {
  
  const [selectedLocation, setSelectedLocation] = useState('');

  const choseLocation = value => {
    setSelectedLocation(value)
    props.choseLocation(value)
  }

  return (
    <div className='modalInput'>
      <Dropdown>
        <Dropdown.Toggle style={{ width: '100%', backgroundColor: '#fff', color: '#212529', border: '1px solid #ced4da', textAlign: 'left' }} variant='dark' id="dropdown-custom-components" size='sm'>
          {props.dropdownName}
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ maxHeight: '25vh', minWidth: '15vw', overflowY: 'auto' }}>
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto modalInput"
            placeholder={props.placeholder}
            onChange={(e) => choseLocation(e.target.value)}
            value={selectedLocation}
            style={{ minWidth: '50%' }}
          />
          <Dropdown.Divider />
          {props.locationSuggestions.map((location, index) => {
            return (
              <>
                <Dropdown.Item key={index} onClick={() => choseLocation(location)}>{location}</Dropdown.Item>
                {index !== props.locationSuggestions.length - 1 ?
                  <Dropdown.Divider />
                  :
                  null
                }

              </>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
