import React, { useState, useEffect, useMemo } from 'react';
import './EventList.css';
import { Button, Container, Row, Col, Spinner, Image } from 'react-bootstrap';
import AnimatedPage from '../components/AnimatedPage';
import { EventSignUpModal } from '../components/modals/EventSignUpModal';
import { CalendarView } from '../components/CalendarView';
import { CalendarDate, ListUl } from 'react-bootstrap-icons';
import { EventModal } from '../components/modals/EventModal';
import coverPicture from '../images/events_coverbild.png';
import { EventItem } from '../components/EventItem';
import { DropdownSelect } from '../components/DropdownSelect';
import { DropdownCheckbox } from '../components/DropdownCheckbox';
import { InputSwitch } from '../components/InputSwitch';
import { useWindowSize } from 'hooks/useWindowSize';
import { useEventsQuery } from 'hooks/useEventsQuery';
import { useAccountsQuery } from 'hooks/useAccountsQuery';
import {
  fixAcceptedInvitesForAll,
  fixAcceptedInvites,
  isInviteOnly,
  isInvited,
} from 'utils.js';

export const EventList = (props) => {
  const currentWindowSize = useWindowSize();
  const { loggedInUser, loggedInAsAdmin } = props;
  const {
    status: eventsStatus,
    data: eventsData,
    error: eventsError,
  } = useEventsQuery();
  const {
    status: accountsStatus,
    data: accountsData,
    error: accountsError,
  } = useAccountsQuery();

  const currentDate = new Date();

  const [categoryName, setCategoryName] = useState(['Alla kategorier']);
  const [locationName, setLocationName] = useState(['Alla platser']);

  const categories = [
    'Alla kategorier',
    'Frukost',
    'Lunch & Learn',
    'Teamaktivitet',
    'Afterwork',
    'Fest',
    'Familjedag',
    'Sport',
    'Kundevent',
    'HiQ Community',
    'Övrigt',
  ];

  const locations = [
    'Alla platser',
    'Malmö',
    'Lund',
    'Karlskrona',
    'Helsingborg',
    'Digitalt',
  ];

  var categoriesCopy = categories;

  //Used for the filter dropdown components.
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        backgroundColor: '#F0F0F0',
      },
    },
  };

  //Called when the users changes the filter options for categories. If the filter list only contains "All categories" and another filter is added, remove "All categories."
  const handleCategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length > 1 && value.includes('Alla kategorier')) {
      let newValue = [];
      value.forEach((category) => {
        if (category !== 'Alla kategorier') {
          newValue.push(category);
        }
      });
      setCategoryName(
        typeof newValue === 'string' ? newValue.split(',') : newValue
      );
    } else {
      setCategoryName(typeof value === 'string' ? value.split(',') : value);
    }
  };

  //Called when the users changes the filter options for locations. If the filter list only contains "All locations" and another filter is added, remove "All locations."
  const handleLocationChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length > 1 && value.includes('Alla platser')) {
      let newValue = [];
      value.forEach((location) => {
        if (location !== 'Alla platser') {
          newValue.push(location);
        }
      });
      setLocationName(
        typeof newValue === 'string' ? newValue.split(',') : newValue
      );
    } else {
      setLocationName(typeof value === 'string' ? value.split(',') : value);
    }
  };

  let date = new Date();
  let dd = String(date.getDate()).padStart(2, '0');
  let mm = String(date.getMonth() + 1).padStart(2, '0');
  let yyyy = date.getFullYear();
  let todayOnlyDate = String(yyyy + '-' + mm + '-' + dd);

  const [fetchedAccounts, setFetchedAccounts] = useState(null);
  const [fetchedEvents, setFetchedEvents] = useState(null);
  const [todaysDate, setTodaysDate] = useState(new Date(todayOnlyDate));
  const [eventTimeFilter, setEventTimeFilter] = useState('month');

  const [showReoccuringEvents, setShowReoccuringEvents] = useState(true);
  const [showParticipants, setShowParticipants] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showListModal, setShowListModal] = useState(null);
  const [showMyEvents, setShowMyEvents] = useState(false);

  const [eventsByMonth, setEventsByMonth] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [changeEventView, setChangeEventView] = useState(false);
  const [eventsForCalendarView, setEventsForCalendarView] = useState(null);

  const [modalType, setModalType] = useState(null);

  const [fetchAccountsError, setFetchAccountsError] = useState(false);
  const [fetchEventsError, setFetchEventsError] = useState(false);

  const fixAndFilterEvents = () => {
    // Convert to array of strings...
    const fixedAcceptedEvents = fixAcceptedInvitesForAll(eventsData);

    // Filter out events non-admin user isn't invited to...
    const filteredEvents = fixedAcceptedEvents.filter((event) => {
      if (isInviteOnly(event) && !loggedInAsAdmin) {
        return isInvited(event, loggedInUser.email);
      }
      return true;
    });

    return filteredEvents;
  };

  useMemo(() => {
    if (eventsData) {
      const fixedAndFiltered = fixAndFilterEvents();
      setFetchedEvents(fixedAndFiltered);
    }
  }, [eventsData]);

  //Set todays date in the same format as the events dates.
  useEffect(() => {
    const interval = setInterval(() => {
      const date = new Date();
      const dd = String(date.getDate()).padStart(2, '0');
      const mm = String(date.getMonth() + 1).padStart(2, '0');
      const yyyy = date.getFullYear();
      const todayOnlyDate = String(yyyy + '-' + mm + '-' + dd);

      setTodaysDate(new Date(todayOnlyDate));
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  //Change the theme back to default theme when navigating from EventSignup.js component.
  useEffect(() => {
    if (!!props.changeCurrentTheme)
      props.changeCurrentTheme('App-container-no-background');
  }, []);

  //Change the dropdown value once the events have been fetched.
  useEffect(() => {
    if (
      fetchedEvents !== null &&
      !changeEventView &&
      !!document.getElementById('selectTimeFilter')
    ) {
      document.getElementById('selectTimeFilter').value = eventTimeFilter;
    } else {
      return;
    }
  }, [fetchedEvents]);

  //Filter function --> Toggle events that are reoccuring
  useEffect(() => {
    filterEventsByReoccurence();
  }, [showReoccuringEvents]);

  //Filter function --> Toggle events that the user have created, signed up to, or have been invited to.
  useEffect(() => {
    filterMyEvents();
  }, [showMyEvents]);

  //Refetch the events and accounts once closing a modal.
  useEffect(() => {
    if (!!document.getElementById('selectTimeFilter') && !!eventTimeFilter) {
      document.getElementById('selectTimeFilter').value = eventTimeFilter;
      // setEventTimeFilter('month')
    }
  }, []);

  //Once the events have been fetched, group them by month for the list and prepare them for the calendar view.
  useEffect(() => {
    if (fetchedEvents !== null && fetchedEvents.length > 0) {
      groupEventsByMonth();
      changeEventsForCalendarView();
    }
  }, [fetchedEvents, props.loggedInAsAdmin]);

  //Change the dropdown value when the view is changed.
  useEffect(() => {
    if (!!document.getElementById('selectTimeFilter') && !!eventTimeFilter) {
      document.getElementById('selectTimeFilter').value = eventTimeFilter;
      // setEventTimeFilter('month')
    }
  }, [changeEventView]);

  if (accountsStatus === 'loading' || eventsStatus === 'loading') {
    return <p role='alert'>Hämtar data...</p>;
  }

  //Call to the API to fetch the accounts
  const fetchAccounts = async () => {
    await fetch(
      /*'https://localhost:7038/api/Accounts'*/ 'https://eventhandler-backend.azurewebsites.net/api/Accounts',
      {
        method: 'GET',
        //mode: 'no-cors'
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('Something went wrong when fetching accounts');
        }
      })
      .then((data) => setFetchedAccounts(data))
      .catch((error) => {
        console.error(error);
        setFetchAccountsError(true);
      });
  };

  //Call to the API to fetch the events
  const fetchEvents = async () => {
    await fetch(
      /*'https://localhost:7038/api/Events'*/ 'https://eventhandler-backend.azurewebsites.net/api/Events',
      {
        method: 'GET',
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('Something went wrong when fetching events');
        }
      })
      .then((fixedData) => fixAcceptedInvites(fixedData)) //setFetchedEvents(fixedData));
      .catch((error) => {
        console.error(error);
        setFetchEventsError(true);
      });
  };

  //filter here is "week", "month" or "year", dateToCheck is the date we are comparing to currentDate.
  const checkDifferenceInDays = (filter, dateToCheck) => {
    let dateToCompare = new Date(dateToCheck);

    let differenceInMs = dateToCompare.getTime() - todaysDate.getTime();
    let differenceInDays = Math.ceil(differenceInMs / (1000 * 3600 * 24));

    switch (filter) {
      case 'week':
        if (differenceInDays >= 0 && differenceInDays <= 7) {
          return true;
        } else {
          return false;
        }
      case 'month':
        if (differenceInDays >= 0 && differenceInDays <= 31) {
          return true;
        } else {
          return false;
        }
      case 'year':
        if (differenceInDays >= 0 && differenceInDays <= 365) {
          return true;
        } else {
          return false;
        }
      case 'all':
        return true;
      default:
        return false;
    }
  };

  //Return true if the filter list (categoryName) includes the events type. Always returns true if the list includes "All categories"
  const filterByEventType = (eventType) => {
    if (categoryName.includes('Alla kategorier')) {
      return true;
    } else if (categoryName.includes(categoriesCopy[eventType])) {
      return true;
    } else {
      return false;
    }
  };

  //Return true if the filter list (locationName) includes the events type. Always returns true if the list includes "All locations"
  const filterByEventLocation = (location) => {
    if (locationName.includes('Alla platser')) {
      return true;
    }
    for (let i = 0; i < locationName.length; i++) {
      if (location.toLowerCase().includes(locationName[i].toLowerCase())) {
        return true;
      }
    }
    return false;
  };

  //This function returns true if the event does not have a recurringEventId.
  function filterEventsByReoccurence(event) {
    if (fetchedEvents !== null && !showReoccuringEvents) {
      const eventsWithRecurringEventId = fetchedEvents.filter(
        (event) => event.recurringEventId !== ''
      );
      const eventsToDeleteSet = new Set(eventsWithRecurringEventId);
      const nonReoccuringEvents = fetchedEvents.filter(
        (event) => !eventsToDeleteSet.has(event)
      );
      if (nonReoccuringEvents.includes(event)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  //This functions returns true if the logged in user has created the event, has been invited to the event or has signed up to the event.
  function filterMyEvents(event) {
    if (showMyEvents && !!event) {
      if (
        event.createdBy.toLowerCase() ===
          props.loggedInUser.email.toLowerCase() ||
        event.invitedAccounts.includes(
          props.loggedInUser.email.toLowerCase()
        ) ||
        event.acceptedAccounts.includes(props.loggedInUser.email.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  //Called when the SignUpModal should opened
  const handleShowSignUpModal = (value) => {
    setShowSignUpModal(value);
  };

  //Called when the EventModal should opened
  const handleShowModal = (value) => {
    setShowModal(value);
  };

  // eslint-disable-next-line no-extend-native
  Date.prototype.getWeek = function () {
    var date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return (
      1 +
      Math.round(
        ((date.getTime() - week1.getTime()) / 86400000 -
          3 +
          ((week1.getDay() + 6) % 7)) /
          7
      )
    );
  };

  //Updates the state of "selected event" so that the modals can display the correct event, then opens the signup modal.
  const displayEventSignUp = (event) => {
    setSelectedEvent(event);
    handleShowSignUpModal(true);
  };

  //Called when clicking the "Edit button" from the signup modal. This closes the signup modal, and the eventmodal is called from another place.
  const displayEditEventModal = () => {
    handleShowSignUpModal(false);
    // handleShowEditModal(true);
  };

  //Groups each event into respective month and corresponding year. This is for the eventlist on the main page.
  function groupEventsByMonth() {
    let date = new Date();
    let yyyy = date.getFullYear();

    let months = [
      {
        name: 'januari',
        year: yyyy - 1,
        events: [],
      },
      {
        name: 'februari',
        year: yyyy - 1,
        events: [],
      },
      {
        name: 'mars',
        year: yyyy - 1,
        events: [],
      },
      {
        name: 'april',
        year: yyyy - 1,
        events: [],
      },
      {
        name: 'maj',
        year: yyyy - 1,
        events: [],
      },
      {
        name: 'juni',
        year: yyyy - 1,
        events: [],
      },
      {
        name: 'juli',
        year: yyyy - 1,
        events: [],
      },
      {
        name: 'augusti',
        year: yyyy - 1,
        events: [],
      },
      {
        name: 'september',
        year: yyyy - 1,
        events: [],
      },
      {
        name: 'oktober',
        year: yyyy - 1,
        events: [],
      },
      {
        name: 'november',
        year: yyyy - 1,
        events: [],
      },
      {
        name: 'december',
        year: yyyy - 1,
        events: [],
      },
      {
        name: 'januari',
        year: yyyy,
        events: [],
      },
      {
        name: 'februari',
        year: yyyy,
        events: [],
      },
      {
        name: 'mars',
        year: yyyy,
        events: [],
      },
      {
        name: 'april',
        year: yyyy,
        events: [],
      },
      {
        name: 'maj',
        year: yyyy,
        events: [],
      },
      {
        name: 'juni',
        year: yyyy,
        events: [],
      },
      {
        name: 'juli',
        year: yyyy,
        events: [],
      },
      {
        name: 'augusti',
        year: yyyy,
        events: [],
      },
      {
        name: 'september',
        year: yyyy,
        events: [],
      },
      {
        name: 'oktober',
        year: yyyy,
        events: [],
      },
      {
        name: 'november',
        year: yyyy,
        events: [],
      },
      {
        name: 'december',
        year: yyyy,
        events: [],
      },
      {
        name: 'januari',
        year: yyyy + 1,
        events: [],
      },
      {
        name: 'februari',
        year: yyyy + 1,
        events: [],
      },
      {
        name: 'mars',
        year: yyyy + 1,
        events: [],
      },
      {
        name: 'april',
        year: yyyy + 1,
        events: [],
      },
      {
        name: 'maj',
        year: yyyy + 1,
        events: [],
      },
      {
        name: 'juni',
        year: yyyy + 1,
        events: [],
      },
      {
        name: 'juli',
        year: yyyy + 1,
        events: [],
      },
      {
        name: 'augusti',
        year: yyyy + 1,
        events: [],
      },
      {
        name: 'september',
        year: yyyy + 1,
        events: [],
      },
      {
        name: 'oktober',
        year: yyyy + 1,
        events: [],
      },
      {
        name: 'november',
        year: yyyy + 1,
        events: [],
      },
      {
        name: 'december',
        year: yyyy + 1,
        events: [],
      },
    ];
    fetchedEvents.forEach((event) => {
      if (parseInt(event.startDate.substring(0, 4)) - yyyy === -1) {
        switch (event.startDate.substring(5, 7)) {
          case '01':
            months[0].events.push(event);
            break;
          case '02':
            months[1].events.push(event);
            break;
          case '03':
            months[2].events.push(event);
            break;
          case '04':
            months[3].events.push(event);
            break;
          case '05':
            months[4].events.push(event);
            break;
          case '06':
            months[5].events.push(event);
            break;
          case '07':
            months[6].events.push(event);
            break;
          case '08':
            months[7].events.push(event);
            break;
          case '09':
            months[8].events.push(event);
            break;
          case '10':
            months[9].events.push(event);
            break;
          case '11':
            months[10].events.push(event);
            break;
          case '12':
            months[11].events.push(event);
            break;
          default:
            break;
        }
      } else if (parseInt(event.startDate.substring(0, 4)) === yyyy) {
        switch (event.startDate.substring(5, 7)) {
          case '01':
            months[12].events.push(event);
            break;
          case '02':
            months[13].events.push(event);
            break;
          case '03':
            months[14].events.push(event);
            break;
          case '04':
            months[15].events.push(event);
            break;
          case '05':
            months[16].events.push(event);
            break;
          case '06':
            months[17].events.push(event);
            break;
          case '07':
            months[18].events.push(event);
            break;
          case '08':
            months[19].events.push(event);
            break;
          case '09':
            months[20].events.push(event);
            break;
          case '10':
            months[21].events.push(event);
            break;
          case '11':
            months[22].events.push(event);
            break;
          case '12':
            months[23].events.push(event);
            break;
          default:
            break;
        }
      } else if (parseInt(event.startDate.substring(0, 4)) - yyyy === 1) {
        switch (event.startDate.substring(5, 7)) {
          case '01':
            months[24].events.push(event);
            break;
          case '02':
            months[25].events.push(event);
            break;
          case '03':
            months[26].events.push(event);
            break;
          case '04':
            months[27].events.push(event);
            break;
          case '05':
            months[28].events.push(event);
            break;
          case '06':
            months[29].events.push(event);
            break;
          case '07':
            months[30].events.push(event);
            break;
          case '08':
            months[31].events.push(event);
            break;
          case '09':
            months[32].events.push(event);
            break;
          case '10':
            months[33].events.push(event);
            break;
          case '11':
            months[34].events.push(event);
            break;
          case '12':
            months[35].events.push(event);
            break;
          default:
            break;
        }
      }
      setEventsByMonth(months);
    });
  }

  //Check if the events startDate matches the current timeFilter (within one week, month, year e.t.c)
  const checkDate = (event) =>
    checkDifferenceInDays(eventTimeFilter, event.startDate) === true;

  //Help function for sorting e.t.c
  function getEventLengthInMs(start, end) {
    const startInMs = new Date(start).getTime();
    const endInMs = new Date(end).getTime();
    return endInMs - startInMs;
  }

  //Adding some needed variables for each event when switching from List to CalendarView.
  function changeEventsForCalendarView() {
    if (fetchedEvents !== null) {
      let changedFormatList = [];
      fetchedEvents.forEach((event) => {
        if (event.active === true || checkPermission(event)) {
          let changedEvent = {
            title: event.title,
            start: event.startDate,
            end: event.endTime,
            originalForm: event,
            color: setColorForEvent(event),
            allDay:
              getEventLengthInMs(event.startDate, event.endTime) >= 86340000
                ? true
                : false,
          };
          changedFormatList.push(changedEvent);
        }
      });
      setEventsForCalendarView(changedFormatList);
    }
  }

  //Same as other, instead for CalendarView
  const filterByReoccurenceForCalendarView = () => {
    if (fetchedEvents !== null) {
      if (
        document.getElementById('calendarReoccuringEventFilter').checked ===
        true
      ) {
        let changedFormatList = [];
        let eventsWithRecurringEventId = [];
        fetchedEvents.forEach((event) => {
          if (event.recurringEventId !== '') {
            eventsWithRecurringEventId.push(event);
          }
        });
        const eventsToDeleteSet = new Set(eventsWithRecurringEventId);
        let nonReoccuringEvents = fetchedEvents.filter((event) => {
          return !eventsToDeleteSet.has(event);
        });

        nonReoccuringEvents.forEach((event) => {
          if (event.active === true || checkPermission(event)) {
            let changedEvent = {
              title: event.title,
              start: event.startDate,
              end: event.endTime,
              originalForm: event,
              color: setColorForEvent(event),
              allDay:
                getEventLengthInMs(event.startDate, event.endTime) >= 86340000
                  ? true
                  : false,
            };
            changedFormatList.push(changedEvent);
          }
        });
        setEventsForCalendarView(changedFormatList);
      } else {
        let changedFormatList = [];
        fetchedEvents.forEach((event) => {
          if (event.active === true || checkPermission(event)) {
            let changedEvent = {
              title: event.title,
              start: event.startDate,
              end: event.endTime,
              originalForm: event,
              color: setColorForEvent(event),
              allDay:
                getEventLengthInMs(event.startDate, event.endTime) >= 86340000
                  ? true
                  : false,
            };
            changedFormatList.push(changedEvent);
          }
        });
        setEventsForCalendarView(changedFormatList);
      }
    }
  };

  //Adding some needed variables to be able to filter the list for "my events" when in CalendarView.
  const filterMyEventsForCalendarView = () => {
    if (fetchedEvents !== null) {
      if (document.getElementById('calendarMyEvensFilter').checked === true) {
        let changedFormatList = [];
        let myEvents = [];

        fetchedEvents.forEach((event) => {
          if (
            event.createdBy.toLowerCase() ===
              props.loggedInUser.email.toLowerCase() ||
            event.invitedAccounts.includes(
              props.loggedInUser.email.toLowerCase()
            ) ||
            event.acceptedAccounts.includes(
              props.loggedInUser.email.toLowerCase()
            )
          ) {
            myEvents.push(event);
          }
        });

        myEvents.forEach((event) => {
          if (event.active === true || checkPermission(event)) {
            let changedEvent = {
              title: event.title,
              start: event.startDate,
              end: event.endTime,
              originalForm: event,
              color: setColorForEvent(event),
              allDay:
                getEventLengthInMs(event.startDate, event.endTime) >= 86340000
                  ? true
                  : false,
            };
            changedFormatList.push(changedEvent);
          }
        });
        setEventsForCalendarView(changedFormatList);
      } else {
        let changedFormatList = [];
        fetchedEvents.forEach((event) => {
          if (event.active === true || checkPermission(event)) {
            let changedEvent = {
              title: event.title,
              start: event.startDate,
              end: event.endTime,
              originalForm: event,
              color: setColorForEvent(event),
              allDay:
                getEventLengthInMs(event.startDate, event.endTime) >= 86340000
                  ? true
                  : false,
            };
            changedFormatList.push(changedEvent);
          }
        });
        setEventsForCalendarView(changedFormatList);
      }
    }
  };

  //Same as other, instead for CalendarView
  const filterByEventTypeForCalendarView = (value) => {
    if (fetchedEvents !== null) {
      if (value.includes('Alla kategorier') && value.length === 1) {
        let changedFormatList = [];
        fetchedEvents.forEach((event) => {
          if (event.active === true || checkPermission(event)) {
            let changedEvent = {
              title: event.title,
              start: event.startDate,
              end: event.endTime,
              originalForm: event,
              color: setColorForEvent(event),
              allDay:
                getEventLengthInMs(event.startDate, event.endTime) >= 86340000
                  ? true
                  : false,
            };
            changedFormatList.push(changedEvent);
          }
        });
        setEventsForCalendarView(changedFormatList);
      } else {
        if (value.length > 1 && value.includes('Alla kategorier')) {
          let newValue = [];
          value.forEach((category) => {
            if (category !== 'Alla kategorier') {
              newValue.push(category);
            }
          });
          let changedFormatList = [];
          fetchedEvents
            .filter((event) =>
              newValue.includes(categoriesCopy[event.eventType])
            )
            .forEach((event) => {
              if (event.active === true || checkPermission(event)) {
                let changedEvent = {
                  title: event.title,
                  start: event.startDate,
                  end: event.endTime,
                  originalForm: event,
                  color: setColorForEvent(event),
                  allDay:
                    getEventLengthInMs(event.startDate, event.endTime) >=
                    86340000
                      ? true
                      : false,
                };
                changedFormatList.push(changedEvent);
              }
            });
          setEventsForCalendarView(changedFormatList);
        } else {
          let changedFormatList = [];
          fetchedEvents
            .filter((event) => value.includes(categoriesCopy[event.eventType]))
            .forEach((event) => {
              if (event.active === true || checkPermission(event)) {
                let changedEvent = {
                  title: event.title,
                  start: event.startDate,
                  end: event.endTime,
                  originalForm: event,
                  color: setColorForEvent(event),
                  allDay:
                    getEventLengthInMs(event.startDate, event.endTime) >=
                    86340000
                      ? true
                      : false,
                };
                changedFormatList.push(changedEvent);
              }
            });
          setEventsForCalendarView(changedFormatList);
        }
      }
    }
  };

  //Same as other, instead for CalendarView
  const filterByEventLocationForCalendarView = (value) => {
    //value = ['location1', 'location2', ...]
    if (fetchedEvents !== null) {
      if (value.includes('Alla platser') && value.length === 1) {
        let changedFormatList = [];
        fetchedEvents.forEach((event) => {
          if (event.active === true || checkPermission(event)) {
            let changedEvent = {
              title: event.title,
              start: event.startDate,
              end: event.endTime,
              originalForm: event,
              color: setColorForEvent(event),
              allDay:
                getEventLengthInMs(event.startDate, event.endTime) >= 86340000
                  ? true
                  : false,
            };
            changedFormatList.push(changedEvent);
          }
        });
        setEventsForCalendarView(changedFormatList);
      } else {
        if (value.length > 1 && value.includes('Alla platser')) {
          let newValue = [];
          value.forEach((location) => {
            if (location !== 'Alla platser') {
              newValue.push(location);
            }
          });
          let changedFormatList = [];
          fetchedEvents.forEach((event) => {
            if (
              newValue.some((locationString) =>
                event.location
                  .toLowerCase()
                  .includes(locationString.toLowerCase())
              )
            ) {
              if (event.active === true || checkPermission(event)) {
                let changedEvent = {
                  title: event.title,
                  start: event.startDate,
                  end: event.endTime,
                  originalForm: event,
                  color: setColorForEvent(event),
                  allDay:
                    getEventLengthInMs(event.startDate, event.endTime) >=
                    86340000
                      ? true
                      : false,
                };
                changedFormatList.push(changedEvent);
              }
            }
          });
          setEventsForCalendarView(changedFormatList);
        } else {
          let changedFormatList = [];
          fetchedEvents.forEach((event) => {
            if (
              value.some((locationString) =>
                event.location
                  .toLowerCase()
                  .includes(locationString.toLowerCase())
              )
            ) {
              if (event.active === true || checkPermission(event)) {
                let changedEvent = {
                  title: event.title,
                  start: event.startDate,
                  end: event.endTime,
                  originalForm: event,
                  color: setColorForEvent(event),
                  allDay:
                    getEventLengthInMs(event.startDate, event.endTime) >=
                    86340000
                      ? true
                      : false,
                };
                changedFormatList.push(changedEvent);
              }
            }
          });
          setEventsForCalendarView(changedFormatList);
        }
      }
    }
  };

  //When in CalendarView, color code the dots for each event based on their eventType.
  function setColorForEvent(event) {
    const eventType = event.eventType;
    switch (eventType) {
      case 0:
        return 'grey';
      case 1:
        return '#FFCBA4';
      case 2:
        return 'yellow';
      case 3:
        return 'blue';
      case 4:
        return 'purple';
      case 5:
        return 'pink';
      case 6:
        return '#FFB6C1';
      case 7:
        return '#3EB489';
      case 8:
        return 'orange';
      case 9:
        return 'green';
      case 10:
        return 'grey';
      default:
        return 'grey';
    }
  }

  //Opening Signup or Create/Edit Modal
  const handleModalType = (value) => {
    setModalType(value);
  };

  //Show the modal that displays an events list of signed up users.
  const hideListModal = () => {
    setShowListModal(null);
  };

  //For Admins to display the foodpreference of signed up users for an event.
  const getFoodPreference = (email) => {
    if (accountsData !== null) {
      let user = accountsData.find(
        (user) => user.email.toLowerCase() === email.toLowerCase()
      );
      if (!!user) {
        if (user.foodPreference === '') {
          return 'Inga preferenser';
        } else {
          return user.foodPreference;
        }
      } else {
        return 'Ingen data tillgänglig';
      }
    } else {
      return 'Ingen data tillgänglig';
    }
  };

  //Returns true if the user is logged in as admin or if they have created the event
  const checkPermission = (event) => {
    if (props.loggedInAsAdmin) {
      return true;
    } else {
      if (
        props.loggedInUser.roles.includes('Role.creator') &&
        event.createdBy.toLowerCase() === props.loggedInUser.email.toLowerCase()
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  //Returns a string containing the number of spots left for an event.
  const getNumberOfSpots = (event) => {
    const numberOfSpots = event.maxParticipants - event.acceptedAccounts.length;
    if (numberOfSpots <= 0) {
      return 'Fullbokat!';
    } else if (numberOfSpots === 1) {
      return '1 plats kvar';
    } else {
      return numberOfSpots + ' platser kvar';
    }
  };

  //Generates a json-string/json file of the signed up user for an event. This json-string is then used when generating the excel-file.
  const generateJsonForExcel = (event) => {
    if (!!event && !!accountsData) {
      let accountsToInclude = [];
      let jsonData = [];
      let acceptedAccountsToLowerCase = [];

      event.acceptedAccounts.forEach((email) => {
        acceptedAccountsToLowerCase.push(email.toLowerCase());
      });

      accountsData.forEach((account) => {
        if (acceptedAccountsToLowerCase.includes(account.email.toLowerCase())) {
          accountsToInclude.push(account);
        }
      });

      accountsToInclude.forEach((user) => {
        jsonData.push({
          Mail: user.email,
          Matpreferens: getFoodPreference(user.email),
          Team: user.teamName,
          TeamId: user.teamId,
          Stad: user.employmentCity,
        });
      });
      return jsonData;
    }
  };

  //Returns a string used for opening a mailclient and preparing a mail to all the signed up users for an event.
  const generateMailingListString = (event) => {
    let mailString = 'mailto:';
    for (let i = 0; i < event.acceptedAccounts.length; i++) {
      if (i === 0) {
        mailString = mailString.concat('', event.acceptedAccounts[i]);
      } else {
        mailString = mailString.concat(',', event.acceptedAccounts[i]);
      }
    }
    return mailString;
  };

  return (
    <AnimatedPage>
      <div
        onClick={() => setShowParticipants(null)}
        style={{ minWidth: '100%', minHeight: '100%' }}
      >
        <Container
          style={{
            minWidth: '75vw',
            marginLeft: 'auto',
            marginRight: 'auto',
            minHeight: '100vh',
            padding: '40px 80px',
            gap: '24px',
          }}
        >
          <>
            {fetchedEvents === null ? (
              <div style={{ textAlign: 'center' }}>
                {!fetchAccountsError && !fetchEventsError && (
                  <Row>
                    <Col md={12}>
                      <Spinner
                        style={{
                          width: '15vh',
                          height: '15vh',
                          margin: '30vh auto 0 auto',
                        }}
                        animation='border'
                        role='status'
                      >
                        <span className='visually-hidden'>Loading</span>
                      </Spinner>
                    </Col>
                  </Row>
                )}
              </div>
            ) : (
              <>
                {!fetchAccountsError && !fetchEventsError && (
                  <div className='eventList'>
                    <Row className='mb-3'>
                      <Col xxl={8} md={6}>
                        <Image
                          fluid
                          src={coverPicture}
                          style={{
                            minWidth: '175px',
                            maxWidth: '60%',
                            minHeight: '150px',
                            maxHeight: '250px',
                          }}
                        />
                      </Col>

                      {(props.loggedInAsAdmin ||
                        props.loggedInUser.roles.includes('Role.admin') ||
                        props.loggedInUser.roles.includes('Role.creator')) && (
                        <>
                          <Col xxl={2} md={3}>
                            <Button
                              variant='dark'
                              className='blackButton'
                              onClick={() => {
                                handleModalType('create');
                                handleShowModal(true);
                              }}
                            >
                              Skapa event
                            </Button>
                          </Col>
                        </>
                      )}
                    </Row>

                    <Row className='mb-3 justify-content-md-center'>
                      {!changeEventView && (
                        <Col
                          sm={12}
                          md={12}
                          lg={3}
                          xl={2}
                          xxl={1}
                          className='allMonths me-2 mb-3'
                        >
                          <DropdownSelect
                            currentWindowSize={currentWindowSize}
                            eventTimeFilter={eventTimeFilter}
                            label='Tid'
                            setEventTimeFilter={setEventTimeFilter}
                            MenuProps={MenuProps}
                            menuItems={[
                              {
                                value: 'all',
                                label: 'Alla datum',
                              },
                              {
                                value: 'week',
                                label: 'Vecka',
                              },
                              {
                                value: 'month',
                                label: 'Månad',
                              },
                              {
                                value: 'year',
                                label: 'År',
                              },
                            ]}
                          />
                        </Col>
                      )}

                      <Col
                        sm={12}
                        md={12}
                        lg={3}
                        xl={2}
                        xxl={2}
                        className='allCategories me-2 mb-3'
                      >
                        <DropdownCheckbox
                          currentWindowSize={currentWindowSize}
                          label='Kategorier'
                          valueName={categoryName}
                          handleValueChange={handleCategoryChange}
                          filterForCalendarView={
                            filterByEventTypeForCalendarView
                          }
                          MenuProps={MenuProps}
                          values={categories}
                        />
                      </Col>

                      <Col
                        sm={12}
                        md={12}
                        lg={3}
                        xl={2}
                        xxl={2}
                        className='allCategories me-2 mb-3'
                      >
                        <DropdownCheckbox
                          currentWindowSize={currentWindowSize}
                          label='Plats'
                          valueName={locationName}
                          handleValueChange={handleLocationChange}
                          filterForCalendarView={
                            filterByEventLocationForCalendarView
                          }
                          MenuProps={MenuProps}
                          values={locations}
                        />
                      </Col>
                      {!changeEventView ? (
                        <>
                          <Col
                            md={6}
                            lg={4}
                            xl={2}
                            xxl={2}
                            className='hideLabel'
                          >
                            <InputSwitch
                              controlId='formMyEvents'
                              style={{ display: 'inline-flex' }}
                              label='Visa mina event'
                              className='ms-2 recurringSwitch'
                              name='myEvents'
                              setShow={setShowMyEvents}
                              show={showMyEvents}
                            />
                          </Col>

                          <Col
                            md={6}
                            lg={4}
                            xl={2}
                            xxl={3}
                            className='hideLabel'
                          >
                            <InputSwitch
                              controlId='formReoccuringEvent'
                              style={{ display: 'inline-flex' }}
                              label='Göm återkommande event'
                              className='ms-2 recurringSwitch'
                              name='reoccuringEvent'
                              setShow={setShowReoccuringEvents}
                              show={showReoccuringEvents}
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col md={3} xxl={2} className='hideLabel'>
                            <InputSwitch
                              controlId='formMyEvents'
                              style={{ display: 'inline-flex' }}
                              label='Visa mina event'
                              className='ms-2 recurringSwitch'
                              name='myEvents'
                              id='calendarMyEvensFilter'
                              hasNoBoolValue={true}
                              onChange={filterMyEventsForCalendarView}
                            />
                          </Col>

                          <Col md={4} xxl={3} className='hideLabel'>
                            <InputSwitch
                              controlId='formReoccuringEvent'
                              style={{ display: 'inline-flex' }}
                              label='Göm återkommande event'
                              className='ms-2 recurringSwitch'
                              name='reoccuringEvent'
                              id='calendarReoccuringEventFilter'
                              hasNoBoolValue={true}
                              onChange={filterByReoccurenceForCalendarView}
                            />
                          </Col>
                        </>
                      )}
                      {!changeEventView ? (
                        <Col>
                          <Button
                            className='float-end outline-blackButtonSmall defaultButton changeViewButton'
                            variant='outline-dark'
                            size='sm'
                            style={{
                              margin: 'auto',
                              padding: '8px 16px',
                              border: 'none',
                              backgroundColor: 'transparent',
                            }}
                            onClick={() => setChangeEventView(!changeEventView)}
                          >
                            <CalendarDate
                              style={{
                                transform: 'scale(1.1)',
                                marginRight: '8px',
                              }}
                            />{' '}
                            Kalendervy{' '}
                          </Button>
                        </Col>
                      ) : (
                        <Col>
                          <Button
                            className='float-end outline-blackButtonSmall defaultButton changeViewButton'
                            variant='outline-dark'
                            size='sm'
                            style={{
                              margin: 'auto',
                              padding: '8px 16px',
                              border: 'none',
                              backgroundColor: 'transparent',
                            }}
                            onClick={() => setChangeEventView(!changeEventView)}
                          >
                            <ListUl
                              style={{
                                transform: 'scale(1.1)',
                                marginRight: '8px',
                              }}
                            />{' '}
                            Listvy
                          </Button>
                        </Col>
                      )}
                    </Row>
                    {eventsByMonth !== null && !changeEventView && (
                      <>
                        {eventsByMonth.map((month, index) => {
                          if (
                            month.events.length > 0 &&
                            month.events.some(checkDate)
                          ) {
                            return (
                              <>
                                {month.events.filter(
                                  (event) =>
                                    filterByEventType(event.eventType) &&
                                    filterByEventLocation(event.location) &&
                                    checkDifferenceInDays(
                                      eventTimeFilter,
                                      event.startDate.substring(0, 10)
                                    ) &&
                                    filterEventsByReoccurence(event) &&
                                    filterMyEvents(event) &&
                                    event.active
                                ).length > 0 ? (
                                  <h3 className='eventMonth' key={index}>
                                    {month.year === yyyy
                                      ? month.name
                                      : month.year + '   ' + month.name}
                                  </h3>
                                ) : (
                                  month.events.filter(
                                    (event) =>
                                      filterByEventType(event.eventType) &&
                                      filterByEventLocation(event.location) &&
                                      checkDifferenceInDays(
                                        eventTimeFilter,
                                        event.startDate.substring(0, 10)
                                      ) &&
                                      filterEventsByReoccurence(event) &&
                                      filterMyEvents(event) &&
                                      checkPermission(event)
                                  ).length > 0 && (
                                    <h3 className='eventMonth' key={index}>
                                      {month.year === yyyy
                                        ? month.name
                                        : month.year + '   ' + month.name}
                                    </h3>
                                  )
                                )}
                                <div>
                                  {month.events
                                    .sort(function (a, b) {
                                      return (
                                        new Date(a.startDate) -
                                        new Date(b.startDate)
                                      );
                                    })
                                    .filter(
                                      (event) =>
                                        filterByEventType(event.eventType) &&
                                        filterByEventLocation(event.location) &&
                                        checkDifferenceInDays(
                                          eventTimeFilter,
                                          event.startDate.substring(0, 10)
                                        ) &&
                                        filterEventsByReoccurence(event) &&
                                        filterMyEvents(event)
                                    )
                                    .map((event, key) => {
                                      return (
                                        <EventItem
                                          key={key}
                                          event={event}
                                          currentDate={currentDate}
                                          loggedInUser={props.loggedInUser}
                                          showListModal={showListModal}
                                          setShowListModal={setShowListModal}
                                          hideListModal={hideListModal}
                                          getNumberOfSpots={getNumberOfSpots}
                                          checkPermission={checkPermission}
                                          generateJsonForExcel={
                                            generateJsonForExcel
                                          }
                                          generateMailingListString={
                                            generateMailingListString
                                          }
                                          getFoodPreference={getFoodPreference}
                                          displayEventSignUp={
                                            displayEventSignUp
                                          }
                                          currentWindowSize={currentWindowSize}
                                          setSelectedEvent={setSelectedEvent}
                                          displayEditEventModal={
                                            displayEditEventModal
                                          }
                                          handleModalType={handleModalType}
                                          handleShowModal={handleShowModal}
                                        />
                                      );
                                    })}
                                </div>
                              </>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </>
                    )}
                  </div>
                )}
              </>
            )}
            {(fetchEventsError === true || fetchAccountsError === true) && (
              <div style={{ textAlign: 'center' }}>
                <Row>
                  <Col md={12}>
                    <h1 style={{ margin: '30vh auto 0 auto' }}>
                      Ett fel har inträffat vid hämtning av data. Vänligen
                      kontakta admin om felet kvarstår.
                    </h1>
                  </Col>
                </Row>
              </div>
            )}
          </>

          {changeEventView && (
            <CalendarView
              events={eventsForCalendarView}
              changeCurrentTheme={props.changeCurrentTheme}
              accountsData={accountsData}
              fetchedEvents={fetchedEvents}
              // fetchEvents={fetchEvents}
              fetchAccounts={fetchAccounts}
              loggedInAsAdmin={props.loggedInAsAdmin}
              showClassicView={() => setChangeEventView(!changeEventView)}
              type={modalType}
              handleShowModal={handleShowModal}
              handleModalType={handleModalType}
              currentWindowSize={
                !!currentWindowSize &&
                !!currentWindowSize.height &&
                !!currentWindowSize.width &&
                currentWindowSize
              }
              loggedInUser={props.loggedInUser}
            />
          )}
        </Container>
      </div>
      <EventSignUpModal
        event={selectedEvent}
        setShow={handleShowSignUpModal}
        show={showSignUpModal}
        changeCurrentTheme={props.changeCurrentTheme}
        loggedInAsAdmin={props.loggedInAsAdmin}
        displayEditEventModal={displayEditEventModal}
        handleModalType={handleModalType}
        handleShowModal={handleShowModal}
        currentWindowSize={
          !!currentWindowSize &&
          !!currentWindowSize.height &&
          !!currentWindowSize.width &&
          currentWindowSize
        }
        loggedInUser={props.loggedInUser}
        fetchedEvents={fetchedEvents}
      />
      <EventModal
        type={modalType}
        handleModalType={handleModalType}
        accountsData={accountsData}
        fetchedEvents={fetchedEvents}
        setShow={handleShowModal}
        show={showModal}
        event={modalType === 'edit' ? selectedEvent : null}
        changeCurrentTheme={
          modalType === 'edit' ? props.changeCurrentTheme : null
        }
        currentWindowSize={
          !!currentWindowSize &&
          !!currentWindowSize.height &&
          !!currentWindowSize.width &&
          currentWindowSize
        }
        loggedInUser={props.loggedInUser}
      />
    </AnimatedPage>
  );
};
