import { Button } from 'react-bootstrap';

interface ICancelButtonSmallProps {
  onClick: () => void;
  showAdminSignUp?: boolean;
}

const CancelButtonSmall = ({
  onClick,
  showAdminSignUp,
}: ICancelButtonSmallProps) => {
  return (
    <Button
      variant='outline-dark'
      size='sm'
      className='eventItemShow'
      style={{
        whiteSpace: 'nowrap',
        border: '2px solid #333333',
        backgroundColor: 'transparent',
        color: '#333333',
      }}
      onClick={onClick}
    >
      {showAdminSignUp ? 'Avanmäl' : 'Avanmäl dig'}
    </Button>
  );
};
export default CancelButtonSmall;
