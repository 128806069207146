import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const MailTextEditor = (props) => {
  return (
    <>
      <h2>{props.header}</h2>
      <div className={props.className}>
        <CKEditor
          editor={ClassicEditor}
          config={{
            removePlugins: ['EasyImage', 'ImageUpload', 'MediaEmbed', 'Table'],
          }}
          data={props.text}
          onChange={(event, editor) => {
            const data = editor.getData();
            props.setText(data);
          }}
        />
      </div>
    </>
  );
};
