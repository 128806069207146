import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import {
  Clock,
  PeopleFill,
  PinMapFill,
  PersonCheckFill,
  EyeSlash,
  PencilFill,
  Download,
  Envelope,
  Share,
} from 'react-bootstrap-icons';
import { ExcelGenerator } from './ExcelGenerator';
import { Tooltip } from '@mui/material';
import SignUpButtonSmall from './buttons/SignUpButtonSmall';
import CancelButtonSmall from './buttons/CancelButtonSmall';
import { IEvent, IUser, IWindowProps } from 'types';
import dayjs from 'dayjs/esm';
import weekOfYear from 'dayjs/esm/plugin/weekOfYear';
import { useEventSignUpMutation } from 'hooks/useEventSignUpMutation';
import { isInviteOnly, isInvited } from 'utils.js';
dayjs.extend(weekOfYear);

interface IEventItemProps {
  event: IEvent;
  currentDate: Date;
  loggedInUser: IUser;
  showListModal: number | null;
  setShowListModal: (value: number | null) => void;
  hideListModal: () => void;
  getNumberOfSpots: (event: IEvent) => string;
  checkPermission: (event: IEvent) => boolean;
  generateJsonForExcel: (event: IEvent) => Record<string, string>[];
  generateMailingListString: (event: IEvent) => string;
  getFoodPreference: (email: string) => string;
  displayEventSignUp: (event: IEvent) => void;
  currentWindowSize: IWindowProps;
  setSelectedEvent: (event: IEvent) => void;
  displayEditEventModal: () => void;
  handleModalType: (value: string) => void;
  handleShowModal: (value: React.SetStateAction<boolean>) => void;
}

export const EventItem = ({
  event,
  currentDate,
  loggedInUser,
  showListModal,
  setShowListModal,
  hideListModal,
  getNumberOfSpots,
  checkPermission,
  generateJsonForExcel,
  generateMailingListString,
  getFoodPreference,
  displayEventSignUp,
  currentWindowSize,
  setSelectedEvent,
  displayEditEventModal,
  handleModalType,
  handleShowModal,
}: IEventItemProps) => {
  const navigate = useNavigate();
  const signUpMutation = useEventSignUpMutation({
    eventId: Number(event.id),
  });
  const [isSignedUp, setIsSignedUp] = useState(false);

  const { email: userEmail } = loggedInUser;

  const getWeekNumber = (date: Date) => {
    return 'V. ' + dayjs(date).week();
  };

  const getEventCategory = (eventType: number) => {
    if (!!eventType) {
      switch (eventType) {
        case 0:
          return 'Standard Event';
        case 1:
          return 'Frukost';
        case 2:
          return 'Lunch & Learn';
        case 3:
          return 'Teamaktivitet';
        case 4:
          return 'Afterwork';
        case 5:
          return 'Fest';
        case 6:
          return 'Familjedag';
        case 7:
          return 'Sport';
        case 8:
          return 'Kundevent';
        case 9:
          return 'HiQ Community';
        case 10:
          return 'Övrigt';
        default:
          return 'Standard Event';
      }
    } else {
      return 'Standard Event';
    }
  };

  const returnLocationName = (location: string) => {
    if (location.includes('malmö')) {
      return <span className='eventTag'>Malmö</span>;
    } else if (location.includes('lund')) {
      return <span className='eventTag'>Lund</span>;
    } else if (location.includes('helsingborg')) {
      return <span className='eventTag'>Helsingborg</span>;
    } else if (location.includes('karlskrona')) {
      return <span className='eventTag'>Karlskrona</span>;
    } else if (location.includes('digitalt') || location.includes('http')) {
      return <span className='eventTag'>Digitalt</span>;
    }
    return null;
  };

  const getMonthInText = (month: string) => {
    switch (month) {
      case '01':
        return 'jan';
      case '02':
        return 'feb';
      case '03':
        return 'mars';
      case '04':
        return 'apr';
      case '05':
        return 'maj';
      case '06':
        return 'jun';
      case '07':
        return 'jul';
      case '08':
        return 'aug';
      case '09':
        return 'sept';
      case '10':
        return 'okt';
      case '11':
        return 'nov';
      case '12':
        return 'dec';
      default:
        return '';
    }
  };

  const checkIsSignedUp = () => {
    if (event.acceptedAccounts.includes(userEmail.toLowerCase())) {
      return setIsSignedUp(true);
    } else {
      return setIsSignedUp(false);
    }
  };

  useEffect(() => {
    checkIsSignedUp();
  }, [event]);

  const handleSignUp = async () => {
    signUpMutation.mutate({
      action: 'accept',
      email: userEmail.toLocaleLowerCase(),
    });
  };

  const uninviteGuests = () => {
    const guests = event.acceptedAccounts.filter(
      (email) =>
        email.includes(userEmail.substring(0, userEmail.indexOf('@'))) &&
        email.includes('guest.se')
    );

    Promise.all(
      guests.map((guest) => {
        makeCancelRequest(guest);
      })
    );
  };

  const makeCancelRequest = async (email: string) => {
    signUpMutation.mutate({
      action: 'cancel',
      email: email.toLocaleLowerCase(),
    });
  };

  const handleCancelSignup = async () => {
    await makeCancelRequest(userEmail);
    uninviteGuests();
  };

  return (
    <div className='eventItem'>
      <div className='eventInformation'>
        <div className='eventDate'>
          <Row>
            <Col style={{ marginBottom: '-8px' }}>
              <h2
                style={
                  new Date(event.startDate) < currentDate
                    ? { color: '#969696' }
                    : {}
                }
                className='eventDateDay'
              >
                {event.startDate.substring(8, 10)}
              </h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p
                style={
                  new Date(event.startDate) < currentDate
                    ? { color: '#969696' }
                    : {}
                }
                className='eventDateMonth'
              >
                {getMonthInText(event.startDate.substring(5, 7))}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p
                style={
                  new Date(event.startDate) < currentDate
                    ? { color: '#969696' }
                    : {}
                }
                className='eventDateMonth'
              >
                {getWeekNumber(new Date(event.startDate))}
              </p>
            </Col>
          </Row>
        </div>
        <div className='eventDetails'>
          <Row style={{ width: '100%' }}>
            <Col xs={12} md={6} lg={6}>
              <h2
                style={
                  new Date(event.startDate) < currentDate
                    ? { color: '#969696' }
                    : {}
                }
                className='eventTitle'
                onClick={() => navigate(`signup/${event.id}`)}
              >
                {event.title}
              </h2>
            </Col>

            <Col className='mb-3 eventTagArea' xs={3} md={3} lg={3}>
              {!!loggedInUser && isSignedUp && (
                <span
                  style={{
                    background: 'rgba(255,102,192, 0.5)',
                    color: '#333333',
                  }}
                  className='eventTag'
                >
                  ✅ Anmäld
                </span>
              )}
            </Col>

            <Col className='mb-3 eventTagArea' xs={3} md={3} lg={3}>
              <span className='eventTag'>
                {getEventCategory(event.eventType)}
              </span>
            </Col>

            <Col className='mb-3 eventTagArea' xs={3} md={3} lg={6}>
              {returnLocationName(event.location.toLowerCase())}
            </Col>
          </Row>
          <Row className='eventInformationXSAdmin' style={{ minWidth: '40vw' }}>
            <Col xs={12} md={6} lg={3}>
              <Clock
                style={
                  new Date(event.startDate) < currentDate
                    ? { color: '#969696' }
                    : {}
                }
                className='eventIcons'
              ></Clock>
              <p
                style={
                  new Date(event.startDate) < currentDate
                    ? { color: '#969696' }
                    : {}
                }
                className='d-inline-block eventText'
              >
                {event.startDate.substring(11)} - {event.endTime.substring(11)}
              </p>
            </Col>

            <Col xs={12} md={6} lg={3} className='eventDetailsLocation'>
              <PinMapFill
                style={
                  new Date(event.startDate) < currentDate
                    ? { color: '#969696' }
                    : {}
                }
                className='eventIcons'
              ></PinMapFill>
              <p
                style={
                  new Date(event.startDate) < currentDate
                    ? { color: '#969696' }
                    : {}
                }
                className='d-inline-block eventText'
              >
                {event.location}
              </p>
            </Col>

            <Col xs={12} md={6} lg={3}>
              {!event.openInvitation && event.invitedAccounts.length === 0 ? (
                <>
                  <PersonCheckFill
                    style={
                      new Date(event.startDate) < currentDate
                        ? { color: '#969696' }
                        : {}
                    }
                    className='eventIcons'
                  ></PersonCheckFill>
                  <span
                    style={
                      new Date(event.startDate) < currentDate
                        ? { color: '#969696' }
                        : {}
                    }
                    className='eventText'
                  >
                    Ingen anmälan krävs
                  </span>
                </>
              ) : (
                <>
                  <PersonCheckFill
                    style={
                      new Date(event.startDate) < currentDate
                        ? { color: '#969696', cursor: 'pointer' }
                        : { cursor: 'pointer' }
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      if (showListModal !== event.id) {
                        setShowListModal(event.id);
                      } else {
                        setShowListModal(null);
                      }
                    }}
                    className='eventIcons'
                  ></PersonCheckFill>
                  <Button
                    size='sm'
                    variant='outline-secondary'
                    className='d-inline-block eventText'
                    onClick={(e) => {
                      e.stopPropagation();
                      if (showListModal !== event.id) {
                        setShowListModal(event.id);
                      } else {
                        setShowListModal(null);
                      }
                    }}
                    style={
                      new Date(event.startDate) < currentDate
                        ? { color: '#969696', border: 'none' }
                        : { border: 'none' }
                    }
                  >
                    {getNumberOfSpots(event)}
                  </Button>
                </>
              )}

              <Modal
                className='modal center'
                show={showListModal === event.id}
                onHide={hideListModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {event.title} -{' '}
                    {event.acceptedAccounts.length === 1
                      ? event.acceptedAccounts.length + ' anmäld'
                      : event.acceptedAccounts.length + ' anmälda'}
                  </Modal.Title>
                  {checkPermission(event) && (
                    <>
                      <ExcelGenerator
                        style={{ marginLeft: '32px' }}
                        excelData={generateJsonForExcel(event)}
                        fileName={
                          event.title.replace(/\s/g, '') + '_deltagarlista'
                        }
                        buttonText={<Download />}
                      />
                      <Tooltip title='Skicka mail till anmälda'>
                        <a href={generateMailingListString(event)}>
                          <Button variant='dark' style={{ marginLeft: '16px' }}>
                            {<Envelope />}
                          </Button>
                        </a>
                      </Tooltip>
                    </>
                  )}
                </Modal.Header>
                <Modal.Body>
                  <Row className='m-3'>
                    <Col
                      className='me-3'
                      style={{ borderRight: '2px solid #eee' }}
                    >
                      <h4>Deltagarlista</h4>
                      {event.acceptedAccounts.map((email, index) => {
                        if (index < event.maxParticipants) {
                          return (
                            <p key={index}>
                              <strong>{email}</strong>{' '}
                              {checkPermission(event) &&
                                event.showFoodPref &&
                                ' - ' +
                                  'Matpreferens: ' +
                                  getFoodPreference(email)}
                            </p>
                          );
                        }
                      })}
                    </Col>
                    <Col>
                      <h4>Reservlista</h4>
                      {event.acceptedAccounts.map((email, index) => {
                        if (index >= event.maxParticipants) {
                          return (
                            <p key={index}>
                              <strong>{email}</strong>{' '}
                              {checkPermission(event) &&
                                event.showFoodPref &&
                                ' - ' +
                                  'Matpreferens: ' +
                                  getFoodPreference(email)}
                            </p>
                          );
                        }
                      })}
                    </Col>
                  </Row>
                </Modal.Body>
              </Modal>
              {event.acceptedAccounts.length > event.maxParticipants && (
                <Col>
                  <PersonCheckFill
                    style={
                      new Date(event.startDate) < currentDate
                        ? { color: '#969696', cursor: 'pointer' }
                        : { color: '#969696', cursor: 'pointer' }
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      if (showListModal !== event.id) {
                        setShowListModal(event.id);
                      } else {
                        setShowListModal(null);
                      }
                    }}
                    className='eventIcons'
                  ></PersonCheckFill>
                  <Button
                    size='sm'
                    variant='outline-secondary'
                    className='d-inline-block eventText'
                    onClick={(e) => {
                      e.stopPropagation();
                      if (showListModal !== event.id) {
                        setShowListModal(event.id);
                      } else {
                        setShowListModal(null);
                      }
                    }}
                    style={
                      new Date(event.startDate) < currentDate
                        ? { color: '#969696', border: 'none' }
                        : { color: '#969696', border: 'none' }
                    }
                  >
                    {event.acceptedAccounts.length - event.maxParticipants !== 1
                      ? event.acceptedAccounts.length -
                        event.maxParticipants +
                        ' reserver'
                      : event.acceptedAccounts.length -
                        event.maxParticipants +
                        ' reserv'}
                  </Button>
                </Col>
              )}
            </Col>

            <Col xs={12} md={6} lg={3}>
              <PeopleFill
                style={
                  new Date(event.startDate) < currentDate
                    ? { color: '#969696' }
                    : {}
                }
                className='eventIcons'
              ></PeopleFill>
              <p
                style={
                  new Date(event.startDate) < currentDate
                    ? { color: '#969696' }
                    : {}
                }
                className='d-inline-block eventText'
              >
                Antal platser: {event.maxParticipants}
              </p>
            </Col>

            {checkPermission(event) && !event.active && (
              <Col>
                <EyeSlash
                  style={
                    new Date(event.startDate) < currentDate
                      ? { color: '#969696' }
                      : {}
                  }
                  className='eventIcons'
                ></EyeSlash>
                <p
                  style={
                    new Date(event.startDate) < currentDate
                      ? { color: '#969696' }
                      : {}
                  }
                  className='d-inline-block eventText'
                >
                  Status: Privat
                </p>
              </Col>
            )}
          </Row>
        </div>
      </div>
      <Row className='eventItemButtonRow'>
        {checkPermission(event) === true ? (
          <>
            <Row className='justify-content-start align-items-center'>
              <Col className='m-1' sm={6} md={4}>
                <Button
                  size='sm'
                  onClick={() => navigate(`signup/${event.id}`)}
                  className='eventItemShow'
                  variant='dark'
                >
                  Läs mer
                </Button>
              </Col>

              {/* Sign up button container */}
              {isSignedUp && (
                <Col className='m-1' sm={12} md={6}>
                  <CancelButtonSmall onClick={handleCancelSignup} />
                </Col>
              )}
              {dayjs(currentDate).isBefore(event.lastDate) && (
                <Col className='m-1' sm={12} md={6}>
                  {isInviteOnly(event) &&
                    isInvited(event, userEmail) &&
                    !isSignedUp && (
                      <SignUpButtonSmall
                        signUpAsReserve={
                          event.acceptedAccounts.length >= event.maxParticipants
                        }
                        onClick={handleSignUp}
                      />
                    )}
                  {event.openInvitation && !isSignedUp && (
                    <SignUpButtonSmall
                      signUpAsReserve={
                        event.acceptedAccounts.length >= event.maxParticipants
                      }
                      onClick={handleSignUp}
                    />
                  )}
                </Col>
              )}
              {/* ************************ */}
            </Row>
            <Row className='justify-content-start align-items-center'>
              <Col className='m-1' sm={6} md={4}>
                <Button
                  size='sm'
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://event.hiqsyd.se/signup/${event.id}`
                    );
                  }}
                  className='eventItemShow goToEventButton'
                  variant='dark'
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <Share />
                </Button>
              </Col>

              <Col className='m-1' sm={6} md={3}>
                <Button
                  onClick={() => {
                    setSelectedEvent(event);
                    displayEditEventModal();
                    handleModalType('edit');
                    handleShowModal(true);
                  }}
                  style={{ whiteSpace: 'nowrap' }}
                  className='eventItemEdit'
                  variant='info'
                  size='sm'
                >
                  <PencilFill style={{ marginRight: '8px' }} />
                  Redigera
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className='justify-content-start align-items-center'>
              <Col className='m-1 showEventSmallScreenCol' sm={6} md={4}>
                <Button
                  size='sm'
                  onClick={() => navigate(`signup/${event.id}`)}
                  className={
                    currentWindowSize.width >= 1200
                      ? 'eventItemShow me-3 float-end'
                      : currentWindowSize.width < 576
                      ? 'eventItemShow'
                      : 'eventItemShow me-3'
                  }
                  variant='dark'
                >
                  Läs mer
                </Button>
              </Col>
              {/* Sign up button container */}
              {isSignedUp && (
                <Col className='m-1' sm={12} md={6}>
                  <CancelButtonSmall onClick={handleCancelSignup} />
                </Col>
              )}
              {dayjs(currentDate).isBefore(event.lastDate) && (
                <Col className='m-1' sm={12} md={6}>
                  {isInviteOnly(event) &&
                    isInvited(event, userEmail) &&
                    !isSignedUp && (
                      <SignUpButtonSmall
                        signUpAsReserve={
                          event.acceptedAccounts.length >= event.maxParticipants
                        }
                        onClick={handleSignUp}
                      />
                    )}
                  {event.openInvitation && !isSignedUp && (
                    <SignUpButtonSmall
                      signUpAsReserve={
                        event.acceptedAccounts.length >= event.maxParticipants
                      }
                      onClick={handleSignUp}
                    />
                  )}
                </Col>
              )}
              {/* ************************ */}
            </Row>
          </>
        )}
      </Row>
    </div>
  );
};
