import { Button } from 'react-bootstrap';

interface ISignUpButtonSmallProps {
  onClick: () => void;
  signUpAsReserve?: boolean;
  showAdminSignUp?: boolean;
}

const SignUpButtonSmall = ({
  onClick,
  signUpAsReserve,
  showAdminSignUp,
}: ISignUpButtonSmallProps) => {
  return (
    <Button
      variant='dark'
      size='sm'
      className='eventItemShow'
      style={{ whiteSpace: 'nowrap' }}
      onClick={onClick}
    >
      {showAdminSignUp
        ? signUpAsReserve
          ? 'Anmäl som reserv'
          : 'Anmäl'
        : signUpAsReserve
        ? 'Anmäl dig som reserv'
        : 'Anmäl dig'}
    </Button>
  );
};

export default SignUpButtonSmall;
