import { useState, useEffect } from 'react';
import './EventSignUp.css';
import {
  Button,
  Form,
  Collapse,
  Row,
  Col,
  Container,
  Spinner,
  Modal,
  Image,
} from 'react-bootstrap';
import {
  Clock,
  PeopleFill,
  PinMapFill,
  PersonCheckFill,
  XCircle,
  Download,
  Envelope,
} from 'react-bootstrap-icons';
import { useParams, useNavigate } from 'react-router-dom';
import AnimatedPage from '../components/AnimatedPage';
import Logo from '../images/hiqLogoTransparentBlack.png';
import { Tooltip } from '@mui/material';
import { ExcelGenerator } from '../components/ExcelGenerator';
import { ModalInputSwitch } from '../components/ModalInputSwitch';
import { useEventQuery } from 'hooks/useEventQuery';
import { useAccountsQuery } from 'hooks/useAccountsQuery';
import { useEventsQuery } from 'hooks/useEventsQuery';
import { useWindowSize } from 'hooks/useWindowSize';
import {
  checkDate,
  fixAcceptedInvites,
  fixAcceptedInvitesForAll,
  isInviteOnly,
} from 'utils.js';
import { useEventSignUpMutation } from 'hooks/useEventSignUpMutation';
import CancelButton from 'components/buttons/CancelButton';
import SignUpButton from 'components/buttons/SignUpButton';

//Check the EventSignUpModal for function explanations.
export const EventSignUp = (props) => {
  const currentWindowSize = useWindowSize();
  const navigate = useNavigate();
  const { eventId } = useParams();
  const {
    status: eventStatus,
    data: eventData,
    error: eventError,
  } = useEventQuery(Number(eventId));
  const {
    status: accountStatus,
    data: accountsData,
    error: accountError,
  } = useAccountsQuery();
  const {
    status: eventsStatus,
    data: eventsData,
    error: eventsError,
  } = useEventsQuery();
  const [fetchedAccounts, setFetchedAccounts] = useState(null);
  const [fetchedEvent, setFetchedEvent] = useState(null);
  const [fetchedEvents, setFetchedEvents] = useState(null);
  const [showParticipants, setShowParticipants] = useState(false);
  const [showBringGuest, setShowBringGuest] = useState(false);
  const [addGuest, setAddGuest] = useState({
    bringGuest: false,
    guests: [],
  });
  const [currentGuest, setCurrentGuest] = useState({
    currentGuestFirstName: '',
    currentGuestLastName: '',
    currentGuestFoodPref: '',
  });
  const [currentParticipants, setCurrentParticipants] = useState(null);
  const [currentReserves, setCurrentReserves] = useState(null);
  const [showGuestErrorMessage, setShowGuestErrorMessage] = useState(false);
  const [showGuestSuccessMessage, setShowGuestSuccessMessage] = useState(false);

  const [userInput, setUserInput] = useState({
    email: !!props.loggedInUser ? props.loggedInUser.email.toLowerCase() : '',
  });

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showCancelMessage, setShowCancelMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showTooLateMessage, setShowTooLateMessage] = useState(false);
  const [showAlreadySignedUp, setShowAlreadySignedUp] = useState(false);
  const [showErrorCancelMessage, setShowErrorCancelMessage] = useState(false);
  const [showTooManyGuestsError, setShowTooManyGuestsError] = useState(false);
  const [showListModal, setShowListModal] = useState(false);

  const [todaysDate, setTodaysDate] = useState(null);
  const [fetchAccountsError, setFetchAccountsError] = useState(false);
  const [fetchEventsError, setFetchEventsError] = useState(false);
  const [showAdminSignUp, setShowAdminSignUp] = useState(false);
  const signUpMutation = useEventSignUpMutation({
    eventId: Number(eventId),
  });

  useEffect(() => {
    if (eventData) {
      setFetchedEvent(fixAcceptedInvites(eventData));
    }
  }, [eventData]);

  useEffect(() => {
    if (eventsData) {
      setFetchedEvents(fixAcceptedInvitesForAll(eventsData));
    }
  }, [eventsData]);

  useEffect(() => {
    if (!showAdminSignUp && !!props.loggedInUser) {
      setUserInput({
        email: props.loggedInUser.email.toLowerCase(),
      });
    }
  }, [showAdminSignUp]);

  useEffect(() => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    today = new Date(yyyy + '-' + mm + '-' + dd);
    setTodaysDate(today);
  }, []);

  useEffect(() => {
    if (fetchedEvent !== null) {
      checkDate(fetchedEvent, setShowTooLateMessage);
    }
  }, [fetchedEvent]);

  useEffect(() => {
    if (fetchedEvent !== null) {
      props.changeCurrentTheme(selectTheme());
    }
  }, [fetchedEvent]);

  useEffect(() => {
    if (!!props.loggedInAsAdmin && props.loggedInUser.email !== '') {
      setUserInput({
        email: props.loggedInUser.email.toLowerCase(),
      });
    }
  }, [fetchedEvent, props.loggedInUser]);

  const selectTheme = () => {
    switch (fetchedEvent.themeType) {
      case 1:
        return 'mintTheme';
      case 2:
        return 'peachTheme';
      case 3:
        return 'pinkLightTheme';
      case 4:
        return 'purpleTheme';
      default:
        return 'App-container-no-background';
    }
  };

  const [currentTheme, setCurrentTheme] = useState('standardSignUpContainer');

  useEffect(() => {
    if (fetchedEvent !== null) {
      switch (fetchedEvent.themeType) {
        case 0:
          setCurrentTheme('standardSignUpContainer');
          break;
        case 1:
          setCurrentTheme('mintSignUpContainer');
          break;
        case 2:
          setCurrentTheme('peachSignUpContainer');
          break;
        case 3:
          setCurrentTheme('pinkLightSignUpContainer');
          break;
        case 4:
          setCurrentTheme('purpleSignUpContainer');
          break;
        default:
          setCurrentTheme('standardSignUpContainer');
          break;
      }
    }
  }, [fetchedEvent]);

  useEffect(() => {
    if (
      accountsData &&
      fetchedEvent &&
      Array.isArray(fetchedEvent.acceptedAccounts)
    ) {
      let currentParticipantsTemp = [];
      let currentReservesTemp = [];
      fetchedEvent.acceptedAccounts
        .slice(0, fetchedEvent.maxParticipants)
        .forEach((user) => {
          currentParticipantsTemp.push(
            accountsData.find((account) => account.email === user)
          );
        });
      setCurrentParticipants(currentParticipantsTemp);

      fetchedEvent.acceptedAccounts
        .slice(fetchedEvent.maxParticipants)
        .forEach((user) => {
          currentReservesTemp.push(
            accountsData.find((account) => account.email === user)
          );
        });
      setCurrentReserves(currentReservesTemp);
    }
  }, [accountsData, fetchedEvent]);

  if (eventStatus === 'error') {
    console.error(eventError);
    setFetchEventsError(true);
  }
  if (accountStatus === 'error') {
    console.error(accountError);
    setFetchAccountsError(true);
  }
  if (eventsStatus === 'error') {
    console.error(eventsError);
    setFetchEventsError(true);
  }

  if (
    accountStatus === 'loading' ||
    eventStatus === 'loading' ||
    eventsStatus === 'loading'
  ) {
    return <p role='alert'>Hämtar data...</p>;
  }

  if (accountStatus === 'success' && eventData === 'success') {
    props.displayBackgroundGif(false);
  }

  if (!accountsData || !eventData) {
    return null;
  }

  const handleInputChange = (e) => {
    e.preventDefault();
    setUserInput((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  /********************* VALIDATIONS & CHECKS *********************/

  const isInvited = () => {
    const email = userInput.email.toLowerCase();
    const invitedUsers = fetchedEvent.invitedAccounts;

    return invitedUsers.some((e) => e.email.toLowerCase() === email);
  };

  const isBringAGuestEvent = () => {
    return addGuest.bringGuest && !!addGuest.guests.length;
  };

  const isAlreadySignedUp = () => {
    return fetchedEvent.acceptedAccounts.includes(userInput.email);
  };

  const isFullyBooked = () => {
    if (fetchedEvent.acceptedAccounts.length >= fetchedEvent.maxParticipants) {
      return true;
    } else {
      return false;
    }
  };

  const checkEmailValidAndExists = () => {
    return (
      userInput.email !== '' &&
      validateEmail(userInput.email) &&
      accountsData.filter(
        (user) => user.email.toLowerCase() === userInput.email.toLowerCase()
      ).length > 0
    );
  };

  const emailValidExistsAndIsInvited = () => {
    return checkEmailValidAndExists() && isInvited();
  };
  /****************************************************************/

  /************************** API CALLS **************************/
  const makeSignUpRequest = async () => {
    await signUpMutation.mutate({
      action: 'accept',
      email: userInput.email.toLocaleLowerCase(),
    });
    return displaySuccessMessage();
  };

  const makeCancelRequest = async (email) => {
    await signUpMutation.mutate({ action: 'cancel', email });
    return displayCancelMessage();
  };

  const makeCreateAccountRequest = async (mailOptions) => {
    return await fetch(
      'https://eventhandler-backend.azurewebsites.net/api/Accounts',
      {
        method: 'POST',
        headers: {
          Accept: 'text/plain',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(mailOptions),
      }
    );
  };

  const makeGetEventRequest = async () => {
    return await fetch(
      `https://eventhandler-backend.azurewebsites.net/api/Events/${fetchedEvent.id}`,
      {
        method: 'GET',
        headers: {
          accept: 'text/plain',
        },
      }
    );
  };

  const makeEventUpdateRequest = async (eventInformation) => {
    return await fetch(
      'https://eventhandler-backend.azurewebsites.net/api/Events',
      {
        method: 'PUT',
        headers: {
          Accept: 'text/plain',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(eventInformation),
      }
    );
  };

  const makeSignUpWithGuestRequest = async (guest) => {
    return await fetch(
      `https://eventhandler-backend.azurewebsites.net/api/Accounts/accept?email=${userInput.email
        .substring(0, userInput.email.indexOf('@'))
        .toLowerCase()}-${
        guest.guestFirstName.toLowerCase() +
        '.' +
        guest.guestLastName.toLowerCase()
      }@guest.se&eventId=${fetchedEvent.id}`,
      {
        method: 'POST',
        headers: {
          accept: 'text/plain',
        },
      }
    );
  };
  /****************************************************************/

  /*********************** DISPLAY MESSAGES ***********************/
  const displaySuccessMessage = () => {
    setShowSuccessMessage(true);
    const timeOut = setTimeout(() => {
      setShowSuccessMessage(false);
    }, 3000);
    return () => {
      clearTimeout(timeOut);
    };
  };

  const displayErrorMessage = () => {
    setShowErrorMessage(true);
    const timeOut = setTimeout(() => {
      setShowErrorMessage(false);
    }, 3000);
    return () => clearTimeout(timeOut);
  };

  const displayAlreadySignedUpMessage = () => {
    setShowAlreadySignedUp(true);
    const timeOut = setTimeout(() => {
      setShowAlreadySignedUp(false);
    }, 3000);
    return () => {
      clearTimeout(timeOut);
    };
  };

  const displayCancelMessage = () => {
    setShowCancelMessage(true);
    const timeOut = setTimeout(() => {
      setShowCancelMessage(false);
    }, 3000);
    return () => {
      clearTimeout(timeOut);
    };
  };

  const displayCancelErrorMessage = () => {
    setShowErrorCancelMessage(true);
    const timeOut = setTimeout(() => {
      setShowErrorCancelMessage(false);
    }, 3000);
    return () => clearTimeout(timeOut);
  };
  /****************************************************************/

  const redirectToEventListPage = () => {
    const timeout = setTimeout(() => {
      navigate('/');
    }, 1500);
    return () => {
      clearTimeout(timeout);
    };
  };

  const handleSignUp = () => {
    return checkEmailValidAndExists()
      ? makeSignUpRequest()
      : displayErrorMessage();
  };

  const handleInvitedSignUp = () => {
    return emailValidExistsAndIsInvited()
      ? makeSignUpRequest()
          .then(displaySuccessMessage)
          .then(redirectToEventListPage)
      : displayErrorMessage();
  };

  const submitUserInput = async () => {
    if (fetchedEvent.openInvitation) {
      isAlreadySignedUp() ? displayAlreadySignedUpMessage() : handleSignUp();
    } else {
      if (fetchedEvent.invitedAccounts.length !== 0) {
        isAlreadySignedUp()
          ? displayAlreadySignedUpMessage()
          : handleInvitedSignUp();
      } else {
        if (userInput.email !== '') {
          // No clue what this is supposed to do...
        } else {
          displayErrorMessage();
        }
      }
    }
  };

  const handleSubmit = () => {
    isBringAGuestEvent() ? submitUserInputWithGuest() : submitUserInput();
  };

  const cancelSignUp = async () => {
    if (checkEmailValidAndExists()) {
      makeCancelRequest(userInput.email.toLocaleLowerCase()).then(async () => {
        let listOfGuests = fetchedEvent.acceptedAccounts.filter(
          (email) =>
            email.includes(
              userInput.email.substring(0, userInput.email.indexOf('@'))
            ) && email.includes('guest.se')
        );
        Promise.all(
          listOfGuests.map(async (guest) =>
            makeCancelRequest(guest.toLocaleLowerCase())
          )
        );
      });
    } else {
      displayCancelErrorMessage();
    }
  };

  const transformDate = (dateAsString) => {
    let result = '';
    let dateToTransform = new Date(dateAsString);
    switch (dateToTransform.getDay()) {
      case 0:
        result = result.concat('', 'SÖN');
        break;
      case 1:
        result = result.concat('', 'MÅN');
        break;
      case 2:
        result = result.concat('', 'TIS');
        break;
      case 3:
        result = result.concat('', 'ONS');
        break;
      case 4:
        result = result.concat('', 'TORS');
        break;
      case 5:
        result = result.concat('', 'FRE');
        break;
      case 6:
        result = result.concat('', 'LÖR');
        break;
      default:
        result = result.concat('', 'ERROR');
        break;
    }
    switch (dateToTransform.getMonth()) {
      case 0:
        result = result + ' JANUARI ';
        break;
      case 1:
        result = result + ' FEBRUARI ';
        break;
      case 2:
        result = result + ' MARS ';
        break;
      case 3:
        result = result + ' APRIL ';
        break;
      case 4:
        result = result + ' MAJ ';
        break;
      case 5:
        result = result + ' JUNI ';
        break;
      case 6:
        result = result + ' JULI ';
        break;
      case 7:
        result = result + ' AUGUSTI ';
        break;
      case 8:
        result = result + ' SEPTEMBER ';
        break;
      case 9:
        result = result + ' OKTOBER ';
        break;
      case 10:
        result = result + ' NOVEMBER ';
        break;
      case 11:
        result = result + ' DECEMBER ';
        break;
      default:
        result = result + ' ERROR ';
        break;
    }
    result = result + dateToTransform.getDate() + ', ';
    result = result + dateToTransform.getFullYear();
    return result;
  };

  const submitUserInputWithGuest = async () => {
    submitUserInput();

    addGuest.guests.map(async (guest) => {
      if (!!guest.guestFirstName && !!guest.guestLastName) {
        const mailOptions = {
          email: `${userInput.email.substring(
            0,
            userInput.email.indexOf('@')
          )}-${guest.guestFirstName}.${guest.guestLastName}@guest.se`,
          foodPreference: guest.guestFoodPref,
          isHiqAccount: false,
          teamName: 'No team lead',
          employmentCity: 'No employment city',
          teamId: 'No team id',
        };

        let newList = [];
        const newAccountResult = await makeCreateAccountRequest(mailOptions);
        const newAccount = await newAccountResult.json();

        let invitesHolder = [];
        const eventResult = await makeGetEventRequest();
        const event = await eventResult.json();

        invitesHolder = event.invitedAccounts;
        newList.push(newAccount);
        invitesHolder.push(newAccount);

        const onlyEmail = invitesHolder.reduce(
          (acc, account) => [...acc, account.email],
          []
        );

        const eventInformation = {
          title: fetchedEvent.title,
          startDate: fetchedEvent.startDate,
          endTime: fetchedEvent.endTime,
          lastDate: fetchedEvent.lastDate,
          location: fetchedEvent.location,
          description: fetchedEvent.description,
          eventType: fetchedEvent.eventType,
          maxParticipants: fetchedEvent.maxParticipants,
          invitedAccounts: onlyEmail,
          id: fetchedEvent.id,
          imageURL: fetchedEvent.imageURL,
          themeType: fetchedEvent.themeType,
          active: fetchedEvent.active,
          openInvitation: fetchedEvent.openInvitation,
          canBringGuest: fetchedEvent.canBringGuest,
          numberOfGuests: fetchedEvent.numberOfGuests,
          hasSentReminder: fetchedEvent.hasSentReminder,
          recurringEventId: fetchedEvent.recurringEventId,
          createdBy: fetchedEvent.createdBy,
          showFoodPref: fetchedEvent.showFoodPref,
          uploadedFiles: ['empty'],
        };

        await makeEventUpdateRequest(eventInformation);

        await makeSignUpWithGuestRequest(guest)
          .then(displaySuccessMessage)
          .then(redirectToEventListPage);
      }
    });
  };

  const handleGuestInputChange = (e) => {
    e.preventDefault();
    setCurrentGuest((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const removeUserFromInviteList = (index) => {
    let guestListCopy = [...addGuest.guests];
    guestListCopy.splice(index, 1);
    setAddGuest({
      bringGuest: true,
      guests: guestListCopy,
    });
  };

  const getFoodPreference = (email) => {
    if (accountsData !== null) {
      let user = accountsData.find(
        (user) => user.email.toLowerCase() === email.toLowerCase()
      );
      if (!!user) {
        if (user.foodPreference === '') {
          return 'Inga preferenser';
        } else {
          return user.foodPreference;
        }
      } else {
        return 'Ingen data tillgänglig';
      }
    } else {
      return 'Ingen data tillgänglig';
    }
  };

  const hideListModal = () => {
    setShowListModal(false);
  };

  const validateEmail = (input) => {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    return input.match(validRegex);
  };

  const fixEventDescription = (description) => {
    const descriptionArray = description.split('\n');
    const renderList = descriptionArray.map((item) => {
      let eachWordArray = item.split(' ');
      const result = eachWordArray.map((word, index) => {
        if (isStringLink(word)) {
          if (word.substring(0, 8) !== 'https://') {
            return (
              <a
                key={word + index}
                href={'https://' + word}
                target='_blank'
                rel='noopener noreferrer'
              >
                {word}
              </a>
            );
          } else {
            return (
              <a
                key={word + index}
                href={word}
                target='_blank'
                rel='noopener noreferrer'
              >
                {word}
              </a>
            );
          }
        } else {
          return <>{' ' + word + ' '}</>;
        }
      });
      return (
        <>
          {result}
          <br />
        </>
      );
    });

    return renderList;
  };

  const isStringLink = (string) => {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(string);
  };

  const checkPermission = (event) => {
    if (props.loggedInAsAdmin) {
      return true;
    } else {
      if (
        props.loggedInUser.roles.includes('Role.creator') &&
        event.createdBy.toLowerCase() === props.loggedInUser.email.toLowerCase()
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const checkIfSignedUp = (event, email) => {
    if (event.acceptedAccounts.includes(email.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  const getNumberOfSpots = (event) => {
    const numberOfSpots = event.maxParticipants - event.acceptedAccounts.length;
    if (numberOfSpots <= 0) {
      return 'Fullbokat!';
    } else if (numberOfSpots === 1) {
      return '1 plats kvar';
    } else {
      return numberOfSpots + ' platser kvar';
    }
  };

  const checkForTimeConflict = (currentEvent) => {
    const user = accountsData.filter(
      (account) =>
        account.email.toLowerCase() === props.loggedInUser.email.toLowerCase()
    );
    let result = false;

    //Change acceptedEvents into an Array instead of a string
    if (!!user && !!user[0]) {
      const acceptArray = user[0].acceptedEvents.split(';');
      if (!!acceptArray && acceptArray.length > 0) {
        const filteredAcceptArray = acceptArray.filter((e) => e !== '');
        const fixedUser = {
          id: user[0].id,
          email: user[0].email,
          foodPreference: user[0].foodPreference,
          isHiqAccount: user[0].isHiqAccount,
          teamName: user[0].teamName,
          employmentCity: user[0].employmentCity,
          teamId: user[0].teamId,
          invitedEvents: user[0].invitedEvents,
          acceptedEvents: filteredAcceptArray,
        };

        if (fixedUser.acceptedEvents.length === 0) {
          // console.log('User has not signed up to any events')
          return false;
        } else {
          // console.log('User has signed up to events')
          fixedUser.acceptedEvents.forEach((event) => {
            const eventObject = fetchedEvents.find(
              (fetchedEvent) => fetchedEvent.id === event
            );
            //check for time conflict betweem eventObject and the current event.
            if (!!eventObject) {
              if (
                new Date(eventObject.startDate) <=
                  new Date(currentEvent.endTime) &&
                new Date(eventObject.endTime) >=
                  new Date(
                    currentEvent.startDate
                  ) /*|| (new Date(currentEvent.startDate) <= new Date(eventObject.endTime) && new Date(currentEvent.endTime) >= new Date(eventObject.startDate))*/
              ) {
                result = true;
              }
            } else {
              result = false;
            }
          });
          return result;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const generateJsonForExcel = (event) => {
    if (!!event && !!accountsData) {
      let accountsToInclude = [];
      let jsonData = [];
      let acceptedAccountsToLowerCase = [];

      event.acceptedAccounts.forEach((email) => {
        acceptedAccountsToLowerCase.push(email.toLowerCase());
      });

      accountsData.forEach((account) => {
        if (acceptedAccountsToLowerCase.includes(account.email.toLowerCase())) {
          accountsToInclude.push(account);
        }
      });

      accountsToInclude.forEach((user) => {
        jsonData.push({
          Mail: user.email,
          Matpreferens: getFoodPreference(user.email),
          Team: user.teamName,
          TeamId: user.teamId,
          Stad: user.employmentCity,
        });
      });
      return jsonData;
    }
  };

  const generateMailingListString = (event) => {
    let mailString = 'mailto:';
    for (let i = 0; i < event.acceptedAccounts.length; i++) {
      if (i === 0) {
        mailString = mailString.concat('', event.acceptedAccounts[i]);
      } else {
        mailString = mailString.concat(',', event.acceptedAccounts[i]);
      }
    }
    return mailString;
  };

  return (
    <AnimatedPage>
      <>
        {fetchedEvent !== null ? (
          <div
            className={currentTheme}
            onClick={() => setShowParticipants(false)}
          >
            <Row
              style={
                currentWindowSize.width <= 1200 ? { textAlign: 'center' } : {}
              }
            >
              <Col md={12} xl={6}>
                {/* <div className='eventSignUp-body'> */}
                <div className='eventInformation-area'>
                  <Container>
                    <Row>
                      <Col>
                        <p className='eventSignUpDate'>
                          {transformDate(
                            fetchedEvent.startDate.substring(0, 10)
                          ) +
                            ' ' +
                            fetchedEvent.startDate.substring(11)}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h1 className='eventSignUpTitle'>
                          {fetchedEvent.title}
                        </h1>
                      </Col>
                    </Row>
                    <div className='eventSignupDetails'>
                      <Row>
                        <Col>
                          <Clock className='eventDetailIcons'></Clock>
                          <p className='d-inline-block eventDetailText'>
                            {fetchedEvent.startDate.substring(11)} -{' '}
                            {fetchedEvent.endTime.substring(11)}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <PinMapFill className='eventDetailIcons'></PinMapFill>
                          <p className='d-inline-block eventDetailText'>
                            {fetchedEvent.location}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {!fetchedEvent.openInvitation &&
                          fetchedEvent.invitedAccounts.length === 0 ? (
                            <>
                              <PersonCheckFill className='eventDetailIcons'></PersonCheckFill>
                              <span className='eventDetailText'>
                                Ingen anmälan krävs
                              </span>
                            </>
                          ) : (
                            <>
                              <PersonCheckFill
                                className='eventDetailIcons'
                                onClick={() => {
                                  setShowListModal(true);
                                }}
                                style={{ cursor: 'pointer' }}
                              ></PersonCheckFill>
                              <Button
                                size='sm'
                                variant='outline-secondary'
                                className='d-inline-block eventDetailText'
                                onClick={() => {
                                  setShowListModal(true);
                                }}
                                style={{ border: 'none' }}
                              >
                                {getNumberOfSpots(fetchedEvent)}
                              </Button>
                            </>
                          )}
                        </Col>
                      </Row>
                      <Collapse in={showParticipants}>
                        <div
                          id='collapse-text'
                          style={{
                            maxWidth: '100%',
                            maxHeight: '25vh',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            marginBottom: '8px',
                            marginTop: '-8px',
                          }}
                        >
                          <Row>
                            <Col>
                              {fetchedEvent.acceptedAccounts.length > 0 ? (
                                <strong>Deltagarlista</strong>
                              ) : (
                                <strong>Inga anmälda</strong>
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {fetchedEvent.acceptedAccounts.map(
                                (email, index) => {
                                  if (index < fetchedEvent.maxParticipants) {
                                    return (
                                      <p
                                        style={{ paddingLeft: '16px' }}
                                        key={index}
                                      >
                                        {email}
                                      </p>
                                    );
                                  }
                                  return;
                                }
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Collapse>
                      {fetchedEvent.acceptedAccounts.length >
                        fetchedEvent.maxParticipants && (
                        <Row className='mb-3'>
                          <Col>
                            <PersonCheckFill
                              style={{ cursor: 'pointer' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (setShowListModal !== fetchedEvent.id) {
                                  setShowListModal(fetchedEvent.id);
                                } else {
                                  setShowListModal(null);
                                }
                              }}
                              className='eventDetailIcons'
                            ></PersonCheckFill>
                            <Button
                              size='sm'
                              variant='outline-secondary'
                              className='d-inline-block modalEventText'
                              onClick={(e) => {
                                e.stopPropagation();
                                if (setShowListModal !== fetchedEvent.id) {
                                  setShowListModal(fetchedEvent.id);
                                } else {
                                  setShowListModal(null);
                                }
                              }}
                              style={{ border: 'none' }}
                            >
                              {fetchedEvent.acceptedAccounts.length -
                                fetchedEvent.maxParticipants !==
                              1
                                ? fetchedEvent.acceptedAccounts.length -
                                  fetchedEvent.maxParticipants +
                                  ' reserver'
                                : fetchedEvent.acceptedAccounts.length -
                                  fetchedEvent.maxParticipants +
                                  ' reserv'}
                            </Button>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <PeopleFill className='eventDetailIcons'></PeopleFill>
                          <p className='d-inline-block eventDetailText'>
                            Antal platser: {fetchedEvent.maxParticipants}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Container>
                  <div className='eventSignUp-area'>
                    {todaysDate !== null &&
                    (fetchedEvent.invitedAccounts.length !== 0 ||
                      fetchedEvent.openInvitation === true) ? (
                      <>
                        {props.loggedInAsAdmin && (
                          <Row className='mb-3'>
                            <Col>
                              <ModalInputSwitch
                                controlId='handleOtherSignUp'
                                label='Vill du hantera anmälan för någon annan?'
                                name='otherSignUp'
                                onChange={() =>
                                  setShowAdminSignUp(!showAdminSignUp)
                                }
                              />
                            </Col>
                          </Row>
                        )}
                        {todaysDate <= new Date(fetchedEvent.lastDate) ? (
                          <>
                            <Row>
                              <Col xl={6}>
                                <h2 className='signUpHeader'>Anmäl dig här!</h2>
                              </Col>
                              {fetchedEvent.canBringGuest &&
                                todaysDate <=
                                  new Date(fetchedEvent.lastDate) && (
                                  <Col xl={6}>
                                    <Button
                                      style={{
                                        whiteSpace: 'nowrap',
                                        border: 'none',
                                        backgroundColor: 'transparent',
                                        color: '#333333',
                                        fontSize: '16px',
                                        fontWeight: '700',
                                      }}
                                      variant='outline-dark'
                                      size='sm'
                                      className='outline-blackButton defaultButton'
                                      onClick={() =>
                                        setShowBringGuest(!showBringGuest)
                                      }
                                    >
                                      + Lägg till gäst
                                    </Button>
                                  </Col>
                                )}
                            </Row>
                            <Row>
                              <Col>
                                <p>
                                  Sista anmälningsdag är{' '}
                                  {fetchedEvent.lastDate.substring(0, 10) +
                                    ' klockan ' +
                                    fetchedEvent.lastDate.substring(11)}
                                </p>
                              </Col>
                            </Row>

                            {!!accountsData &&
                              !!fetchedEvent &&
                              !!fetchedEvents && (
                                <Row className='mt-3 mb-3'>
                                  <Col>
                                    {fetchedEvent.invitedAccounts.length !==
                                      0 &&
                                      !checkIfSignedUp(
                                        fetchedEvent,
                                        userInput.email.toLowerCase()
                                      ) &&
                                      !showAdminSignUp &&
                                      checkForTimeConflict(fetchedEvent) && (
                                        <span
                                          style={{
                                            color: 'orange',
                                            fontSize: '20px',
                                          }}
                                        >
                                          Varning! Du är redan anmäld till ett
                                          annat event som krockar med detta.{' '}
                                        </span>
                                      )}

                                    {fetchedEvent.invitedAccounts.length ===
                                      0 &&
                                      !checkIfSignedUp(
                                        fetchedEvent,
                                        userInput.email.toLowerCase()
                                      ) &&
                                      fetchedEvent.openInvitation &&
                                      !showAdminSignUp &&
                                      checkForTimeConflict(fetchedEvent) && (
                                        <span
                                          style={{
                                            color: 'orange',
                                            fontSize: '20px',
                                          }}
                                        >
                                          Varning! Du är redan anmäld till ett
                                          annat event som krockar med detta.{' '}
                                        </span>
                                      )}
                                  </Col>
                                </Row>
                              )}
                          </>
                        ) : (
                          <>
                            <Row>
                              <Col>
                                <h2>Det är för sent för att anmäla sig</h2>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p>
                                  Sista anmälningsdatum var den{' '}
                                  {fetchedEvent.lastDate.substring(0, 10) +
                                    ' klockan ' +
                                    fetchedEvent.lastDate.substring(11)}
                                </p>
                              </Col>
                            </Row>
                          </>
                        )}

                        {fetchedEvent.canBringGuest &&
                          todaysDate <= new Date(fetchedEvent.lastDate) && (
                            <>
                              {showBringGuest && (
                                <div className='fade-in-animation'>
                                  <Row className='mb-5'>
                                    <Col xxl={6} md={6}>
                                      <Form>
                                        <Form.Control
                                          className='modalInput'
                                          type='text'
                                          name='currentGuestFirstName'
                                          value={
                                            currentGuest.currentGuestFirstName
                                          }
                                          onChange={handleGuestInputChange}
                                          style={{
                                            textAlign: 'center',
                                            padding: '8px 24px',
                                            gap: '60px',
                                            boxShadow:
                                              '0px 4px 24px rgba(0, 0, 0, 0.1)',
                                            borderRadius: '12px',
                                          }}
                                          size='sm'
                                          placeholder='Förnamn'
                                        />
                                      </Form>
                                    </Col>

                                    <Col xxl={6} md={6}>
                                      <Form>
                                        <Form.Control
                                          className='modalInput'
                                          type='email'
                                          name='currentGuestLastName'
                                          value={
                                            currentGuest.currentGuestLastName
                                          }
                                          onChange={handleGuestInputChange}
                                          style={{
                                            textAlign: 'center',
                                            padding: '8px 24px',
                                            gap: '60px',
                                            boxShadow:
                                              '0px 4px 24px rgba(0, 0, 0, 0.1)',
                                            borderRadius: '12px',
                                          }}
                                          size='sm'
                                          placeholder='Efternamn'
                                        />
                                      </Form>
                                    </Col>

                                    <Col xxl={8} md={12}>
                                      <Form.Group controlId='formGuestFoodPref2'>
                                        <Form.Control
                                          className='modalInput'
                                          as='textarea'
                                          rows={2}
                                          style={{ textAlign: 'left' }}
                                          name='currentGuestFoodPref'
                                          value={
                                            currentGuest.currentGuestFoodPref
                                          }
                                          placeholder='Matrestriktioner/allergier'
                                          onChange={handleGuestInputChange}
                                        />
                                      </Form.Group>
                                    </Col>

                                    <Col xxl={{ span: 2, offset: 2 }} md={12}>
                                      <Button
                                        style={{
                                          whiteSpace: 'nowrap',
                                          border: '2px solid #333333',
                                          backgroundColor: 'transparent',
                                          color: '#333333',
                                          marginTop: '16px',
                                        }}
                                        variant='outline-dark'
                                        size='lg'
                                        className='outline-blackButtonSmall defaultButton'
                                        onClick={() => {
                                          if (
                                            addGuest.guests.length <
                                            fetchedEvent.numberOfGuests
                                          ) {
                                            if (
                                              addGuest.guestFirstName !== '' &&
                                              addGuest.guestLastName !== ''
                                            ) {
                                              let currentGuestCopy =
                                                addGuest.guests;
                                              currentGuestCopy.push({
                                                guestFirstName:
                                                  currentGuest.currentGuestFirstName,
                                                guestLastName:
                                                  currentGuest.currentGuestLastName,
                                                guestFoodPref:
                                                  currentGuest.currentGuestFoodPref,
                                              });
                                              setAddGuest({
                                                bringGuest: true,
                                                guests: currentGuestCopy,
                                              });

                                              setShowGuestSuccessMessage(true);
                                              const timeOut = setTimeout(() => {
                                                setShowGuestSuccessMessage(
                                                  false
                                                );
                                                setCurrentGuest({
                                                  currentGuestFirstName: '',
                                                  currentGuestLastName: '',
                                                  currentGuestFoodPref: '',
                                                });
                                              }, 2000);
                                              return () => {
                                                clearTimeout(timeOut);
                                              };
                                            } else {
                                              setShowGuestErrorMessage(true);
                                              const timeOut = setTimeout(() => {
                                                setShowGuestErrorMessage(false);
                                              }, 2000);
                                              return () => {
                                                clearTimeout(timeOut);
                                              };
                                            }
                                          } else {
                                            setShowTooManyGuestsError(true);
                                            const timeOut = setTimeout(() => {
                                              setShowTooManyGuestsError(false);
                                            }, 2000);
                                            return () => {
                                              clearTimeout(timeOut);
                                            };
                                          }
                                        }}
                                      >
                                        Bjud in
                                      </Button>
                                    </Col>
                                  </Row>

                                  {addGuest.guests.length !== 0 ? (
                                    addGuest.guests.map((guest, index) => {
                                      return (
                                        <Row>
                                          <Col>
                                            <p>
                                              <strong>Namn: </strong>
                                              {guest.guestFirstName +
                                                ' ' +
                                                guest.guestLastName}{' '}
                                              <strong>Matpreferens: </strong>
                                              {guest.guestFoodPref}
                                            </p>
                                          </Col>
                                          <Col>
                                            <Button
                                              size='sm'
                                              variant='danger'
                                              onClick={() =>
                                                removeUserFromInviteList(index)
                                              }
                                            >
                                              <XCircle></XCircle>
                                            </Button>
                                          </Col>
                                        </Row>
                                      );
                                    })
                                  ) : (
                                    <>
                                      {fetchedEvent.numberOfGuests === 1 ? (
                                        <em>Du kan ta med 1 gäst.</em>
                                      ) : (
                                        <em>
                                          Du kan ta med{' '}
                                          {fetchedEvent.numberOfGuests} gäster.
                                        </em>
                                      )}
                                    </>
                                  )}

                                  <Row>
                                    <Col>
                                      <Collapse in={showGuestErrorMessage}>
                                        <p style={{ color: 'red' }}>
                                          Måste fylla i alla fält
                                        </p>
                                      </Collapse>
                                      <Collapse in={showGuestSuccessMessage}>
                                        <p style={{ color: 'green' }}>
                                          {currentGuest.currentGuestFirstName +
                                            ' ' +
                                            currentGuest.currentGuestLastName}{' '}
                                          är tillagd som din gäst.
                                        </p>
                                      </Collapse>
                                      <Collapse in={showTooManyGuestsError}>
                                        <p style={{ color: 'red' }}>
                                          Kan inte bjuda in fler gäster.
                                        </p>
                                      </Collapse>
                                    </Col>
                                  </Row>
                                </div>
                              )}
                            </>
                          )}

                        {props.loggedInAsAdmin && showAdminSignUp && (
                          <Row>
                            <Col
                              style={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                              }}
                              md={12}
                            >
                              <Form>
                                <Form.Group
                                  className='mt-3 mb-3'
                                  controlId='formEmail'
                                >
                                  <Form.Control
                                    className='modalInput'
                                    type='email'
                                    name='email'
                                    value={userInput.email}
                                    onChange={handleInputChange}
                                    style={{
                                      textAlign: 'center',
                                      padding: '8px 24px',
                                      gap: '60px',
                                    }}
                                    size='lg'
                                    placeholder='Din e-post'
                                  />
                                </Form.Group>
                              </Form>
                            </Col>
                          </Row>
                        )}

                        <Row className='mt-5' style={{ minWidth: '100%' }}>
                          {todaysDate <= new Date(fetchedEvent.startDate) &&
                            checkIfSignedUp(
                              fetchedEvent,
                              userInput.email.toLowerCase()
                            ) && (
                              <Col xxl={6} xs={12}>
                                {fetchedEvent.invitedAccounts.length !== 0 &&
                                  checkIfSignedUp(
                                    fetchedEvent,
                                    userInput.email.toLowerCase()
                                  ) &&
                                  !fetchedEvent.openInvitation && (
                                    <CancelButton
                                      onClick={cancelSignUp}
                                      showAdminSignUp={showAdminSignUp}
                                    />
                                  )}
                                {fetchedEvent.openInvitation &&
                                  checkIfSignedUp(
                                    fetchedEvent,
                                    userInput.email.toLowerCase()
                                  ) && (
                                    <CancelButton
                                      onClick={cancelSignUp}
                                      showAdminSignUp={showAdminSignUp}
                                    />
                                  )}
                              </Col>
                            )}

                          {todaysDate <= new Date(fetchedEvent.lastDate) && (
                            <Col xxl={6} xs={12}>
                              {isInviteOnly(fetchedEvent) &&
                                !showAdminSignUp &&
                                !checkIfSignedUp(
                                  fetchedEvent,
                                  userInput.email.toLowerCase()
                                ) && (
                                  <SignUpButton
                                    onClick={handleSubmit}
                                    signUpAsReserve={isFullyBooked()}
                                  />
                                )}
                              {fetchedEvent.openInvitation &&
                                !showAdminSignUp &&
                                !checkIfSignedUp(
                                  fetchedEvent,
                                  userInput.email.toLowerCase()
                                ) && (
                                  <SignUpButton
                                    onClick={handleSubmit}
                                    signUpAsReserve={isFullyBooked()}
                                  />
                                )}
                              {isInviteOnly(fetchedEvent) &&
                                showAdminSignUp &&
                                !checkIfSignedUp(
                                  fetchedEvent,
                                  userInput.email.toLowerCase()
                                ) && (
                                  <SignUpButton
                                    onClick={handleSubmit}
                                    signUpAsReserve={isFullyBooked()}
                                  />
                                )}
                              {fetchedEvent.openInvitation &&
                                showAdminSignUp &&
                                !checkIfSignedUp(
                                  fetchedEvent,
                                  userInput.email.toLowerCase()
                                ) && (
                                  <SignUpButton
                                    onClick={handleSubmit}
                                    signUpAsReserve={isFullyBooked()}
                                  />
                                )}
                            </Col>
                          )}
                        </Row>
                        <>
                          <Collapse in={showSuccessMessage}>
                            <h4 style={{ color: 'green' }}>
                              Du är nu anmäld till "{fetchedEvent.title}"
                            </h4>
                          </Collapse>
                          <Collapse in={showCancelMessage}>
                            <h4 style={{ color: 'orange' }}>
                              Du har nu tagit bort din anmälan från eventet: "
                              {fetchedEvent.title}"
                            </h4>
                          </Collapse>
                          <Collapse in={showErrorMessage}>
                            <h4 style={{ color: 'red' }}>
                              Inkorrekt E-postadress eller så har du inte blivit
                              inbjuden.
                            </h4>
                          </Collapse>
                          <Collapse in={showErrorCancelMessage}>
                            <h4 style={{ color: 'red' }}>
                              Inkorrekt E-postadress eller så är du inte anmäld.
                            </h4>
                          </Collapse>
                          <Collapse in={showAlreadySignedUp}>
                            <h4 style={{ color: 'orange' }}>
                              Du är redan anmäld till eventet "
                              {fetchedEvent.title}"
                            </h4>
                          </Collapse>
                        </>
                      </>
                    ) : (
                      <Row>
                        <Col>
                          {fetchedEvent.invitedAccounts.length === 0 &&
                            fetchedEvent.openInvitation === false &&
                            !showTooLateMessage && (
                              <p style={{ fontSize: '200%' }}>
                                Ingen anmälan behövs till detta event. Hoppas vi
                                ses på{' '}
                                {fetchedEvent.eventType === 1
                                  ? 'go’ frulle'
                                  : fetchedEvent.eventType === 4
                                  ? 'afterwork'
                                  : ''}
                                !
                              </p>
                            )}
                          {showTooLateMessage && (
                            <p style={{ fontSize: '200%' }}>
                              Det är tyvärr för sent för att anmäla sig till
                              detta event.
                            </p>
                          )}
                        </Col>
                      </Row>
                    )}
                  </div>
                </div>
                {/* </div> */}
              </Col>

              <Col md={12} xl={6}>
                <Row style={{ width: '100%' }}>
                  {fetchedEvent.imageURL !== '' ? (
                    <Col
                      className='mb-5 mt-3'
                      sm={{ span: 12, order: 2 }}
                      md={{ span: 12, order: 2 }}
                      lg={{ span: 12, order: 1 }}
                    >
                      <Image
                        className='eventSignUpImage'
                        rounded
                        fluid
                        src={fetchedEvent.imageURL}
                        alt='event Image'
                      />
                    </Col>
                  ) : (
                    <Col
                      className='mb-5 mt-3'
                      sm={{ span: 12, order: 2 }}
                      md={{ span: 12, order: 2 }}
                      lg={{ span: 12, order: 1 }}
                    >
                      <Image
                        className='eventSignUpImage'
                        rounded
                        fluid
                        src={Logo}
                        alt='HiQ logo'
                      />
                    </Col>
                  )}

                  <Col
                    className='mb-3'
                    sm={{ span: 12, order: 1 }}
                    md={{ span: 12, order: 1 }}
                    lg={{ span: 12, order: 2 }}
                  >
                    <p
                      style={
                        currentWindowSize.width <= 1200
                          ? { textAlign: 'left' }
                          : {}
                      }
                      className='modalEventDescription'
                    >
                      <strong style={{ fontSize: '18px' }}>Beskrivning:</strong>{' '}
                      <br /> {fixEventDescription(fetchedEvent.description)}
                    </p>
                  </Col>
                </Row>
              </Col>

              <Modal
                aria-labelledby='example-custom-modal-styling-title'
                className='modal center'
                show={showListModal}
                onHide={hideListModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {fetchedEvent.title} -{' '}
                    {fetchedEvent.acceptedAccounts.length === 1
                      ? fetchedEvent.acceptedAccounts.length + ' anmäld'
                      : fetchedEvent.acceptedAccounts.length + ' anmälda'}
                  </Modal.Title>
                  {checkPermission(fetchedEvent) && (
                    <>
                      <ExcelGenerator
                        style={{ marginLeft: '32px' }}
                        excelData={generateJsonForExcel(fetchedEvent)}
                        fileName={
                          fetchedEvent.title.replace(/\s/g, '') +
                          '_deltagarlista'
                        }
                        buttonText={<Download />}
                      />
                      <Tooltip title='Skicka mail till anmälda'>
                        <a href={generateMailingListString(fetchedEvent)}>
                          <Button variant='dark' style={{ marginLeft: '16px' }}>
                            {<Envelope />}
                          </Button>
                        </a>
                      </Tooltip>
                    </>
                  )}
                </Modal.Header>
                <Modal.Body>
                  <Row className='m-3'>
                    <Col
                      className='me-3'
                      style={{ borderRight: '2px solid #eee' }}
                    >
                      <h4>Deltagarlista</h4>
                      {fetchedEvent.acceptedAccounts.map((email, index) => {
                        if (index < fetchedEvent.maxParticipants) {
                          return (
                            <p key={index}>
                              <strong>{email}</strong>{' '}
                              {checkPermission(fetchedEvent) &&
                                ' - ' +
                                  'Matpreferens: ' +
                                  getFoodPreference(email)}
                            </p>
                          );
                        }
                      })}
                    </Col>
                    <Col>
                      <h4>Reservlista</h4>
                      {fetchedEvent.acceptedAccounts.map((email, index) => {
                        if (index >= fetchedEvent.maxParticipants) {
                          return (
                            <p key={index}>
                              <strong>{email}</strong>{' '}
                              {checkPermission(fetchedEvent) &&
                                ' - ' +
                                  'Matpreferens: ' +
                                  getFoodPreference(email)}
                            </p>
                          );
                        }
                      })}
                    </Col>
                  </Row>
                </Modal.Body>
              </Modal>
            </Row>
          </div>
        ) : (
          <>
            {!fetchAccountsError && !fetchEventsError && (
              <Spinner
                style={{
                  width: '15vh',
                  height: '15vh',
                  margin: '30vh auto 0 auto',
                }}
                animation='border'
                role='status'
              >
                <span className='visually-hidden'>Loading</span>
              </Spinner>
            )}
            {(fetchEventsError === true || fetchAccountsError === true) && (
              <div style={{ textAlign: 'center' }}>
                <Row>
                  <Col md={12}>
                    <h1 style={{ margin: '30vh auto 0 auto' }}>
                      Ett fel har inträffat vid hämtning av data eller så finns
                      inte detta event. Vänligen kontakta admin om felet
                      kvarstår.
                    </h1>
                  </Col>
                </Row>
              </div>
            )}
          </>
        )}
      </>
    </AnimatedPage>
  );
};
