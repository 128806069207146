import React from 'react'
import { Button, Col, Row, Modal, CloseButton } from 'react-bootstrap';
import { Trash3Fill } from 'react-bootstrap-icons';


//This component is used for confirming creation, change or deletion of an event. 
export const ConfirmationModal = (props) => {
    return (
        <>
            <Modal centered show={props.showModal} onHide={() => props.setShowModal(false)}>
                <Modal.Header>
                    <Row style={{ float: 'left' }}>
                        <Col>
                            <CloseButton onClick={() => props.setShowModal(false)} style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                        </Col>

                        <Col>
                            <Modal.Title style={{ whiteSpace: 'nowrap' }}>{props.title}</Modal.Title>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <p>{props.bodyContent} </p>
                    {props.type === 'delete' && props.chainEvent &&
                        <>
                            <br />
                            <p>Det här eventet är ett återkommande event, vill du även ta bort de andra eventen i kedjan?  {'(' + props.chainSize}  event totalt {')'}</p>
                        </>
                    }
                    {props.type === 'edit' && props.chainEvent &&
                        <>
                            <br />
                            <p>Det här eventet är ett återkommande event, vill du även att dessa ändringar (förutom tiderna) ska ändras för de andra eventen?  {'(' + props.chainSize}  event totalt {')'}</p>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {props.type === 'delete' && !props.chainEvent &&
                        <Row style={{ width: '100%' }}>
                            <Col xs={6} md={4}>
                                <Button variant='outline-danger' className='outline-redButton-delete defaultButton' onClick={props.confirmFunction}><Trash3Fill /> Ta bort</Button>
                            </Col>

                            <Col xs={6} md={{ span: 4, offset: 4 }}>
                                <Button className='float-end outline-blackButtonSmall defaultButton' variant="outline-info" onClick={() => props.setShowModal(false)}>
                                    Avbryt
                                </Button>
                            </Col>
                        </Row>
                    }
                    {props.type === 'delete' && props.chainEvent &&
                        <Row style={{ width: '100%' }}>
                            <Col xs={6} md={4}>
                                <Button variant='outline-danger' className='outline-redButton-delete defaultButton' onClick={() => props.confirmFunction()}><Trash3Fill /> Ta bort</Button>
                            </Col>

                            <Col xs={6} md={4}>
                                <Button variant='outline-danger' className='outline-redButton-delete defaultButton' onClick={() => props.confirmChainFunction()}><Trash3Fill /> Ta bort alla events</Button>
                            </Col>

                            <Col xs={12} md={4}>
                                <Button className='float-end outline-blackButtonSmall defaultButton' variant="outline-info" onClick={() => props.setShowModal(false)}>
                                    Avbryt
                                </Button>
                            </Col>
                        </Row>
                    }
                    {props.type === 'edit' && !props.chainEvent &&
                        <Row style={{ width: '100%' }}>
                            <Col xs={6} md={4}>
                                <Button size='lg' variant='info' className='modalLightBlueButton float-start' onClick={() => props.confirmFunction()}>
                                    Spara
                                </Button>
                            </Col>

                            <Col xs={6} md={{ span: 4, offset: 4 }}>
                                <Button className='float-end outline-blackButtonSmall defaultButton' variant="outline-info" onClick={() => props.setShowModal(false)}>
                                    Avbryt
                                </Button>
                            </Col>
                        </Row>
                    }
                    {props.type === 'edit' && props.chainEvent &&
                        <Row style={{ width: '100%' }}>
                            <Col xs={6} md={4}>
                                <Button size='lg' variant='info' className='modalLightBlueButton float-start' onClick={() => props.confirmFunction()}>
                                    Spara
                                </Button>
                            </Col>

                            <Col xs={6} md={4}>
                                <Button size='lg' variant='info' className='modalLightBlueButton float-start' onClick={() => props.confirmChainFunction()}>
                                    Spara för alla events i kedjan
                                </Button>
                            </Col>

                            <Col xs={12} md={4}>
                                <Button className='float-end outline-blackButtonSmall defaultButton' variant="outline-info" onClick={() => props.setShowModal(false)}>
                                    Avbryt
                                </Button>
                            </Col>
                        </Row>
                    }
                    {props.type === 'created' &&
                        <Row style={{ width: '100%' }}>
                            <Col xs={6} md={4}>
                                <Button style={{ whiteSpace: 'nowrap' }} variant="outline-info" className='modalLightBlueButton' onClick={() => props.confirmFunction()}>
                                    Eventsida
                                </Button>
                            </Col>

                            <Col xs={6} md={{ span: 4, offset: 4 }}>
                                <Button className='float-end outline-blackButtonSmall defaultButton' variant="outline-dark" onClick={() => props.setShowModal(false)}>
                                    Tillbaka
                                </Button>
                            </Col>
                        </Row>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}
