import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import { ThemeProvider } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { EventList } from './pages/EventList';
import { EventSignUp } from './pages/EventSignUp';
import { AnimatePresence } from 'framer-motion';
import { ErrorPage } from './pages/ErrorPage';
import { InteractionType } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
  useMsal,
} from '@azure/msal-react';

function App() {
  const location = useLocation();
  const [showBackgroundGif, setShowBackgroundGif] = useState(true);
  const [currentTheme, setCurrentTheme] = useState(
    'App-container-no-background'
  );

  //Fetching and storing the user data from Azure AD login.
  const { accounts } = useMsal();
  const userEmail = !!accounts[0]
    ? accounts[0].username
    : 'Error loading username';
  const usersName = !!accounts[0] ? accounts[0].name : 'Error loading name';
  const userRoles = !!accounts[0] ? accounts[0].idTokenClaims.roles : [];

  useEffect(() => {
    if (!!accounts) {
      console.log(accounts);
    }
  }, [accounts]);

  //Storing the data from the login session in a state to be passed throughout the application
  const [m_strUser, setm_strUser] = useState({
    name: '',
    email: '',
    roles: [],
  });
  useEffect(() => {
    try {
      setm_strUser({
        name: usersName,
        email: userEmail,
        roles: userRoles,
      });
    } catch (e) {}
  }, [accounts]);

  //Auto redirects to login page with Azure AD
  useMsalAuthentication(InteractionType.Redirect);

  const displayBackgroundGif = (value) => {
    setShowBackgroundGif(value);
  };

  const changeCurrentTheme = (value) => {
    setCurrentTheme(value);
    return currentTheme;
  };

  const [loggedInAsAdmin, setLoggedInAsAdmin] = useState(false);
  const handleAdminStatus = (value) => {
    setLoggedInAsAdmin(value);
  };

  //If the logged in user has the role of admin, set the state loggedInAsAdmin = true.
  useEffect(() => {
    try {
      if (m_strUser.roles.includes('Role.admin')) {
        setLoggedInAsAdmin(true);
      }
    } catch (e) {}
  }, [m_strUser]);

  return (
    <ThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
      minBreakpoint='xxs'
    >
      <Header
        loggedInAs={loggedInAsAdmin ? ' HiQ-eventadministratör' : 'Gäst'}
        handleAdminStatus={handleAdminStatus}
        loggedInAsAdmin={loggedInAsAdmin}
        displayBackgroundGif={displayBackgroundGif}
        loggedInUser={m_strUser}
      />
      <div className={showBackgroundGif ? 'App-container' : currentTheme}>
        <AuthenticatedTemplate>
          <AnimatePresence exitBeforeEnter>
            <Routes key={location.pathname} location={location}>
              <Route
                path='/'
                element={
                  <EventList
                    loggedInAsAdmin={loggedInAsAdmin}
                    displayBackgroundGif={displayBackgroundGif}
                    changeCurrentTheme={changeCurrentTheme}
                    loggedInUser={m_strUser}
                  ></EventList>
                }
              ></Route>
              <Route
                path='/signup'
                element={<h1>Anmälan till event</h1>}
              ></Route>
              <Route
                path='/signup/:eventId'
                element={
                  <EventSignUp
                    displayBackgroundGif={displayBackgroundGif}
                    changeCurrentTheme={changeCurrentTheme}
                    loggedInAsAdmin={loggedInAsAdmin}
                    loggedInUser={m_strUser}
                  ></EventSignUp>
                }
              ></Route>
              <Route
                path='*'
                element={<ErrorPage errorMessage='404 - Sidan hittades inte' />}
              />
            </Routes>
          </AnimatePresence>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <AnimatePresence exitBeforeEnter>
            <Routes key={location.pathname} location={location}>
              <Route
                path='*'
                element={
                  <ErrorPage errorMessage='Vänligen ladda om och logga in för att visa sidan' />
                }
              />
            </Routes>
          </AnimatePresence>
        </UnauthenticatedTemplate>
      </div>
    </ThemeProvider>
  );
}

export default App;
