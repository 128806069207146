import React from 'react'
import { motion } from 'framer-motion'

//Trigger an animation when navigating between different pages. Example when navigating to the home page from an eventSignup page.
const animations = {
    initial: {opacity: 0, x: 100},
    animate: {opacity: 1, x: 0},
    exit: {opacity: 0, x: -100},
}

const AnimatedPage = ({children}) => {
  return (
    <motion.div
        variants={animations}
        initial='initial'
        animate='animate'
        exit='exit'
        transition={{ duration: 0.5 }}
    >
        {children}
    </motion.div>
  )
}

export default AnimatedPage