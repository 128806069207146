import React, { useState, useEffect } from 'react';
import {
  Button,
  Form,
  Col,
  Dropdown,
  Container,
  Row,
  Modal,
  Collapse,
  Image,
  CloseButton,
} from 'react-bootstrap';
import {
  XCircle,
  ArrowRepeat,
  CalendarCheck,
  Trash3Fill,
} from 'react-bootstrap-icons';
import { AutoSuggestInput } from '../AutoSuggestInput';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './modal.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers';
// import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/sv';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { ConfirmationModal } from './ConfirmationModal';
import { ModalInputSwitch } from '../ModalInputSwitch';
import { Inputfield } from '../Inputfield';
import { InputSelect } from '../InputSelect';
import { DateTimeInputfield } from '../DateTimeInputfield';
import { MailTextEditor } from '../MailTextEditor';
import { useAccountsQuery } from 'hooks/useAccountsQuery';

export const EventModal = (props) => {
  const navigate = useNavigate();
  const [text, setText] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [uploadedFile, setUploadedFile] = useState(null);

  const [inviteListFilters, setInviteListFilters] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [employmentCityList, setEmploymentCityList] = useState([]);

  const { data: accountsData } = useAccountsQuery();

  //Saves the state from the form and is later used when sending the data to the API when creating or editing an event
  const [eventInput, setEventInput] = useState({
    title: '',
    description: '',
    location: '',
    startDate: '',
    endTime: '',
    lastDate: '',
    maxParticipants: 1,
    invitedAccounts: [],
    eventType: 0,
    imageURL: '',
    themeType: 0,
    active: true,
    openInvitation: false,
    canBringGuest: false,
    numberOfGuests: 1,
    hasSentReminder: true,
    recurringEventId: '',
    createdBy: !!props.loggedInUser
      ? props.loggedInUser.email.toLowerCase()
      : '',
    showFoodPref: false,
    uploadedFiles: ['empty'],
  });

  const [emailImage, setEmailImage] = useState([]);
  const [emailImageUrl, setEmailImageUrl] = useState([]);

  const onImageChange = (e) => {
    setEventInput((prevState) => ({
      ...prevState,
      imageURL: '',
    }));
    setEmailImage([...e.target.files]);
  };
  const [dateTimeValue, setDateTimeValue] = useState(dayjs('2022-11-08'));
  const [dateTimeValueEnd, setDateTimeValueEnd] = useState(dayjs('2022-11-08'));
  const [dateValue, setDateValue] = useState(dayjs('2022-11-08'));
  const [showEditChainModal, setShowEditChainModal] = useState(false);
  const [previouslyPrivateEvent, setPreviouslyPrivateEvent] = useState(false);

  //If a user is editing event and its set to "private", setPreviouslyPrivateEvent = true, so that a different email can be sent if changed to a public event.
  useEffect(() => {
    if (props.type === 'edit' && !!props.event) {
      if (!props.event.active) {
        setPreviouslyPrivateEvent(true);
      }
    }
  }, [props.type]);

  const selectOptions = [
    {
      value: 'daily',
      label: (
        <>
          <ArrowRepeat style={{ marginRight: '8px' }} /> Varje dag
        </>
      ),
    },
    {
      value: 'weekly',
      label: (
        <>
          <ArrowRepeat style={{ marginRight: '8px' }} /> Varje vecka
        </>
      ),
    },
    {
      value: 'monthly',
      label: (
        <>
          <ArrowRepeat style={{ marginRight: '8px' }} /> Varje månad
        </>
      ),
    },
  ];

  //Styling for dropdown component
  const styles = {
    control: (base) => ({
      ...base,
      minHeight: 20,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    menuList: (base) => ({
      ...base,
      marginTop: 0,
      paddingTop: 0,
      marginBottom: 0,
      paddingBottom: 0,
    }),
  };

  //Called when the user uploades an image.
  useEffect(() => {
    if (emailImage.length < 1) {
      return;
    }
    const newImageUrl = [];
    emailImage.forEach((image) => newImageUrl.push(URL.createObjectURL(image)));
    setEmailImageUrl(newImageUrl);
    setEventInput((prevState) => ({
      ...prevState,
      imageURL: newImageUrl[0],
    }));
  }, [emailImage]);

  let date = new Date();
  let dd = date.getDate().toString().padStart(2, '0');
  let mm = (date.getMonth() + 1).toString().padStart(2, '0');
  let yyyy = date.getFullYear();
  let today = String(yyyy + '-' + mm + '-' + dd + 'T' + '00:00');
  let todayOnlyDate = String(yyyy + '-' + mm + '-' + dd);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showAddedToList, setShowAddedToList] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showWrongDateInput, setShowWrongDateInput] = useState(false);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);

  const [mailingList, setMailingList] = useState([]);

  const [addedUsersToInvite, setAddedUsersToInvite] = useState([]);
  const [customEmailInvite, setCustomEmailInvite] = useState('');
  const [showCustomEmailInvited, setShowCustomEmailInvited] = useState(false);
  const [showIncorrectEmail, setShowIncorrectEmail] = useState(false);

  //The frequency of a reoccurring event
  const [reoccuringEvent, setReoccuringEvent] = useState({
    isReoccuring: false,
    frequency: 'weekly',
    lastOccuringDate: '',
  });

  const [invite, setInvite] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const [showEditInvite, setShowEditInvite] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);

  const [showRouteModal, setShowRouteModal] = useState(false);
  const [routedEvent, setRoutedEvent] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const eventTypeOptions = [
    {
      value: 4,
      text: 'Afterwork',
    },
    {
      value: 6,
      text: 'Familjedag',
    },
    {
      value: 5,
      text: 'Fest',
    },
    {
      value: 1,
      text: 'Frukost',
    },
    {
      value: 9,
      text: 'HiQ Community',
    },
    {
      value: 8,
      text: 'Kundevent',
    },
    {
      value: 2,
      text: 'Lunch & Learn',
    },
    {
      value: 7,
      text: 'Sport',
    },
    {
      value: 0,
      text: 'Standard event',
    },
    {
      value: 3,
      text: 'Teamaktivitet',
    },
    {
      value: 10,
      text: 'Övrigt',
    },
  ];

  const eventThemeOptions = [
    {
      value: 0,
      text: 'Standard tema',
    },
    {
      value: 1,
      text: 'Mint',
    },
    {
      value: 2,
      text: 'Peach',
    },
    {
      value: 3,
      text: 'Pink-light',
    },
    {
      value: 4,
      text: 'Purple',
    },
  ];

  //Generate a list of team Names and a list of employment cities once the accounts have been fetched.
  useEffect(() => {
    if (!!accountsData) {
      let newTeamList = [];
      let newEmploymentCityList = [];
      accountsData.forEach((account) => {
        if (
          !newTeamList.includes(account.teamName) &&
          !!account.teamName &&
          account.teamName !== 'No team lead'
        ) {
          newTeamList.push(account.teamName);
        }
        if (
          !newEmploymentCityList.includes(account.employmentCity) &&
          !!account.employmentCity &&
          account.employmentCity !== 'No employment city'
        ) {
          newEmploymentCityList.push(account.employmentCity);
        }
      });
      setTeamList(newTeamList);
      setEmploymentCityList(newEmploymentCityList);
    }
  }, [accountsData]);

  //Close the modal once an event has been created.
  useEffect(() => {
    if (routedEvent !== null) {
      const timeOut = setTimeout(() => {
        setShowRouteModal(true);
        handleClose();
      }, 1500);
      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [routedEvent]);

  //Generate a RecurringEventId if the "is this a recurring event" is checked.
  useEffect(() => {
    if (
      reoccuringEvent.isReoccuring &&
      !!eventInput.title &&
      eventInput.recurringEventId === ''
    ) {
      setEventInput((prevState) => ({
        ...prevState,
        recurringEventId:
          eventInput.title + 'key=' + generateRecurringEventId(),
      }));
    }
  }, [reoccuringEvent.isReoccuring, eventInput.title]);

  //set the state of "createdBy" to the logged in users mail address.
  useEffect(() => {
    if (
      props.type === 'create' &&
      !!props.loggedInUser &&
      props.loggedInUser.email !== ''
    ) {
      setEventInput((prevState) => ({
        ...prevState,
        createdBy: props.loggedInUser.email.toLowerCase(),
      }));
    }
  }, [props.loggedInUser, props.type]);

  //Reset all the states once the modal is closed.
  const handleClose = () => {
    props.setShow(false);
    setShowPreview(false);
    setInvite(false);
    setReoccuringEvent({
      isReoccuring: false,
      frequency: 'weekly',
      lastOccuringDate: '',
    });
    setShowIncorrectEmail(false);
    setShowCustomEmailInvited(false);
    setCustomEmailInvite('');
    setAddedUsersToInvite([]);
    setShowWrongDateInput(false);
    setShowErrorMessage(false);
    setShowAddedToList(false);
    setShowSuccessMessage(false);
    setEmailImage([]);
    setEventInput({
      title: '',
      description: '',
      location: '',
      startDate: '',
      endTime: '',
      lastDate: '',
      maxParticipants: 1,
      invitedAccounts: [],
      eventType: 0,
      imageURL: '',
      themeType: 0,
      active: true,
      openInvitation: false,
      canBringGuest: false,
      numberOfGuests: 1,
      hasSentReminder: true,
      recurringEventId: '',
      createdBy: !!props.loggedInUser
        ? props.loggedInUser.email.toLowerCase()
        : '',
      showFoodPref: false,
      uploadedFiles: ['empty'],
    });
    setEmailBody('');
    setText('');
    let today = new Date();
    let date =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate();
    let time = today.getHours() + ':' + today.getMinutes();
    let dateTime = date + 'T' + time;
    setDateTimeValue(dayjs(dateTime));
    setDateValue(dayjs(todayOnlyDate));
    setSendEmail(false);
    props.handleModalType(null);
    setUploadedFile(null);
    setInviteListFilters([]);
    setTeamList([]);
    setEmploymentCityList([]);
    setCurrentTheme('standardModal');
  };

  const locationSuggestions = [
    'HiQ Malmö – Rådmansgatan 16, 211 46 Malmö',
    'HiQ Lund – Stortorget 8, 222 23 Lund',
    'HiQ Karlskrona – Campus Gräsvik 26, Karlskrona',
    'HiQ Helsingborg – Bröderna Pihls Gränd 2, 252 36 Helsingborg',
    'Digitalt event',
    'PDL Center Malmö – Skeppsbyggaregatan 7, 211 11 Malmö',
    'Kockum Fritid – Västra Varvsgatan 8, 211 11 Malmö',
  ];

  const [notifyUsersAboutChange, setNotifyUsersAboutChange] = useState(false);

  //When opening the modal to create a new event, automatically set the different times of the event.
  useEffect(() => {
    if (props.type === 'create') {
      if (accountsData !== null) {
        let newMailingList = [];
        for (let i = 0; i < accountsData.length; i++) {
          newMailingList.push({
            email: accountsData[i].email,
            checked: false,
          });
        }
        setMailingList(newMailingList);
      }
      let today = new Date();

      let month = today.getMonth() + 1;
      if (month < 10) {
        month = '0' + month.toString();
      }

      let day = today.getDate();
      if (day < 10) {
        day = '0' + day.toString();
      }

      let hour = today.getHours();
      if (hour < 10) {
        hour = '0' + hour.toString();
      }

      let minute = today.getMinutes();
      if (minute < 10) {
        minute = '0' + minute.toString();
      }

      let date = today.getFullYear() + '-' + month + '-' + day;
      let time = hour + ':' + minute;
      let dateTime = date + 'T' + time;

      setDateTimeValue(dayjs(dateTime));
      setDateTimeValueEnd(dayjs(addHours(new Date(dateTime), 1)));
      setDateValue(dayjs(addHours(new Date(dateTime), -1)));
      setEventInput((prevState) => ({
        ...prevState,
        startDate: dateTime,
        lastDate: addHours(new Date(dateTime), -1),
        endTime: addHours(new Date(dateTime), 1),
      }));
    }
  }, [props.type]);

  //If opening the modal to edit an event, automatically fill out the form with the previous values.
  useEffect(() => {
    if (props.type === 'edit' && props.event !== null) {
      setEventInput({
        title: props.event.title,
        description: props.event.description,
        location: props.event.location,
        startDate: props.event.startDate,
        endTime: props.event.endTime,
        lastDate: props.event.lastDate,
        maxParticipants: props.event.maxParticipants,
        currentNumberParticipants: props.event.acceptedAccounts.length,
        invitedAccounts: props.event.invitedAccounts,
        newlyAddedInvites: [],
        eventType: props.event.eventType,
        id: props.event.id,
        imageURL: props.event.imageURL,
        themeType: props.event.themeType,
        active: props.event.active,
        openInvitation: props.event.openInvitation,
        canBringGuest: props.event.canBringGuest,
        numberOfGuests: props.event.numberOfGuests,
        hasSentReminder: props.event.hasSentReminder,
        recurringEventId: props.event.recurringEventId
          ? props.event.recurringEventId
          : '',
        createdBy: props.event.createdBy,
        showFoodPref: props.event.showFoodPref,
        uploadedFiles: ['empty'],
      });
      setDateTimeValue(dayjs(props.event.startDate));
      setDateTimeValueEnd(dayjs(props.event.endTime));
      setDateValue(dayjs(props.event.lastDate));
    }
  }, [props.event]);

  //When opening the modal to edit an event, automatically fill change the switches to the previous values.
  useEffect(() => {
    if (
      props.type === 'edit' &&
      props.event !== null &&
      props.event.eventType !== null &&
      props.event.themeType !== null &&
      eventInput.title !== '' &&
      document.getElementById('eventTypeModal') !== null &&
      document.getElementById('eventThemeModal') !== null
    ) {
      document.getElementById('eventTypeModal').value = props.event.eventType;
      document.getElementById('eventThemeModal').value = props.event.themeType;
      document.getElementById('formOpenSignUpModal').checked =
        props.event.openInvitation;
      document.getElementById('formCanBringGuestModal').checked =
        props.event.canBringGuest;
      document.getElementById('formShowFoodPref').checked =
        props.event.showFoodPref;
    }
  }, [document.getElementById('eventTypeModal')]);

  //When opening the modal to create an event, automatically set the event type to standard event.
  useEffect(() => {
    if (props.type === 'create') {
      document.getElementById('eventTypeModal').value = 0;
    }
  }, [document.getElementById('eventTypeModal')]);

  //Called when the user is done with creating their event and hits "Create"
  const submitEventInput = () => {
    if (reoccuringEvent.isReoccuring) {
      setEventInput((prevState) => ({
        ...prevState,
        recurringEventId:
          eventInput.title + 'key=' + generateRecurringEventId(),
      }));
    } else {
      setEventInput((prevState) => ({
        ...prevState,
        recurringEventId: '',
      }));
    }
    if (
      eventInput.title !== '' &&
      eventInput.description !== '' &&
      eventInput.location !== '' &&
      eventInput.startDate !== '' &&
      eventInput.lastDate !== '' &&
      eventInput.maxParticipants !== 0 &&
      eventInput.endTime !== ''
    ) {
      if (
        eventInput.lastDate < eventInput.startDate &&
        eventInput.startDate < eventInput.endTime
      ) {
        createEvent(eventInput);
        if (reoccuringEvent.isReoccuring) {
          SubmitReoccuringEvent();
        }
        props.fetchEvents();
        props.fetchAccounts();
        setShowSuccessMessage(true);
        const timeOut = setTimeout(() => {
          setShowSuccessMessage(false);
          props.fetchEvents();
          props.fetchAccounts();
          // handleClose();
        }, 2500);
        return () => {
          clearTimeout(timeOut);
        };
      } else {
        setShowWrongDateInput(true);
        const timeOut = setTimeout(() => {
          setShowWrongDateInput(false);
        }, 3000);
        return () => clearTimeout(timeOut);
      }
    } else {
      setShowErrorMessage(true);
      const timeOut = setTimeout(() => {
        setShowErrorMessage(false);
      }, 3000);
      return () => clearTimeout(timeOut);
    }
  };

  //Called when the user is done with editing their event and hits "Save"
  const submitEditedEvent = () => {
    setShowEditChainModal(false);
    if (reoccuringEvent.isReoccuring) {
      if (
        props.event.recurringEventId === '' &&
        eventInput.recurringEventId === ''
      ) {
        setEventInput((prevState) => ({
          ...prevState,
          recurringEventId:
            eventInput.title + 'key=' + generateRecurringEventId(),
        }));
      }
    }
    if (
      eventInput.title !== '' &&
      eventInput.description !== '' &&
      eventInput.location !== '' &&
      eventInput.startDate !== '' &&
      eventInput.lastDate !== '' &&
      eventInput.maxParticipants !== 0 &&
      eventInput.endTime !== ''
    ) {
      if (
        eventInput.lastDate < eventInput.startDate &&
        eventInput.startDate < eventInput.endTime
      ) {
        if (props.event.maxParticipants > eventInput.maxParticipants) {
        }
        setShowSuccessMessage(true);
        if (eventInput.newlyAddedInvites.length !== 0) {
          editEventWithNewInvites(eventInput);
        } else {
          editEventNoNewInvites(eventInput);
        }
        if (reoccuringEvent.isReoccuring) {
          SubmitReoccuringEvent();
        }

        const timeOut = setTimeout(() => {
          setShowSuccessMessage(false);
          submitEmail();
          handleClose();
        }, 2500);
        return () => clearTimeout(timeOut);
      } else {
        setShowWrongDateInput(true);
        const timeOut = setTimeout(() => {
          setShowWrongDateInput(false);
        }, 3000);
        return () => clearTimeout(timeOut);
      }
    } else {
      setShowErrorMessage(true);
      const timeOut = setTimeout(() => {
        setShowErrorMessage(false);
      }, 3000);
      return () => clearTimeout(timeOut);
    }
  };

  //Called when the user is done with editing their event and hits "Save for all events in chain".
  const submitEditedEventChain = (recurringEventId) => {
    setShowEditChainModal(false);
    let eventChainToBeEdited = [];
    props.fetchedEvents.forEach((event) => {
      if (event.recurringEventId === recurringEventId) {
        let newEventInput = {
          id: event.id,
          title: eventInput.title,
          description: eventInput.description,
          location: eventInput.location,
          startDate: changeTimeForEventChain(event.startDate, 'startDate'),
          endTime: changeTimeForEventChain(event.endTime, 'endTime'),
          lastDate: changeTimeForEventChain(event.lastDate, 'lastDate'),
          maxParticipants: eventInput.maxParticipants,
          invitedAccounts: eventInput.invitedAccounts,
          eventType: eventInput.eventType,
          imageURL: eventInput.imageURL,
          themeType: eventInput.themeType,
          active: eventInput.active,
          openInvitation: eventInput.openInvitation,
          canBringGuest: eventInput.canBringGuest,
          numberOfGuests: eventInput.numberOfGuests,
          hasSentReminder: event.hasSentReminder,
          recurringEventId: event.recurringEventId,
          createdBy: event.createdBy,
          showFoodPref: event.showFoodPref,
          uploadedFiles: ['empty'],
        };
        eventChainToBeEdited.push(newEventInput);
      }
    });
    eventChainToBeEdited.forEach(async (event) => {
      if (
        event.title !== '' &&
        event.description !== '' &&
        event.location !== '' &&
        event.startDate !== '' &&
        event.lastDate !== '' &&
        event.maxParticipants !== 0 &&
        event.endTime !== ''
      ) {
        if (
          event.lastDate < event.startDate &&
          event.startDate < event.endTime
        ) {
          if (eventInput.newlyAddedInvites.length !== 0) {
            editEventWithNewInvites(event);
          } else {
            editEventNoNewInvites(event);
          }
        }
      }
    });
    setShowSuccessMessage(true);
    const timeOut = setTimeout(() => {
      setShowSuccessMessage(false);
      submitEmail();
      handleClose();
    }, 2500);
    return () => clearTimeout(timeOut);
  };

  //Creates a date object based on the dateString, adds the MS and returns a new dateString.
  const changeTimeForEventChain = (oldDateString, timeToChange) => {
    const datevalue1 = new Date(oldDateString);
    let newDate;
    let currentWindowDate;
    if (timeToChange === 'startDate') {
      newDate = new Date(eventInput.startDate);
      currentWindowDate = new Date(props.event.startDate);
    } else if (timeToChange === 'endTime') {
      newDate = new Date(eventInput.endTime);
      currentWindowDate = new Date(props.event.endTime);
    } else if (timeToChange === 'lastDate') {
      newDate = new Date(eventInput.lastDate);
      currentWindowDate = new Date(props.event.lastDate);
    }
    const differenceInMs = Math.abs(
      newDate.getTime() - currentWindowDate.getTime()
    );

    let newTimeAsMS = 0;
    if (newDate > currentWindowDate) {
      newTimeAsMS = datevalue1.getTime() + differenceInMs;
    } else {
      newTimeAsMS = datevalue1.getTime() - differenceInMs;
    }

    // const newTimeAsMS = datevalue1.getTime() + differenceInMs
    const adjustedDateObj = new Date(newTimeAsMS);

    let dateAsString =
      adjustedDateObj.getFullYear() +
      '-' +
      (adjustedDateObj.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      adjustedDateObj.getDate().toString().padStart(2, '0');
    let timeAsString =
      adjustedDateObj.getHours().toString().padStart(2, '0') +
      ':' +
      adjustedDateObj.getMinutes().toString().padStart(2, '0');
    let dateTimeAsString = dateAsString + 'T' + timeAsString;
    return dateTimeAsString;
  };

  //Called when inviting a "custom" email.
  useEffect(() => {
    setShowCustomEmailInvited(true);
    const timeOut = setTimeout(() => {
      setShowCustomEmailInvited(false);
    }, 3000);
    return () => clearTimeout(timeOut);
  }, [eventInput.newlyAddedInvites]);

  //Called when editing an event where newAccounts have been invited.
  const editEventWithNewInvites = async (event) => {
    let newList = [];
    const newFetchedAccounts = await fetch(
      'https://eventhandler-backend.azurewebsites.net/api/Accounts',
      {
        method: 'GET',
      }
    ).then((res) => res.json());

    let changedImageUrl = props.event.imageURL;
    if (!!uploadedFile) {
      changedImageUrl = `https://hiqeventhandlerstorage.blob.core.windows.net/${(
        event.title.replace(/[^A-Z0-9]/gi, '') +
        event.startDate.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
      ).toLowerCase()}/${uploadedFile.name}`;
    }
    Promise.all(
      eventInput.newlyAddedInvites.map((email) => {
        const mailOptions = {
          email: email,
          foodPreference: '',
          isHiqAccount: false,
          teamName: 'No team lead',
          employmentCity: 'No employment city',
          teamId: 'No team id',
        };
        if (newFetchedAccounts.filter((e) => e.email === email).length === 0) {
          fetch(
            /*'https://eventhandler-backend.azurewebsites.net/api/Accounts'*/ 'https://eventhandler-backend.azurewebsites.net/api/Accounts',
            {
              method: 'POST',
              headers: {
                Accept: 'text/plain',
                'Content-type': 'application/json',
              },
              body: JSON.stringify(mailOptions),
            }
          )
            .then((res) => res.json())
            .then(async (newAccount) => {
              newList.push(newAccount);
              const newInviteList = eventInput.invitedAccounts.concat(newList);
              let onlyEmail = [];
              newInviteList.forEach((account) => {
                onlyEmail.push(account.email);
              });
              let eventInformation = {
                title: event.title,
                startDate: event.startDate,
                endTime: event.endTime,
                lastDate: event.lastDate,
                location: event.location,
                description: event.description,
                eventType: event.eventType,
                maxParticipants: event.maxParticipants,
                invitedAccounts: onlyEmail,
                id: event.id,
                imageURL: changedImageUrl,
                themeType: event.themeType,
                active: event.active,
                openInvitation: event.openInvitation,
                canBringGuest: event.canBringGuest,
                numberOfGuests: event.numberOfGuests,
                hasSentReminder: event.hasSentReminder,
                recurringEventId: event.recurringEventId,
                createdBy: event.createdBy,
                showFoodPref: event.showFoodPref,
                uploadedFiles: event.uploadedFiles,
              };

              await fetch(
                /*'https://localhost:7038/api/Events'*/ 'https://eventhandler-backend.azurewebsites.net/api/Events',
                {
                  method: 'PUT',
                  headers: {
                    Accept: 'text/plain',
                    'Content-type': 'application/json',
                  },
                  body: JSON.stringify(eventInformation),
                }
              ).then((res) => {
                if (res.ok) {
                  if (!!uploadedFile) {
                    deleteOldImageFile(
                      (
                        event.title.replace(/[^A-Z0-9]/gi, '') +
                        event.startDate.replace(
                          /[&\/\\#,+()$~%.'":*?<>{}]/g,
                          ''
                        )
                      ).toLowerCase()
                    );
                    uploadFile(
                      (
                        event.title.replace(/[^A-Z0-9]/gi, '') +
                        event.startDate.replace(
                          /[&\/\\#,+()$~%.'":*?<>{}]/g,
                          ''
                        )
                      ).toLowerCase()
                    );
                  }
                }
                return res.json();
              });
            });
        } else {
          let onlyEmail = [];
          eventInput.invitedAccounts.forEach((account) => {
            onlyEmail.push(account.email);
          });
          const combinedArray = onlyEmail.concat(eventInput.newlyAddedInvites);
          let eventInformation = {
            title: event.title,
            startDate: event.startDate,
            endTime: event.endTime,
            lastDate: event.lastDate,
            location: event.location,
            description: event.description,
            eventType: event.eventType,
            maxParticipants: event.maxParticipants,
            invitedAccounts: combinedArray,
            id: event.id,
            imageURL: changedImageUrl,
            themeType: event.themeType,
            active: event.active,
            openInvitation: event.openInvitation,
            canBringGuest: event.canBringGuest,
            numberOfGuests: event.numberOfGuests,
            hasSentReminder: event.hasSentReminder,
            recurringEventId: event.recurringEventId,
            createdBy: event.createdBy,
            showFoodPref: event.showFoodPref,
            uploadedFiles: event.uploadedFiles,
          };
          fetch(
            /*'https://localhost:7038/api/Events'*/ 'https://eventhandler-backend.azurewebsites.net/api/Events',
            {
              method: 'PUT',
              headers: {
                Accept: 'text/plain',
                'Content-type': 'application/json',
              },
              body: JSON.stringify(eventInformation),
            }
          ).then((res) => {
            if (res.ok) {
              deleteOldImageFile(
                (
                  event.title.replace(/[^A-Z0-9]/gi, '') +
                  event.startDate.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
                ).toLowerCase()
              );
              if (!!uploadedFile) {
                uploadFile(
                  (
                    event.title.replace(/[^A-Z0-9]/gi, '') +
                    event.startDate.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
                  ).toLowerCase()
                );
              }
            }
            return res.json();
          });
        }
      })
    );
  };

  //Called when editing an event with no new invites
  const editEventNoNewInvites = async (event) => {
    let invitesOnlyEmail = [];
    let changedImageUrl = props.event.imageURL;
    if (!!uploadedFile) {
      changedImageUrl = `https://hiqeventhandlerstorage.blob.core.windows.net/${(
        event.title.replace(/[^A-Z0-9]/gi, '') +
        event.startDate.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
      ).toLowerCase()}/${uploadedFile.name}`;
    }
    eventInput.invitedAccounts.forEach((invites) => {
      invitesOnlyEmail.push(invites.email);
    });
    let eventInformation = {
      title: event.title,
      startDate: event.startDate,
      endTime: event.endTime,
      lastDate: event.lastDate,
      location: event.location,
      description: event.description,
      eventType: event.eventType,
      maxParticipants: event.maxParticipants,
      invitedAccounts: invitesOnlyEmail,
      id: event.id,
      imageURL: changedImageUrl,
      themeType: event.themeType,
      active: event.active,
      openInvitation: event.openInvitation,
      canBringGuest: event.canBringGuest,
      numberOfGuests: event.numberOfGuests,
      hasSentReminder: event.hasSentReminder,
      recurringEventId: event.recurringEventId,
      createdBy: event.createdBy,
      showFoodPref: event.showFoodPref,
      uploadedFiles: event.uploadedFiles,
    };

    const res = await fetch(
      /*'https://localhost:7038/api/Events'*/ 'https://eventhandler-backend.azurewebsites.net/api/Events',
      {
        method: 'PUT',
        headers: {
          Accept: 'text/plain',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(eventInformation),
      }
    ).then((res) => {
      if (res.ok) {
        deleteOldImageFile(
          (
            event.title.replace(/[^A-Z0-9]/gi, '') +
            event.startDate.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
          ).toLowerCase()
        );
        if (!!uploadedFile) {
          uploadFile(
            (
              event.title.replace(/[^A-Z0-9]/gi, '') +
              event.startDate.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
            ).toLowerCase()
          );
        }
      }
      return res.json();
    });
  };

  //Called when the user wants to delete an event.
  const deleteEvent = (id) => {
    if (
      props.event.invitedAccounts.length > 0 ||
      props.event.acceptedAccounts.length > 0
    ) {
      fetch(
        'https://eventhandler-backend.azurewebsites.net/api/Events/SendNotification',
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify({
            eventId: id,
            type: 'delete',
            useCustomMailFormat: false,
            customEmailSubject:
              'Eventet ' + eventInput.title + ' har ställts in.',
            customEmailBody: '',
          }),
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((resJson) => {
          if (!!resJson) {
            fetch(
              `https://eventhandler-backend.azurewebsites.net/api/Events/${id}`,
              {
                method: 'DELETE',
                headers: {
                  accept: 'text/plain',
                },
              }
            ).then((res) => {
              props.fetchEvents();
              props.fetchAccounts();
              if (res.ok) {
                const timeOut = setTimeout(() => {
                  // deleteEventEmail();
                  setShowDeleteMessage(false);
                  handleClose();
                }, 1000);
                deleteFolder(
                  (
                    props.event.title.replace(/[^A-Z0-9]/gi, '') +
                    props.event.startDate.replace(
                      /[&\/\\#,+()$~%.'":*?<>{}]/g,
                      ''
                    )
                  ).toLowerCase()
                );
                return () => clearTimeout(timeOut);
              }
            });
          }
        });
    } else {
      fetch(`https://eventhandler-backend.azurewebsites.net/api/Events/${id}`, {
        method: 'DELETE',
        headers: {
          accept: 'text/plain',
        },
      }).then((res) => {
        props.fetchEvents();
        props.fetchAccounts();
        if (res.ok) {
          deleteFolder(
            (
              props.event.title.replace(/[^A-Z0-9]/gi, '') +
              props.event.startDate.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
            ).toLowerCase()
          );
          const timeOut = setTimeout(() => {
            // deleteEventEmail();
            setShowDeleteMessage(false);
            handleClose();
          }, 1000);
          return () => clearTimeout(timeOut);
        }
      });
    }
  };

  //Called when a user wants to delete an event and all the other events in the event chain.
  const deleteEventChain = (recurringEventId) => {
    let chainToBeDeleted = [];
    props.fetchedEvents.forEach((event) => {
      if (event.recurringEventId === recurringEventId) {
        chainToBeDeleted.push(event);
      }
    });
    try {
      chainToBeDeleted.forEach((event) => {
        fetch(
          `https://eventhandler-backend.azurewebsites.net/api/Events/${event.id}`,
          {
            method: 'DELETE',
            headers: {
              accept: 'text/plain',
            },
          }
        ).then((res) => {
          if (res.ok) {
            deleteFolder(
              (
                event.title.replace(/[^A-Z0-9]/gi, '') +
                event.startDate.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
              ).toLowerCase()
            );
            const timeOut = setTimeout(() => {
              // deleteEventEmail();
              setShowDeleteMessage(false);
              handleClose();
            }, 1000);
            return () => clearTimeout(timeOut);
          }
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  //Called when the users has checked that they want to send an email to the invited users (invite or notify regarding changes)
  const submitEmail = async (e) => {
    if (
      notifyUsersAboutChange &&
      eventInput.active &&
      !previouslyPrivateEvent
    ) {
      fetch(
        'https://eventhandler-backend.azurewebsites.net/api/Events/SendNotification',
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify({
            eventId: eventInput.id,
            type: 'update',
            useCustomMailFormat: emailBody === '' ? false : true,
            customEmailSubject: 'Förändring av ' + eventInput.title,
            customEmailBody: emailBody,
          }),
        }
      )
        .then((res) => res.json())
        .catch((error) => {
          console.error(error);
        });
    } else if (
      notifyUsersAboutChange &&
      eventInput.active &&
      previouslyPrivateEvent
    ) {
      fetch(
        'https://eventhandler-backend.azurewebsites.net/api/Events/SendNotification',
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify({
            eventId: eventInput.id,
            type: 'invite',
            useCustomMailFormat: emailBody === '' ? false : true,
            customEmailSubject: 'Inbjudan till ' + eventInput.title,
            customEmailBody: emailBody,
          }),
        }
      )
        .then((res) => res.json())
        .catch((error) => {
          console.error(error);
        });
    }
  };

  //Called when input fields are filled out. Change the field of eventInput with name that corresponds with e.target.name
  const handleInputChange = (e) => {
    e.preventDefault();
    setEventInput((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  //Called when the switch for "recurring event" is checked.
  const handleOccurenceChange = () => {
    setReoccuringEvent((prevState) => ({
      ...prevState,
      isReoccuring: !reoccuringEvent.isReoccuring,
    }));
    setEventInput((prevState) => ({
      ...prevState,
      recurringEventId: '',
    }));
  };

  //Called when the switch for "open event" is checked
  const handleOpenSignUp = () => {
    setEventInput((prevState) => ({
      ...prevState,
      openInvitation: !eventInput.openInvitation,
    }));
  };

  //Called when the switch for "can bring guest" is checked
  const handleCanBringGuest = () => {
    setEventInput((prevState) => ({
      ...prevState,
      canBringGuest: !eventInput.canBringGuest,
    }));
  };

  //Called when the switch for "active event" is checked
  const handleActiveChange = () => {
    setEventInput((prevState) => ({
      ...prevState,
      active: !eventInput.active,
    }));
  };

  //Called when the switch for "send email" is checked
  const handleSendEmail = () => {
    setSendEmail(!sendEmail);
  };

  //Called when the switch for "include food preference" is checked
  const handleShowFoodPref = () => {
    setEventInput((prevState) => ({
      ...prevState,
      showFoodPref: !eventInput.showFoodPref,
    }));
  };

  //Called when the switch for "can bring guest" is checked
  const changeOccurrence = (e) => {
    e.preventDefault();
    setReoccuringEvent((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  //Called when the frequency of a recurring event is changed.
  const changeOccurrenceFrequency = (choice) => {
    setReoccuringEvent((prevState) => ({
      ...prevState,
      frequency: choice.value,
    }));
  };

  //Called when entering a custom email to invite when creating an event
  const addToCustomList = () => {
    if (props.type === 'create') {
      const newInviteList = [
        ...eventInput.invitedAccounts,
        ...addedUsersToInvite,
      ];
      const uniqueNewInviteList = [...new Set(newInviteList)];
      setEventInput((prevState) => ({
        ...prevState,
        invitedAccounts: uniqueNewInviteList,
      }));
      setShowAddedToList(true);
      const timeOut = setTimeout(() => {
        setShowAddedToList(false);
      }, 3000);
      return () => clearTimeout(timeOut);
    } else if (props.type === 'edit') {
      addToNewCustomList();
    }
  };

  //Called when entering a custom email to invite when editing an event
  const addToNewCustomList = () => {
    const invitedOnlyEmail = [];
    props.event.invitedAccounts.forEach((account) => {
      invitedOnlyEmail.push(account.email);
    });
    const newInviteList = [
      ...eventInput.newlyAddedInvites,
      ...addedUsersToInvite,
    ];
    const uniqueNewInviteList = [...new Set(newInviteList)];
    const filteredList = uniqueNewInviteList.filter(
      (e) => !invitedOnlyEmail.includes(e)
    );
    setEventInput((prevState) => ({
      ...prevState,
      newlyAddedInvites: filteredList,
    }));
    setShowAddedToList(true);
    const timeOut = setTimeout(() => {
      setShowAddedToList(false);
    }, 3000);
    return () => clearTimeout(timeOut);
  };

  //Handling custom email invite
  const inviteCustomEmail = (e) => {
    e.preventDefault();
    if (props.type === 'create') {
      if (
        customEmailInvite !== '' &&
        customEmailInvite.includes('@') &&
        customEmailInvite.includes('.')
      ) {
        const copyOfInviteList = eventInput.invitedAccounts;
        copyOfInviteList.push(customEmailInvite);
        const uniqueInviteList = [...new Set(copyOfInviteList)];
        setEventInput((prevState) => ({
          ...prevState,
          invitedAccounts: uniqueInviteList,
        }));
        setShowCustomEmailInvited(true);
        const timeOut = setTimeout(() => {
          setShowCustomEmailInvited(false);
        }, 3000);
        return () => clearTimeout(timeOut);
      } else {
        setShowIncorrectEmail(true);
        const timeOut = setTimeout(() => {
          setShowIncorrectEmail(false);
        }, 3000);
        return () => clearTimeout(timeOut);
      }
    } else if (props.type === 'edit') {
      if (
        customEmailInvite !== '' &&
        customEmailInvite.includes('@') &&
        customEmailInvite.includes('.')
      ) {
        if (
          !eventInput.newlyAddedInvites.includes(customEmailInvite) &&
          props.event.invitedAccounts.filter(
            (e) => e.email === customEmailInvite
          ).length === 0
        ) {
          const newlyAddedInvitesCopy = eventInput.newlyAddedInvites;
          newlyAddedInvitesCopy.push(customEmailInvite);
          setEventInput((prevState) => ({
            ...prevState,
            newlyAddedInvites: newlyAddedInvitesCopy,
          }));
          setShowCustomEmailInvited(true);
          const timeOut = setTimeout(() => {
            setShowCustomEmailInvited(false);
          }, 3000);
          return () => clearTimeout(timeOut);
        }
      } else {
        setShowIncorrectEmail(true);
        const timeOut = setTimeout(() => {
          setShowIncorrectEmail(false);
        }, 3000);
        return () => clearTimeout(timeOut);
      }
    }
  };

  //Adds a user when the checkbox is checked to a list of added users to invite.
  const handleCheckBoxChange = (index) => (e) => {
    let userEmail = e.target.id;
    let copyOfUserList = [...mailingList];
    if (e.target.checked) {
      setAddedUsersToInvite((oldArray) => [...oldArray, userEmail]);
      copyOfUserList[index].checked = true;
      setMailingList(copyOfUserList);
    } else {
      setAddedUsersToInvite(
        addedUsersToInvite.filter((email) => email !== userEmail)
      );
      copyOfUserList[index].checked = false;
      setMailingList(copyOfUserList);
    }
  };

  //Checks all the users in the list of users to invite.
  const inviteAllUsers = () => {
    if (inviteListFilters.length === 0) {
      let fetchedAccountsEmails = [];
      accountsData
        .filter((user) => !user.email.includes('guest.se'))
        .forEach((account) => {
          fetchedAccountsEmails.push(account.email);
        });
      if (addedUsersToInvite.length !== fetchedAccountsEmails.length) {
        setAddedUsersToInvite(fetchedAccountsEmails);
      } else {
        setAddedUsersToInvite([]);
      }
    } else {
      let filteredList = [];
      accountsData
        .filter(
          (user) =>
            !user.email.includes('guest.se') &&
            (inviteListFilters.includes(user.teamName) ||
              inviteListFilters.includes(user.employmentCity))
        )
        .forEach((account) => {
          filteredList.push(account.email);
        });
      if (addedUsersToInvite.length !== filteredList.length) {
        setAddedUsersToInvite(filteredList);
      } else {
        setAddedUsersToInvite([]);
      }
    }
  };

  //Select users based on team or employmentcity
  const inviteTeamMembers = () => {
    let teamNameMembers = accountsData.filter(
      (user) =>
        inviteListFilters.includes(user.teamName) ||
        inviteListFilters.includes(user.employmentCity)
    );
    let emailList = [];
    teamNameMembers.forEach((account) => {
      emailList.push(account.email);
    });
    setAddedUsersToInvite(emailList);
  };

  //Called on checkbox change for invite list
  const handleInviteChange = (teamName, checked) => {
    if (checked) {
      setInviteListFilters((oldArray) => [...oldArray, teamName]);
    } else {
      setInviteListFilters(
        inviteListFilters.filter((item) => item !== teamName)
      );
    }
  };

  //Called when the user removes a user from the invite list.
  const removeUserFromInviteList = (index) => {
    if (props.type === 'create') {
      let customInviteListCopy = eventInput.invitedAccounts;
      customInviteListCopy.splice(index, 1);
      setEventInput((prevState) => ({
        ...prevState,
        invitedAccounts: customInviteListCopy,
      }));
    } else if (props.type === 'edit') {
      let customInviteListCopy = eventInput.newlyAddedInvites;
      customInviteListCopy.splice(index, 1);
      setEventInput((prevState) => ({
        ...prevState,
        newlyAddedInvites: customInviteListCopy,
      }));
    }
  };

  //Called when filling the input for location
  const choseLocation = (value) => {
    setEventInput((prevState) => ({
      ...prevState,
      location: value,
    }));
  };

  //Called when changing event location
  const choseEventType = (value) => {
    setEventInput((prevState) => ({
      ...prevState,
      eventType: value,
    }));
  };

  //Called when changing event theme
  const choseEventTheme = (value) => {
    setEventInput((prevState) => ({
      ...prevState,
      themeType: Number(value),
    }));
  };

  //Called when the users has selected that they want to invite users
  const changeInvite = () => {
    setInvite(!invite);
    setShowPreview(!showPreview);
  };

  //Called when the user wants to see a preview of their email.
  const changePreview = () => {
    setShowPreview(!showPreview);
  };

  //Called if the user wants to replace the template invitation with a custom email.
  const changeCustomEmailInvite = (value) => {
    setCustomEmailInvite(value);
  };

  //Called when creating an event which is going to be a recurring event. This will create copies of the original event with different event times.
  const SubmitReoccuringEvent = () => {
    const startDate = new Date(eventInput.startDate.substring(0, 10));
    const endTime = new Date(eventInput.endTime.substring(0, 10));
    const endDate = new Date(reoccuringEvent.lastOccuringDate.substring(0, 10));

    const startDateInMs = startDate.getTime();
    const endTimeInMs = endTime.getTime();
    const endDateInMs = endDate.getTime();
    const changingOsaDateInMs =
      startDateInMs - new Date(eventInput.lastDate).getTime();

    let nextDateInMs = startDateInMs;
    let nextEndTimeInMs = endTimeInMs;

    let frequencyInMs = '';
    switch (reoccuringEvent.frequency) {
      case 'daily':
        frequencyInMs = 86400000;
        break;
      case 'weekly':
        frequencyInMs = 604800000;
        break;
      case 'monthly':
        frequencyInMs = 2629800000;
        break;
      default:
        frequencyInMs = 0;
        break;
    }
    nextDateInMs = nextDateInMs + frequencyInMs;
    nextEndTimeInMs = nextEndTimeInMs + frequencyInMs;
    while (nextDateInMs <= endDateInMs) {
      let nextOsaDateInMs = nextDateInMs - changingOsaDateInMs;
      let newEventDate = new Date(nextDateInMs);
      let newEventEndTime = new Date(nextEndTimeInMs);

      let dd = newEventDate.getDate().toString().padStart(2, '0');
      let mm = (newEventDate.getMonth() + 1).toString().padStart(2, '0');
      let yyyy = newEventDate.getFullYear();

      let newOsaEvent = new Date(nextOsaDateInMs);
      let ddOsa = newOsaEvent.getDate().toString().padStart(2, '0');
      let mmOsa = (newOsaEvent.getMonth() + 1).toString().padStart(2, '0');
      let yyyyOsa = newOsaEvent.getFullYear().toString();

      let ddEnd = newEventEndTime.getDate().toString().padStart(2, '0');
      let mmEnd = (newEventEndTime.getMonth() + 1).toString().padStart(2, '0');
      let yyyyEnd = newEventEndTime.getFullYear();

      let newEventDateAsString = String(
        yyyy + '-' + mm + '-' + dd + eventInput.startDate.substring(10)
      );
      let newEventEndTimeAsString = String(
        yyyyEnd + '-' + mmEnd + '-' + ddEnd + eventInput.endTime.substring(10)
      );
      let newOsaEventAsString = String(
        yyyyOsa + '-' + mmOsa + '-' + ddOsa + eventInput.lastDate.substring(10)
      );

      createEvent({
        title: eventInput.title,
        description: eventInput.description,
        location: eventInput.location,
        startDate: newEventDateAsString,
        endTime: newEventEndTimeAsString,
        lastDate: newOsaEventAsString,
        maxParticipants: eventInput.maxParticipants,
        invitedAccounts: eventInput.invitedAccounts,
        eventType: eventInput.eventType,
        imageURL: !!uploadedFile
          ? `https://hiqeventhandlerstorage.blob.core.windows.net/${(
              eventInput.title.replace(/[^A-Z0-9]/gi, '') +
              eventInput.startDate.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
            ).toLowerCase()}/${uploadedFile.name}`
          : eventInput.imageURL,
        themeType: eventInput.themeType,
        active: eventInput.active,
        openInvitation: eventInput.openInvitation,
        canBringGuest: eventInput.canBringGuest,
        numberOfGuests: eventInput.numberOfGuests,
        hasSentReminder: eventInput.hasSentReminder,
        recurringEventId: eventInput.recurringEventId,
        createdBy: eventInput.createdBy,
        showFoodPref: eventInput.showFoodPref,
        uploadedFiles: eventInput.uploadedFiles,
      });
      nextDateInMs = nextDateInMs + frequencyInMs;
      nextEndTimeInMs = nextEndTimeInMs + frequencyInMs;
      nextOsaDateInMs = nextDateInMs - changingOsaDateInMs;
    }
  };

  //With the data from eventInput, makes a call to the API, creating the event.
  const createEvent = async (event) => {
    const newFetchedAccounts = await fetch(
      /*'https://localhost:7038/api/Accounts'*/ 'https://eventhandler-backend.azurewebsites.net/api/Accounts',
      {
        method: 'GET',
        headers: {
          Accept: 'text/plain',
        },
      }
    ).then((res) => res.json());
    Promise.all(
      event.invitedAccounts.map(async (account) => {
        const mailOptions = {
          email: account,
          foodPreference: '',
          isHiqAccount: false,
          teamName: 'No team lead',
          employmentCity: 'No employment city',
          teamId: 'No team id',
        };
        if (
          newFetchedAccounts.filter((e) => e.email === account).length === 0
        ) {
          await fetch(
            /*'https://localhost:7038/api/Accounts'*/ 'https://eventhandler-backend.azurewebsites.net/api/Accounts',
            {
              method: 'POST',
              headers: {
                Accept: 'text/plain',
                'Content-type': 'application/json',
              },
              body: JSON.stringify(mailOptions),
            }
          )
            .then((res) => res.json())
            .catch((error) => {
              console.error(error);
            });
        }
      })
    ).then(() => {
      let eventInformation = {
        title: event.title,
        startDate: event.startDate,
        endTime: event.endTime,
        lastDate: event.lastDate,
        location: event.location,
        description: event.description,
        eventType: event.eventType,
        maxParticipants: event.maxParticipants,
        invitedAccounts: event.invitedAccounts,
        imageURL: !!uploadedFile
          ? `https://hiqeventhandlerstorage.blob.core.windows.net/${(
              event.title.replace(/[^A-Z0-9]/gi, '') +
              event.startDate.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
            ).toLowerCase()}/${uploadedFile.name}`
          : event.imageURL,
        themeType: event.themeType,
        active: event.active,
        openInvitation: event.openInvitation,
        canBringGuest: event.canBringGuest,
        numberOfGuests: event.numberOfGuests,
        hasSentReminder: event.hasSentReminder,
        recurringEventId: event.recurringEventId,
        createdBy: event.createdBy,
        showFoodPref: event.showFoodPref,
        uploadedFiles: event.uploadedFiles,
      };
      fetch(
        /*'https://localhost:7038/api/Events'*/ 'https://eventhandler-backend.azurewebsites.net/api/Events',
        {
          method: 'POST',
          headers: {
            Accept: 'text/plain',
            'Content-type': 'application/json',
          },
          body: JSON.stringify(eventInformation),
        }
      )
        .then((res) => {
          if (!!uploadedFile && res.ok) {
            uploadFile(
              (
                event.title.replace(/[^A-Z0-9]/gi, '') +
                event.startDate.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
              ).toLowerCase()
            );
          }
          return res.json();
        })
        .then(async (data) => {
          setRoutedEvent(data);
          if (sendEmail && eventInput.active) {
            fetch(
              'https://eventhandler-backend.azurewebsites.net/api/Events/SendNotification',
              {
                method: 'POST',
                headers: {
                  'Content-type': 'application/json',
                },
                body: JSON.stringify({
                  eventId: data.id,
                  type: 'invite',
                  useCustomMailFormat: emailBody === '' ? false : true,
                  customEmailSubject: 'Inbjudan till ' + eventInput.title,
                  customEmailBody:
                    emailBody +
                    `<div><br><a href="https://event.hiqsyd.se/signup/${data.id}">Länk till eventet</a></div>`,
                }),
              }
            )
              .then((res) => res.json())
              .catch((error) => {
                console.error(error);
              });
          }
        });
    });
  };

  //Checks if the email is added to the invite list.
  const checkIfAddedToList = (email) => {
    if (addedUsersToInvite.includes(email)) {
      return true;
    } else {
      return false;
    }
  };

  //Called when the users wants to edit their invite
  const handleEditInvite = () => {
    setShowEditInvite(!showEditInvite);
    setShowPreview(false);
    document.getElementById('showEmailPreview').checked = false;
  };

  //Created HTML data to be used in the custom email invite.
  const createHTMLFromEmailBody = (string) => {
    if (string !== '') {
      var parse = require('html-react-parser');
      return parse(string);
    } else {
      return <p>Emailbody is empty</p>;
    }
  };

  const selectThemeSelect = () => {
    switch (eventInput.themeType) {
      case 1:
        return 'mintThemeSelected';
      case 2:
        return 'peachThemeSelected';
      case 3:
        return 'pinkLightThemeSelected';
      case 4:
        return 'purpleThemeSelected';
      default:
        return '';
    }
  };

  //Adds or subtracts hours to an event.
  const addHours = (date, hours) => {
    date.setHours(date.getHours() + hours);
    let day = date.getDate().toString().padStart(2, '0');
    return (
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      day +
      'T' +
      date.getHours().toString().padStart(2, '0') +
      ':' +
      date.getMinutes().toString().padStart(2, '0')
    );
  };

  //Handles notifyUsersAboutChange, if notifyUsersAboutChange = true, the users will get an email regarding the changes of the event.
  const handleNotifyUsersAboutChange = () => {
    setNotifyUsersAboutChange(!notifyUsersAboutChange);
  };

  //Closes delete-confirmation modal and calls function to delete event
  const deleteThisEvent = () => {
    setShowDeleteModal(false);
    setShowDeleteMessage(true);
    deleteEvent(props.event.id);
  };

  //Closes delete-confirmation modal (when there is a recurring event chain) and calls function to delete event and all events in chain
  const deleteAllEventsInChainHandler = (recurringEventId) => {
    setShowDeleteModal(false);
    setShowDeleteMessage(true);
    deleteEventChain(recurringEventId);
  };

  const [currentTheme, setCurrentTheme] = useState('standardModal');

  //Sets theme during edit.
  useEffect(() => {
    if (props.type === 'edit' && props.event !== null) {
      switch (eventInput.themeType) {
        case 0:
          setCurrentTheme('standardModal');
          break;
        case 1:
          setCurrentTheme('mintModal');
          break;
        case 2:
          setCurrentTheme('peachModal');
          break;
        case 3:
          setCurrentTheme('pinkLightModal');
          break;
        case 4:
          setCurrentTheme('purpleModal');
          break;
        default:
          setCurrentTheme('standardModal');
          break;
      }
    }
  }, [eventInput.themeType]);

  //State for uploaded file
  const onFileChange = (event) => {
    setUploadedFile(event.target.files[0]);
  };

  //Upload file to blob storage.
  const uploadFile = async (foldername) => {
    // let storageAccountName = 'hiqeventhandlerstorage';
    // let sasToken = 'sv=2021-06-08&ss=b&srt=sco&sp=rwlaciytfx&se=2023-03-02T23:20:48Z&st=2023-02-23T15:20:48Z&spr=https&sig=PogR34hR2kGKmrgBjn9Rkkqye8QCsTvClRIJW8dLYhs%3D';
    let blobSasUrl =
      'https://hiqeventhandlerstorage.blob.core.windows.net/?sv=2021-12-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-06-26T17:25:00Z&st=2023-04-06T09:25:00Z&spr=https&sig=1judcVXwBcq3DLAh2W4ZGAV%2FBOxXAyFoYfYuVcL1zJw%3D';

    const blobService = new BlobServiceClient(blobSasUrl);

    const containerClient = blobService.getContainerClient(foldername);
    await containerClient.createIfNotExists({
      access: 'container',
    });

    const blobClient = containerClient.getBlockBlobClient(uploadedFile.name);
    const options = { blobHTTPHeaders: { blobContentType: uploadedFile.type } };

    await blobClient.uploadBrowserData(uploadedFile, options);
  };

  //Delete old file from blob storage if a new image is uploaded during edit.
  const deleteOldImageFile = async (foldername) => {
    if (!!props.event.imageURL && props.event.imageURL !== '') {
      const words = props.event.imageURL.split('/');
      const oldFileName = words[words.length - 1];

      let blobSasUrl =
        'https://hiqeventhandlerstorage.blob.core.windows.net/?sv=2021-12-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-06-26T17:25:00Z&st=2023-04-06T09:25:00Z&spr=https&sig=1judcVXwBcq3DLAh2W4ZGAV%2FBOxXAyFoYfYuVcL1zJw%3D';
      const blobService = new BlobServiceClient(blobSasUrl);

      const containerClient = blobService.getContainerClient(foldername);

      // include: Delete the base blob and all of its snapshots.
      // only: Delete only the blob's snapshots and not the blob itself.
      const options = {
        deleteSnapshots: 'include', // or 'only'
      };

      // Create blob client from container client
      const blockBlobClient = await containerClient.getBlockBlobClient(
        oldFileName
      );

      await blockBlobClient.deleteIfExists(options);
    }
  };

  //Deletes the blobstorage folder when deleting an event. This is to save space.
  const deleteFolder = async (foldername) => {
    // let storageAccountName = 'hiqeventhandlerstorage';
    // let sasToken = 'sv=2021-06-08&ss=b&srt=sco&sp=rwlaciytfx&se=2023-03-02T23:20:48Z&st=2023-02-23T15:20:48Z&spr=https&sig=PogR34hR2kGKmrgBjn9Rkkqye8QCsTvClRIJW8dLYhs%3D';
    let blobSasUrl =
      'https://hiqeventhandlerstorage.blob.core.windows.net/?sv=2021-12-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2023-06-26T17:25:00Z&st=2023-04-06T09:25:00Z&spr=https&sig=1judcVXwBcq3DLAh2W4ZGAV%2FBOxXAyFoYfYuVcL1zJw%3D';
    const blobService = new BlobServiceClient(blobSasUrl);

    const containerClient = blobService.getContainerClient(foldername);
    await containerClient.deleteIfExists({
      access: 'container',
    });
  };

  //Generates a recurringEventId for the event
  const generateRecurringEventId = () => {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 12) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  //Return chain size
  const returnNumberOfRecurringEvents = (recurringEventId) => {
    let eventChain = [];
    props.fetchedEvents.forEach((event) => {
      if (event.recurringEventId === recurringEventId) {
        eventChain.push(event);
      }
    });
    return eventChain.length;
  };

  const returnEmailPreview = () => {
    if (emailBody === '') {
      return (
        <>
          <h2>Nuvarande mail</h2>
          <div
            style={{
              backgroundColor: 'white',
              border: '1px solid black',
              width: '75%',
              padding: '5%',
            }}
          >
            <div style={{ textAlign: 'left' }}>
              <p>Hej,</p>
              <p>
                Du har blivit inbjuden till <strong>{eventInput.title}</strong>!
                🥳
              </p>
              <Image
                fluid
                src={
                  eventInput.imageURL !== ''
                    ? eventInput.imageURL
                    : 'https://www.triton-partners.com/media/2983/hiq-logo-jpg.jpg?mode=max&height=225&saturation=0'
                }
                width='125'
                height='150'
              />
              <div style={{ textAlign: 'left' }}>
                <p>{eventInput.description}</p>
                <p>
                  <strong>Var? </strong>
                  {eventInput.location}
                </p>
                <p>
                  <strong>När? </strong>
                  {eventInput.startDate.substring(0, 10) +
                    ' : ' +
                    eventInput.startDate.substring(11)}
                </p>
                <p>
                  <strong>Anmälan?</strong> Anmäl dig senast den{' '}
                  {eventInput.lastDate.substring(0, 10) +
                    ' : ' +
                    eventInput.lastDate.substring(11)}
                  .
                </p>
                <p>
                  Mer information och anmälan hittar du{' '}
                  <strong
                    style={{
                      color: 'blue',
                      textDecoration: 'underline',
                    }}
                  >
                    HÄR
                  </strong>
                </p>
                <br></br>
                <p>/Vibe Masters</p>
                <small>
                  Detta mail är autogenererat, vänligen kontakta{' '}
                  <a href='mailto:HiQ.South.Vibe.Masters@hiq.se'>
                    HiQ.South.Vibe.Masters@hiq.se
                  </a>{' '}
                  om du har några frågor.
                </small>
              </div>
            </div>
            {!showEditInvite && !showPreview && (
              <Button
                size='sm'
                variant='info'
                className='modalLightBlueButton mt-3'
                onClick={handleEditInvite}
              >
                Redigera inbjudan
              </Button>
            )}
          </div>
        </>
      );
    } else {
      return (
        <>
          <h2>Nuvarande mail</h2>
          <div
            style={{
              backgroundColor: 'white',
              border: '1px solid black',
              width: '70%',
              marginRight: 'auto',
              marginLeft: 'auto',
              paddingTop: '5%',
              paddingLeft: '10%',
            }}
          >
            <h1>{eventInput.title}</h1>
            <Image
              src={
                eventInput.imageURL !== ''
                  ? eventInput.imageURL
                  : 'https://www.triton-partners.com/media/2983/hiq-logo-jpg.jpg?mode=max&height=225&saturation=0'
              }
              width='250'
              height='300'
            />
            <div
              style={{
                textAlign: 'left',
                paddingBottom: '10%',
                paddingRight: '10%',
              }}
            >
              <div className='customEmailBody'>
                {createHTMLFromEmailBody(emailBody)}
              </div>
              {!showEditInvite && showPreview && (
                <Button
                  size='sm'
                  variant='info'
                  className='modalLightBlueButton'
                  onClick={handleEditInvite}
                >
                  Redigera inbjudan
                </Button>
              )}
            </div>
          </div>
        </>
      );
    }
  };

  const returnCreationMessage = () => {
    return (
      <>
        <Row>
          {props.type === 'create' && eventInput !== 'null' && (
            <Col>
              <Collapse in={showSuccessMessage}>
                <h4 style={{ color: 'green' }}>
                  {eventInput.title} håller på att skapas
                </h4>
              </Collapse>
            </Col>
          )}

          {props.type === 'edit' && props.event !== null && (
            <Col>
              <Collapse in={showSuccessMessage}>
                <h4 style={{ color: 'green' }}>
                  Dina ändringar för {eventInput.title} har nu sparats
                </h4>
              </Collapse>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <Collapse in={showErrorMessage}>
              <h4 style={{ color: 'red' }}>Alla fält måste vara ifyllda</h4>
            </Collapse>
          </Col>
        </Row>
        <Row>
          <Col>
            <Collapse in={showWrongDateInput}>
              <h4 style={{ color: 'red' }}>
                Se till att alla datum och tider är korrekta
              </h4>
            </Collapse>
          </Col>
        </Row>
        {props.event !== null && props.type === 'edit' && (
          <Collapse in={showDeleteMessage}>
            <h4 style={{ color: 'orange' }}>{props.event.title} tas bort</h4>
          </Collapse>
        )}
      </>
    );
  };

  return (
    <>
      <Modal
        animation={false}
        className={
          'modal left animate__animated animate__fadeInRightBig ' + currentTheme
        }
        show={props.show}
        onHide={handleClose}
      >
        <Modal.Header>
          <Row style={{ float: 'left' }}>
            <Col>
              <CloseButton
                onClick={handleClose}
                style={{ marginTop: 'auto', marginBottom: 'auto' }}
              />
            </Col>

            {props.type === 'create' && (
              <Col>
                <Modal.Title
                  style={{
                    minWidth: '40vw',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                >
                  Nytt event
                </Modal.Title>
              </Col>
            )}

            {props.type === 'edit' && props.event !== null && (
              <Col>
                <Modal.Title
                  style={{
                    minWidth: '40vw',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                >
                  Redigera event - {props.event.title}
                </Modal.Title>
              </Col>
            )}
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form>
              <Row>
                <Col
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '0.5vh',
                  }}
                  md={12}
                >
                  <Inputfield
                    controlId='formEventName'
                    type='text'
                    label='Titel *'
                    name='title'
                    value={eventInput.title}
                    placeholder='Ex: Afterwork'
                    onChange={handleInputChange}
                  />
                </Col>
              </Row>

              <Row className='mb-3'>
                <Col md>
                  <DateTimeInputfield
                    controlId='formEventDate1'
                    label='Startdatum och tid *'
                    value={dateTimeValue}
                    setEventInput={setEventInput}
                    setDateTimeValue={setDateTimeValue}
                    setDateTimeValueEnd={setDateTimeValueEnd}
                    addHours={addHours}
                    setDateValue={setDateValue}
                    todayOnlyDate={todayOnlyDate}
                    timeType='startDate'
                  />
                </Col>
                <Col md>
                  <DateTimeInputfield
                    controlId='formEventDate2'
                    label='Slutdatum och tid *'
                    value={dateTimeValueEnd}
                    setEventInput={setEventInput}
                    setDateTimeValue={setDateTimeValue}
                    setDateTimeValueEnd={setDateTimeValueEnd}
                    addHours={addHours}
                    setDateValue={setDateValue}
                    todayOnlyDate={todayOnlyDate}
                    timeType='endTime'
                  />
                </Col>
                <Col md>
                  <DateTimeInputfield
                    controlId='formEventDate3'
                    label='Senast anmälan *'
                    value={dateValue}
                    setEventInput={setEventInput}
                    setDateTimeValue={setDateTimeValue}
                    setDateTimeValueEnd={setDateTimeValueEnd}
                    addHours={addHours}
                    setDateValue={setDateValue}
                    todayOnlyDate={todayOnlyDate}
                    timeType='dateTime'
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <ModalInputSwitch
                    controlId='formReoccuringEvent'
                    label='Göra detta till ett återkommande event?'
                    name='reoccuringEvent'
                    onChange={handleOccurenceChange}
                  />
                </Col>

                {reoccuringEvent.isReoccuring && (
                  <>
                    <Col md={3}>
                      <Select
                        name='frequency'
                        onChange={(choice) => changeOccurrenceFrequency(choice)}
                        styles={styles}
                        className='modalInputSelect'
                        defaultValue={selectOptions.filter(
                          (option) => option.value === 'weekly'
                        )}
                        options={selectOptions}
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Group controlId='formLastSignUpDate'>
                        <Form.Control
                          className='modalInput'
                          style={{ textAlign: 'left' }}
                          type='date'
                          name='lastOccuringDate'
                          value={reoccuringEvent.lastOccuringDate}
                          onChange={changeOccurrence}
                          size='sm'
                          min={eventInput.startDate.substring(0, 10)}
                        />
                        <Form.Label>Fram till och med</Form.Label>
                      </Form.Group>
                    </Col>
                  </>
                )}
              </Row>

              <Row>
                <Col
                  style={{ marginLeft: 'auto', marginRight: 'auto' }}
                  md={12}
                  className='mb-3'
                >
                  <Form.Group controlId='formEventLocation'>
                    <Form.Label>Plats *</Form.Label>
                    <AutoSuggestInput
                      dropdownName={
                        eventInput.location === ''
                          ? 'Välj plats'
                          : eventInput.location
                      }
                      placeholder='Skriv in plats'
                      locationSuggestions={locationSuggestions}
                      choseLocation={choseLocation}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col
                  style={{ marginLeft: 'auto', marginRight: 'auto' }}
                  md={12}
                >
                  <Inputfield
                    controlId='formEventDescription'
                    type='text'
                    label='Beskrivning *'
                    name='description'
                    value={eventInput.description}
                    placeholder='Kort beskrivning av eventet'
                    onChange={handleInputChange}
                    as='textarea'
                    rows={3}
                  />
                </Col>
              </Row>

              <Row className='mb-3'>
                <Col md={6}>
                  <Inputfield
                    controlId='formMaxParticipants'
                    type='number'
                    label={
                      eventInput.maxParticipants > 0 ? (
                        <span>Max antal deltagare *</span>
                      ) : (
                        <span style={{ color: 'red' }}>
                          Måste vara fler än 0
                        </span>
                      )
                    }
                    name='maxParticipants'
                    value={eventInput.maxParticipants}
                    placeholder='Kort beskrivning av eventet'
                    onChange={handleInputChange}
                  />
                </Col>
              </Row>

              <Row className={reoccuringEvent.isReoccuring ? 'mb-3' : 'mt-3'}>
                <Col style={{ marginLeft: 'auto', marginRight: 'auto' }} md={6}>
                  <InputSelect
                    className='modalInput'
                    id='eventTypeModal'
                    size='sm'
                    style={{ textAlign: 'left', marginTop: '0px' }}
                    label='Välj Eventtyp'
                    onChange={(e) => choseEventType(e.target.value)}
                    ariaLabel='Select event type'
                    options={eventTypeOptions}
                  />
                </Col>
                <Col style={{ marginLeft: 'auto', marginRight: 'auto' }} md={6}>
                  <InputSelect
                    className={selectThemeSelect() + ' modalInputTheme'}
                    id='eventThemeModal'
                    size='sm'
                    style={{ textAlign: 'left' }}
                    label='Välj Eventtema'
                    onChange={(e) => choseEventTheme(e.target.value)}
                    ariaLabel='Select event theme'
                    options={eventThemeOptions}
                  />
                </Col>
              </Row>

              <Row className='mb-3 mt-3'>
                <Col style={{}} md={6}>
                  <Form.Group className='mb-3' controlId='formEmailImage'>
                    <Form.Label>
                      {props.type === 'create'
                        ? 'Ladda upp en eventbild.'
                        : 'Ändra eventbilden.'}
                    </Form.Label>
                    <Form.Control
                      className='modalInput'
                      style={{ textAlign: 'left' }}
                      type='file'
                      accept='image/*'
                      onChange={(e) => {
                        onFileChange(e);
                      }}
                      size='sm'
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Inputfield
                    controlId='formPasteUrl'
                    type='text'
                    label='Eller klistra in URL...'
                    name='pasteUrl'
                    value={eventInput.imageURL}
                    placeholder='Ex: https://hiq.se/wp-content/uploads/2022/09/hiq.jpg'
                    onChange={(e) => {
                      setEventInput((prevState) => ({
                        ...prevState,
                        imageURL: e.target.value,
                      }));
                    }}
                    size='sm'
                  />
                </Col>
              </Row>

              <Row className='mb-3 mt-3'>
                <Col md={6} xxl={2}>
                  <ModalInputSwitch
                    controlId='formInviteToEvent'
                    label='Bjud in till eventet'
                    name='inviteToEvent'
                    onChange={changeInvite}
                  />
                </Col>

                <Col md={6} xxl={2}>
                  <ModalInputSwitch
                    controlId='formOpenSignUpModal'
                    label='Öppen för anmälan'
                    name='openSignUpModal'
                    onChange={handleOpenSignUp}
                  />
                </Col>

                <Col md={6} xxl={2}>
                  <ModalInputSwitch
                    controlId='formCanBringGuestModal'
                    label='Tillåt +1:or'
                    name='canBringGuestModal'
                    onChange={handleCanBringGuest}
                  />
                </Col>

                <Col md={6} xxl={3}>
                  <ModalInputSwitch
                    controlId='formShowFoodPref'
                    label='Inkludera matpreferenser'
                    name='showFoodPref'
                    onChange={handleShowFoodPref}
                  />
                </Col>

                <Col md={6} xxl={2}>
                  <ModalInputSwitch
                    controlId='formActiveEvent'
                    label='Privat event'
                    name='activeEvent'
                    onChange={handleActiveChange}
                  />
                  {!eventInput.active && (
                    <p>
                      <strong>
                        OBS! Eventet kommer inte vara synligt i listan av event
                        för andra användare
                      </strong>
                    </p>
                  )}
                </Col>
              </Row>

              <Collapse in={eventInput.canBringGuest}>
                <Row className='mb-3'>
                  <Col md={6}>
                    <Inputfield
                      controlId='formNumberOfGuests'
                      type='number'
                      label={
                        eventInput.numberOfGuests > 0 ? (
                          <span>Hur många gäster ska man kunna ta med? *</span>
                        ) : (
                          <span style={{ color: 'red' }}>
                            Måste vara fler än 0
                          </span>
                        )
                      }
                      name='numberOfGuests'
                      value={eventInput.numberOfGuests}
                      onChange={handleInputChange}
                      size='sm'
                    />
                  </Col>
                </Row>
              </Collapse>

              {invite && (
                <>
                  <Row className='mb-5'>
                    {props.type === 'edit' && (
                      <>
                        <Row className='mb-3 mt-3'>
                          <h1>Välj vilka du vill bjuda in</h1>
                        </Row>
                        <div className='filterUserCity'>
                          <Row className='mb-1'>
                            <h2
                              style={{ fontSize: '20px', fontWeight: 'bold' }}
                            >
                              Stad
                            </h2>
                          </Row>
                          <Row className='mb-3'>
                            {!!employmentCityList &&
                              employmentCityList.length > 0 &&
                              employmentCityList.map((city, index) => {
                                return (
                                  <Col md={6} lg={4} xxl={3}>
                                    <Form.Check
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                      }}
                                      key={index}
                                      type='checkbox'
                                      className='m-2'
                                      label={city}
                                      onChange={(e) =>
                                        handleInviteChange(
                                          city,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </Col>
                                );
                              })}
                          </Row>
                        </div>

                        <div className='filterUserTeam'>
                          <Row className='mb-1'>
                            <h2
                              style={{ fontSize: '20px', fontWeight: 'bold' }}
                            >
                              Team
                            </h2>
                          </Row>
                          <Row className='mb-3'>
                            {!!teamList &&
                              teamList.length > 0 &&
                              teamList.map((team, index) => {
                                return (
                                  <Col md={6} lg={4} xxl={3}>
                                    <Form.Check
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                      }}
                                      key={index}
                                      type='checkbox'
                                      className='m-2'
                                      label={team}
                                      onChange={(e) =>
                                        handleInviteChange(
                                          team,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </Col>
                                );
                              })}
                          </Row>
                        </div>
                        <Col md={12}>
                          <div className='boxShadow'>
                            <Form
                              style={{
                                padding: '10px',
                                maxHeight: '25vh',
                                minWidth: '20vw',
                                overflowX: 'hidden',
                              }}
                            >
                              <Row>
                                <Col
                                  md={8}
                                  xs={12}
                                  style={{
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                  }}
                                >
                                  <Form.Control
                                    className='mb-3 modalInput'
                                    style={{ textAlign: 'left' }}
                                    type='email'
                                    name='customEmailInvite'
                                    onChange={(e) =>
                                      changeCustomEmailInvite(e.target.value)
                                    }
                                    placeholder='E-postadress'
                                  ></Form.Control>
                                </Col>

                                <Col
                                  md={4}
                                  xs={12}
                                  style={{
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                  }}
                                >
                                  <Button
                                    variant='outline-dark'
                                    className='roundedButton d-inline-block'
                                    size='sm'
                                    onClick={inviteCustomEmail}
                                  >
                                    Bjud in
                                  </Button>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Collapse in={showCustomEmailInvited}>
                                    <p style={{ color: 'green' }}>
                                      {customEmailInvite} har nu bjudits in
                                    </p>
                                  </Collapse>
                                  <Collapse in={showIncorrectEmail}>
                                    <p style={{ color: 'red' }}>
                                      Fältet får inte vara tomt och måste vara
                                      en giltig E-postadress
                                    </p>
                                  </Collapse>
                                </Col>
                              </Row>

                              <br />
                              <Button
                                style={{ float: 'right' }}
                                variant='outline-dark'
                                className='roundedButton'
                                onClick={inviteAllUsers}
                              >
                                {
                                  'Välj alla' /*addedUsersToInvite.length === accountsData.length ? 'Avmarkera alla' : 'Välj alla'*/
                                }
                              </Button>
                              {accountsData !== null &&
                              inviteListFilters.length > 0
                                ? accountsData
                                    .filter(
                                      (user) =>
                                        !user.email.includes('guest.se') &&
                                        (inviteListFilters.includes(
                                          user.teamName
                                        ) ||
                                          inviteListFilters.includes(
                                            user.employmentCity
                                          ))
                                    )
                                    .sort((a, b) => {
                                      const nameA = a.email.toUpperCase(); // ignore upper and lowercase
                                      const nameB = b.email.toUpperCase(); // ignore upper and lowercase
                                      if (nameA > nameB) {
                                        return 1;
                                      }
                                      if (nameA < nameB) {
                                        return -1;
                                      }
                                      // names must be equal
                                      return 0;
                                    })
                                    .map((user, index) => {
                                      return (
                                        <Form.Check
                                          className='m-3'
                                          key={index}
                                          name='userEmail'
                                          id={user.email}
                                          label={user.email}
                                          checked={checkIfAddedToList(
                                            user.email
                                          )}
                                          onChange={handleCheckBoxChange(index)}
                                          style={{ textAlign: 'left' }}
                                        />
                                      );
                                    })
                                : !!accountsData &&
                                  accountsData
                                    .filter(
                                      (user) => !user.email.includes('guest.se')
                                    )
                                    .sort((a, b) => {
                                      const nameA = a.email.toUpperCase();
                                      const nameB = b.email.toUpperCase();
                                      if (nameA > nameB) {
                                        return 1;
                                      }
                                      if (nameA < nameB) {
                                        return -1;
                                      }
                                      // names must be equal
                                      return 0;
                                    })
                                    .map((user, index) => {
                                      return (
                                        <Form.Check
                                          className='m-3'
                                          key={index}
                                          name='userEmail'
                                          id={user.email}
                                          label={user.email}
                                          checked={checkIfAddedToList(
                                            user.email
                                          )}
                                          onChange={handleCheckBoxChange(index)}
                                          style={{ textAlign: 'left' }}
                                        />
                                      );
                                    })}
                            </Form>
                            <Row className='ms-1' style={{ width: '100%' }}>
                              <Col md={12}>
                                <Button
                                  variant='outline-dark'
                                  className='roundedButton'
                                  style={{
                                    marginTop: '4vh',
                                    marginBottom: '1vh',
                                  }}
                                  onClick={addToCustomList}
                                >
                                  Lägg till markerade
                                </Button>
                              </Col>
                            </Row>{' '}
                            {addedUsersToInvite.length !== 0 ? (
                              <Collapse in={showAddedToList}>
                                <p style={{ color: 'green' }}>
                                  Du har nu uppdaterat listan av inbjudna
                                  anställda.
                                </p>
                              </Collapse>
                            ) : (
                              <Collapse in={showAddedToList}>
                                <p style={{ color: 'red' }}>
                                  Du har inte valt några anställda att bjuda in.
                                </p>
                              </Collapse>
                            )}
                          </div>
                        </Col>

                        <Col md={6}>
                          <Dropdown className='mb-3 mt-3'>
                            <Dropdown.Toggle
                              variant='outline-dark'
                              className='roundedButton'
                              id='dropdown-listOfInvitedEmployees'
                            >
                              Visa tidigare inbjudna
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                              style={{
                                maxHeight: '25vh',
                                minWidth: '15vw',
                                overflowY: 'auto',
                                borderRadius: '16px',
                              }}
                            >
                              {props.event !== null &&
                              props.event.invitedAccounts.length > 0 ? (
                                props.event.invitedAccounts.map(
                                  (user, index) => {
                                    return (
                                      <>
                                        <Dropdown.ItemText key={index}>
                                          {user.email}
                                        </Dropdown.ItemText>
                                        {index !==
                                        props.event.invitedAccounts.length -
                                          1 ? (
                                          <Dropdown.Divider />
                                        ) : null}
                                      </>
                                    );
                                  }
                                )
                              ) : (
                                <Dropdown.ItemText>
                                  Ingen tillagd
                                </Dropdown.ItemText>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>

                        <Col md={6}>
                          <Dropdown className='mb-3 mt-3'>
                            <Dropdown.Toggle
                              variant='outline-dark'
                              className='roundedButton'
                              id='dropdown-listOfInvitedEmployees'
                            >
                              Visa nya inbjudna
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                              style={{
                                maxHeight: '25vh',
                                minWidth: '15vw',
                                overflowY: 'auto',
                                marginLeft: '5vw',
                                borderRadius: '16px',
                              }}
                            >
                              {eventInput.newlyAddedInvites.length > 0 ? (
                                eventInput.newlyAddedInvites.map(
                                  (user, index) => {
                                    return (
                                      <>
                                        <Dropdown.ItemText key={index}>
                                          {user}{' '}
                                          <Button
                                            size='sm'
                                            variant='outline-danger'
                                            style={{ float: 'right' }}
                                            onClick={() =>
                                              removeUserFromInviteList(index)
                                            }
                                          >
                                            <XCircle></XCircle>
                                          </Button>
                                        </Dropdown.ItemText>
                                        {index !==
                                        eventInput.newlyAddedInvites.length -
                                          1 ? (
                                          <Dropdown.Divider />
                                        ) : null}{' '}
                                      </>
                                    );
                                  }
                                )
                              ) : (
                                <Dropdown.ItemText>
                                  Ingen tillagd
                                </Dropdown.ItemText>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </>
                    )}
                    {props.type === 'create' && (
                      <>
                        <Row className='mb-3 mt-3'>
                          <h1>Välj vilka du vill bjuda in</h1>
                        </Row>
                        <div className='filterUserCity'>
                          <Row className='mb-1'>
                            <h2
                              style={{ fontSize: '20px', fontWeight: 'bold' }}
                            >
                              Stad
                            </h2>
                          </Row>
                          <Row className='mb-3'>
                            {!!employmentCityList &&
                              employmentCityList.length > 0 &&
                              employmentCityList.map((city, index) => {
                                return (
                                  <Col md={6} lg={4} xxl={3}>
                                    <Form.Check
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                      }}
                                      key={index}
                                      type='checkbox'
                                      className='m-2'
                                      label={city}
                                      onChange={(e) =>
                                        handleInviteChange(
                                          city,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </Col>
                                );
                              })}
                          </Row>
                        </div>

                        <div className='filterUserTeam'>
                          <Row className='mb-1'>
                            <h2
                              style={{ fontSize: '20px', fontWeight: 'bold' }}
                            >
                              Team
                            </h2>
                          </Row>
                          <Row className='mb-3'>
                            {!!teamList &&
                              teamList.length > 0 &&
                              teamList.map((team, index) => {
                                return (
                                  <Col md={6} lg={4} xxl={3}>
                                    <Form.Check
                                      style={{
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                      }}
                                      key={index}
                                      type='checkbox'
                                      className='m-2'
                                      label={team}
                                      onChange={(e) =>
                                        handleInviteChange(
                                          team,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </Col>
                                );
                              })}
                          </Row>
                        </div>

                        <Col md={12}>
                          <div style={{ backgroundColor: 'white' }}>
                            <Form
                              style={{
                                padding: '10px',
                                minHeight: '250px',
                                maxHeight: '400px',
                                minWidth: '20vw',
                                overflowX: 'hidden',
                              }}
                            >
                              <Row>
                                <Col
                                  md={8}
                                  xs={12}
                                  style={{
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                  }}
                                >
                                  <Form.Control
                                    className='mb-3 modalInput'
                                    style={{ textAlign: 'left' }}
                                    type='email'
                                    name='customEmailInvite'
                                    onChange={(e) =>
                                      changeCustomEmailInvite(e.target.value)
                                    }
                                    placeholder='E-postadress'
                                  ></Form.Control>
                                </Col>

                                <Col
                                  md={4}
                                  xs={12}
                                  style={{
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                  }}
                                >
                                  <Button
                                    variant='outline-dark'
                                    className='roundedButton d-inline-block'
                                    size='sm'
                                    onClick={inviteCustomEmail}
                                  >
                                    Bjud in
                                  </Button>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Collapse in={showCustomEmailInvited}>
                                    <p style={{ color: 'green' }}>
                                      {customEmailInvite} har nu bjudits in
                                    </p>
                                  </Collapse>
                                  <Collapse in={showIncorrectEmail}>
                                    <p style={{ color: 'red' }}>
                                      Fältet får inte vara tomt och måste vara
                                      en giltig E-postadress
                                    </p>
                                  </Collapse>
                                </Col>
                              </Row>
                              <br />
                              <Button
                                className='roundedButton'
                                style={{ float: 'right' }}
                                variant='outline-dark'
                                onClick={inviteAllUsers}
                              >
                                {addedUsersToInvite.length ===
                                accountsData.length
                                  ? 'Avmarkera alla'
                                  : 'Välj alla'}
                              </Button>
                              {accountsData !== null &&
                              inviteListFilters.length > 0
                                ? accountsData
                                    .filter(
                                      (user) =>
                                        !user.email.includes('guest.se') &&
                                        (inviteListFilters.includes(
                                          user.teamName
                                        ) ||
                                          inviteListFilters.includes(
                                            user.employmentCity
                                          ))
                                    )
                                    .sort((a, b) => {
                                      const nameA = a.email.toUpperCase(); // ignore upper and lowercase
                                      const nameB = b.email.toUpperCase(); // ignore upper and lowercase
                                      if (nameA > nameB) {
                                        return 1;
                                      }
                                      if (nameA < nameB) {
                                        return -1;
                                      }
                                      // names must be equal
                                      return 0;
                                    })
                                    .map((user, index) => {
                                      return (
                                        <Form.Check
                                          className='m-3'
                                          key={index}
                                          name='userEmail'
                                          id={user.email}
                                          label={user.email}
                                          checked={checkIfAddedToList(
                                            user.email
                                          )}
                                          onChange={handleCheckBoxChange(index)}
                                          style={{ textAlign: 'left' }}
                                        />
                                      );
                                    })
                                : !!accountsData &&
                                  accountsData
                                    .filter(
                                      (user) => !user.email.includes('guest.se')
                                    )
                                    .sort((a, b) => {
                                      const nameA = a.email.toUpperCase(); // ignore upper and lowercase
                                      const nameB = b.email.toUpperCase(); // ignore upper and lowercase
                                      if (nameA > nameB) {
                                        return 1;
                                      }
                                      if (nameA < nameB) {
                                        return -1;
                                      }
                                      // names must be equal
                                      return 0;
                                    })
                                    .map((user, index) => {
                                      return (
                                        <Form.Check
                                          className='m-3'
                                          key={index}
                                          name='userEmail'
                                          id={user.email}
                                          label={user.email}
                                          checked={checkIfAddedToList(
                                            user.email
                                          )}
                                          onChange={handleCheckBoxChange(index)}
                                          style={{ textAlign: 'left' }}
                                        />
                                      );
                                    })}
                            </Form>
                            <Row style={{ width: '100%' }}>
                              <Col md={12}>
                                <Button
                                  variant='outline-dark'
                                  className='roundedButton'
                                  style={{
                                    marginTop: '4vh',
                                    marginBottom: '1vh',
                                  }}
                                  onClick={addToCustomList}
                                >
                                  Lägg till markerade
                                </Button>
                              </Col>
                            </Row>
                            {addedUsersToInvite.length !== 0 ? (
                              <Collapse in={showAddedToList}>
                                <p style={{ color: 'green' }}>
                                  Du har nu uppdaterat listan av inbjudna
                                  anställda.
                                </p>
                              </Collapse>
                            ) : (
                              <Collapse in={showAddedToList}>
                                <p style={{ color: 'red' }}>
                                  Du har inte valt några anställda att bjuda in.
                                </p>
                              </Collapse>
                            )}
                          </div>
                        </Col>

                        <Col md={12}>
                          <Dropdown className='mb-3 mt-3'>
                            <Dropdown.Toggle
                              variant='outline-dark'
                              className='roundedButton'
                              id='dropdown-listOfInvitedEmployees'
                            >
                              Visa inbjudna
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                              style={{
                                maxHeight: '25vh',
                                minWidth: '20vw',
                                overflowY: 'auto',
                                borderRadius: '16px',
                              }}
                            >
                              {eventInput.invitedAccounts.length > 0 ? (
                                eventInput.invitedAccounts.map(
                                  (user, index) => {
                                    return (
                                      <>
                                        <Dropdown.ItemText key={index}>
                                          {user}{' '}
                                          <Button
                                            size='sm'
                                            variant='outline-danger'
                                            style={{ float: 'right' }}
                                            onClick={() =>
                                              removeUserFromInviteList(index)
                                            }
                                          >
                                            <XCircle></XCircle>
                                          </Button>
                                        </Dropdown.ItemText>
                                        {index !==
                                        eventInput.invitedAccounts.length -
                                          1 ? (
                                          <Dropdown.Divider />
                                        ) : null}
                                      </>
                                    );
                                  }
                                )
                              ) : (
                                <Dropdown.ItemText>
                                  Ingen tillagd
                                </Dropdown.ItemText>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </>
                    )}
                  </Row>

                  {eventInput.active && props.type === 'create' && (
                    <Row className='mb-3'>
                      <Col>
                        <ModalInputSwitch
                          controlId='formSendEmail'
                          label='Vill du skicka en inbjudan via E-post?'
                          name='sendEmail'
                          id='sendEmail'
                          checked={sendEmail}
                          onChange={handleSendEmail}
                        />
                      </Col>
                    </Row>
                  )}

                  {sendEmail && eventInput.active && (
                    <>
                      <Row>
                        <Col>
                          <ModalInputSwitch
                            controlId='formShowEmailPreview'
                            label='Visa förhandsgranskning av inbjudan'
                            name='showEmailPreview'
                            id='showEmailPreview'
                            onChange={changePreview}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          {!showPreview &&
                            !showEditInvite &&
                            returnEmailPreview()}
                        </Col>
                      </Row>

                      {showEditInvite && (
                        <>
                          <Row className='mb-3'>
                            <Col
                              md={7}
                              style={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                              }}
                            >
                              <MailTextEditor
                                className='editor modalInput'
                                header='Redigera inbjudan'
                                text={text}
                                setText={setText}
                              />
                            </Col>
                          </Row>

                          <Row
                            style={{
                              marginLeft: 'auto',
                              marginRight: 'auto',
                              textAlign: 'center',
                              minWidth: '20%',
                              maxWidth: '50%',
                            }}
                          >
                            <Col md={6}>
                              <Button
                                className='m-2 outline-blackButton-delete defaultButton'
                                size='sm'
                                variant='outline-dark'
                                onClick={() => {
                                  setEmailBody(text);
                                  handleEditInvite();
                                }}
                              >
                                Spara
                              </Button>
                            </Col>
                            <Col md={6}>
                              <Button
                                className='m-2 outline-redButton-delete defaultButton'
                                size='sm'
                                variant='danger'
                                onClick={handleEditInvite}
                              >
                                Avbryt
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              {props.type === 'edit' && (
                <Row className='mb-3'>
                  <Col>
                    <ModalInputSwitch
                      controlId='formSendEmail'
                      label='Vill du skicka E-post till inbjudna om dina ändringar?'
                      name='sendEmailEdit'
                      id='sendEmailEdit'
                      checked={notifyUsersAboutChange}
                      onChange={handleNotifyUsersAboutChange}
                    />
                  </Col>
                </Row>
              )}
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            {props.type === 'create' ? (
              <Col>
                <Button
                  variant='info'
                  className='modalLightBlueButton'
                  onClick={submitEventInput}
                >
                  Skapa event
                </Button>
              </Col>
            ) : (
              <>
                <Col style={{ display: 'contents' }}>
                  <Button
                    size='lg'
                    variant='info'
                    className='modalLightBlueButton float-start'
                    onClick={() => setShowEditChainModal(true)}
                  >
                    Spara
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant='outline-danger'
                    className='outline-redButton-delete defaultButton'
                    onClick={() => setShowDeleteModal(!showDeleteModal)}
                  >
                    <Trash3Fill /> Ta bort
                  </Button>
                </Col>
              </>
            )}
          </Row>
          {returnCreationMessage()}
        </Modal.Footer>
      </Modal>

      <ConfirmationModal
        showModal={showRouteModal}
        setShowModal={() => setShowRouteModal()}
        type='created'
        title='Eventet har skapats'
        bodyContent={
          routedEvent !== null &&
          'Ditt event "' + routedEvent.title + '" har nu skapats.'
        }
        chainEvent={false}
        confirmFunction={() => {
          if (routedEvent !== null) {
            setShowRouteModal(false);
            navigate('signup/' + routedEvent.id);
          } else {
            setShowRouteModal(false);
            alert(
              'Något fel har inträffat. Vänligen kontakta admin om felet kvarstår.'
            );
          }
        }}
      />

      <ConfirmationModal
        showModal={showDeleteModal}
        setShowModal={() => setShowDeleteModal()}
        type='delete'
        title='Bekräfta borttagning'
        bodyContent='Är du säker på att du vill ta bort eventet?'
        chainEvent={
          eventInput.recurringEventId !== null &&
          eventInput.recurringEventId !== ''
        }
        chainSize={
          !!props.fetchedEvents
            ? returnNumberOfRecurringEvents(eventInput.recurringEventId)
            : 'error'
        }
        confirmFunction={() => deleteThisEvent()}
        confirmChainFunction={() =>
          deleteAllEventsInChainHandler(eventInput.recurringEventId)
        }
      />

      <ConfirmationModal
        showModal={showEditChainModal}
        setShowModal={() => setShowEditChainModal()}
        type='edit'
        title='Bekräfta ändring'
        bodyContent='Är du säker på att du vill redigera eventet?'
        chainEvent={
          eventInput.recurringEventId !== null &&
          eventInput.recurringEventId !== ''
        }
        chainSize={
          !!props.fetchedEvents
            ? returnNumberOfRecurringEvents(eventInput.recurringEventId)
            : 'error'
        }
        confirmFunction={() => submitEditedEvent()}
        confirmChainFunction={() =>
          submitEditedEventChain(eventInput.recurringEventId)
        }
      />
    </>
  );
};
