import { useQuery } from '@tanstack/react-query';
import { makeFetchRequest } from 'services/api';

export function useEventQuery(eventId: number) {
  return useQuery({
    queryKey: ['event', { eventId }],
    retry: false,
    queryFn: () => makeFetchRequest(`Events/${eventId}`),
    enabled: Boolean(eventId),
  });
}
