import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

export const DropdownCheckbox = (props) => {
  return (
    <FormControl
      fullWidth
      sx={
        props.currentWindowSize.width <= 576
          ? {
              m: 0,
              maxWidth: 150,
              backgroundColor: '#F0F0F0',
            }
          : {
              m: 0,
              minWidth: 100,
              maxWidth: 175,
              backgroundColor: '#F0F0F0',
            }
      }
    >
      <InputLabel id='multiple-checkbox-label'>{props.label}</InputLabel>
      <Select
        labelId='multiple-checkbox-label'
        id='multiple-checkbox'
        multiple
        value={props.valueName}
        onChange={(e) => {
          props.handleValueChange(e);
          props.filterForCalendarView(e.target.value);
        }}
        input={<OutlinedInput label={props.label} />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={props.MenuProps}
      >
        {props.values
          .slice(0, 1)
          .concat(props.values.slice(1).sort())
          .map((value) => (
            <MenuItem key={value} value={value}>
              <Checkbox checked={props.valueName.indexOf(value) > -1} />
              <ListItemText primary={value} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
